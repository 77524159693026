import { Popover } from "@idui/react-popover";

import * as sharedActions from "../../store/actions/Shared";
import * as chatbotActions from "../../store/actions/Chatbot";
import * as servicesActions from "../../store/actions/Services";
import * as accountsActions from "../../store/actions/Accounts";

import React, { useState } from "react";
import { connect } from "react-redux";

import moment from "moment";

import _ from "lodash";

import { useHistory, useLocation } from "react-router-dom";

const ChatPopover = (props) => {
  const [focus, setFocus] = useState(false);
  const [show, setShow] = useState(false);

  let conversations = props.conversations;

  const loadActiveChat = props.loadActiveChat;
  const setMessages = props.setMessages;
  const setShowBlackScreen = props.setShowBlackScreen;
  const setShowBlackScreenDrawer = props.setShowBlackScreenDrawer;
  const setActiveServicesEnergy = props.setActiveServicesEnergy;
  const setActiveServicesPlus = props.setActiveServicesPlus;
  const setAccount = props.setAccount;
  const loadServicesAsync = props.loadServicesAsync;
  let currentAccount = props.account;
  let totalAccounts = props.totalAccounts;
  let totalServices = props.totalServices;

  const history = useHistory();
  const location = useLocation();

  const loadConversations = async (id, services) => {
    await loadActiveChat(id);
    if (services.length) {
      await handleActiveMessage(services);
    }

    setShow(false);

    setShowBlackScreen(false);
    setShowBlackScreenDrawer(false);

    setFocus(false);
  };

  const chatHandler = () => {
    setMessages([]);
    setShow(false);
    setShowBlackScreen(false);
    setShowBlackScreenDrawer(false);
    setFocus(false);
  };

  const handleActiveMessage = async (services) => {
    const account = totalAccounts.filter((account) => account?.services?.map((x) => x?.idService).includes(services[0]));

    if (account.length && account[0]?.idAccount !== currentAccount?.idAccount) {
      setAccount(account[0]);
      await loadServicesAsync(services);
    } else {
      const filteredServices = totalServices.filter((service) => services.includes(service.idService));

      setActiveServicesEnergy(filteredServices);

      setActiveServicesPlus([_.last(filteredServices)]);
    }

    if (location.pathname === "/home") history.push("home/detail");
  };

  const ConversationComponent = ({ item, id }) => {
    return (
      <div
        className="row-between align-items-center gap-2 mb-2 pe-pointer"
        key={id}
        onClick={() => loadConversations(item?.id_conversation, item?.services)}>
        {item?.services?.length ? (
          <Popover
            zIndex={99999}
            className={"p-0 bg-gray-500 shadow-none"}
            width={240}
            trigger={"hover"}
            placement={"left"}
            arrowColor={"gray-500"}
            height={180}
            offset={[-20, 0]}
            withArrow={false}
            openingAnimationTranslateDistance={0}
            content={
              <div className="w-100 p-3">
                <div className="txt-8 text-white txt-phrase txt-bold my-2">Servicios consultados</div>
                <div className="txt-6 my-2 text-wrap txt-gray-700 txt-regular">Estos son los servicios que consultaste en tu pregunta</div>
                {item?.services?.length &&
                  totalAccounts
                    .filter((account) => account?.services?.map((x) => x?.idService).includes(item?.services[0]))[0]
                    ?.services?.filter((service) => item?.services?.includes(service?.idService))
                    ?.map((service, i) => (
                      <div
                        key={i}
                        className="flex-grow-1 my-2 txt-white text-left rounded-pill p-2 bg-gray-450 border-color-gray-800 border-1 txt-6 txt-regular">
                        {service?.name}
                      </div>
                    ))}
              </div>
            }>
            <div
              className="row-center text-center txt-green-500-hover align-items-center txt-white border-color-white border-color-green-500-hover border-1 rounded-pill txt-6 txt-medium pe-pointer"
              style={{ width: 24, height: 24, minWidth: 24, minHeight: 24 }}>
              {item?.services?.length}
            </div>
          </Popover>
        ) : (
          <div
            className="row-center text-center txt-green-500-hover align-items-center txt-white border-color-white border-color-green-500-hover border-1 rounded-pill txt-6 txt-medium pe-pointer"
            style={{ width: 24, height: 24, minWidth: 24, minHeight: 24 }}>
            {item?.services?.length}
          </div>
        )}
        <div className="flex-grow-1 txt-white text-left rounded-pill p-2 bg-gray-450 border-color-gray-800 border-1 txt-8 txt-regular bg-gray-800-hover pe-pointer">{item?.message}</div>
      </div>
    );
  };

  return (
    <Popover
      lazy
      closeOnEnter
      fitMaxHeightToBounds
      width={300}
      height={320}
      zIndex={99999}
      trigger={"click"}
      placement={"left"}
      className={"p-0 bg-gray-500"}
      isOpen={show}
      withArrow={false}
      offset={[0, -180]}
      closeOnRemoteClick={true}
      openingAnimationTranslateDistance={0}
      animation={{
        animate: { opacity: 1 },
        exit: { opacity: 0, transition: { duration: 0.1 } },
        initial: { opacity: 0 },
      }}
      onChangeOpen={(state) => {
        setShow(state);
        setShowBlackScreen(state);
        setShowBlackScreenDrawer(state);
        setFocus(state);
      }}
      content={
        <div
          className="w-100 py-3 ps-3"
          style={{ overflowY: "scroll" }}>
          <div
            className="row-center align-items-center bg-green-500 gap-2 px-3 mb-3 bg-green-200-hover rounded-8 flex-grow-1 pe-pointer"
            style={{ height: 45 }}
            onClick={() => chatHandler()}>
            <i className="fas fa-sparkles txt-neutral-800 txt-regular txt-10 pe-pointer" />
            <p className="text-phrase txt-neutral-800 txt-8 txt-bold">Nuevo chat</p>
          </div>
          <div className="txt-title txt-10 txt-title txt-bold txt-white mb-3">Tus chats</div>
          {conversations?.filter((item) => !moment(item?.date)?.isBefore(moment().subtract(7, "days"), "day")).length && <div className="txt-8 txt-regular txt-gray-700 mb-2 mt-2">Últimos 7 días</div>}
          {conversations
            ?.filter((item) => !moment(item?.date)?.isBefore(moment().subtract(7, "days"), "day"))
            .map((item, id) => (
              <ConversationComponent
                key={id}
                item={item}
                id={id}
              />
            ))}
          {conversations?.filter((item) => moment(item?.date)?.isBefore(moment().subtract(30, "days"), "day")).length && <div className="txt-8 txt-regular txt-gray-700 mt-4 mb-2">Últimos 30 días</div>}
          {conversations
            ?.filter((item) => moment(item?.date)?.isBefore(moment().subtract(30, "days"), "day"))
            .map((item, id) => (
              <ConversationComponent
                key={id}
                item={item}
                id={id}
              />
            ))}
        </div>
      }>
      <div
        className="row-center align-items-center bg-gray-450 border-color-gray-800 border-1 rounded-pill pe-pointer"
        style={{ height: 46, width: 46, zIndex: focus ? 10000 : 0 }}>
        <i className="fas fa-messages txt-10 txt-gray-700 txt-regular txt-8 pe-pointer" />
      </div>
    </Popover>
  );
};

const mapStateToProps = (state, ownProps) => ({
  language: state.shared.language,
  conversations: state.chatbot.conversations,
  totalAccounts: state.accounts.totalAccounts,
  totalServices: state.services.totalServices,
  account: state.accounts.account,
});

const mapDispatchToProps = (dispatch) => ({
  loadActiveChat: (payload) => dispatch(chatbotActions.loadActiveChat(payload)),
  setShowBlackScreen: (payload) => dispatch(sharedActions.setShowBlackScreen(payload)),
  setShowBlackScreenDrawer: (payload) => dispatch(sharedActions.setShowBlackScreenDrawer(payload)),
  setMessages: (payload) => dispatch(chatbotActions.setMessages(payload)),
  setActiveServicesPlus: (payload) => dispatch(servicesActions.setActiveServicesPlus(payload)),
  setActiveServicesEnergy: (payload) => dispatch(servicesActions.setActiveServicesEnergy(payload)),
  setAccount: (payload) => dispatch(accountsActions.setAccount(payload)),
  loadServicesAsync: (payload) => dispatch(servicesActions.loadServicesAsync(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatPopover);
