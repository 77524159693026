import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import * as sharedActions from "../../../../store/actions/Shared";

import queryString from 'query-string';

import ReactToPrint from 'react-to-print';

import PaidStatus from './PaidStatus';

import { GETData } from '../../../../services/WebServices';

const Paid = (props) => {

    let language = props.language;

    const componentRef = useRef();

    const [transaction, setTransaction] = useState(null);

    const loadLanguageAsync = props.loadLanguageAsync

    useEffect(() => {

        let url = props.location.search;
        let params = queryString.parse(url);

        const complement = params.ticket ? `/${params.ticket}` : '';

        GETData(`${params.provider}/transaction/${params.order}${complement}`, 'GET')
            .then((result) => { if (result !== null) { setTransaction(result); } });

    }, [props, setTransaction]);

    useEffect(() => {
        if (!Object.keys(language).length)
            loadLanguageAsync();
    }, [loadLanguageAsync, language]);

    return (
        <section className="column-center align-items-center p-4 vh-100 w-100 bg-black">
            <div className="position-relative column-start align-items-center flex-grow-1 col-12 col-md-6 bg-gray-500 rounded-16 overflow-hidden">
                <PaidStatus
                    transaction={transaction}
                    language={language}
                />
                <div className="d-none">
                    <PaidStatus
                        light
                        ref={componentRef}
                        transaction={transaction}
                        language={language}
                    />
                </div>
                {transaction != null && Object.keys(language).length ?
                    <div className="row-center align-items-center flex-wrap my-2">
                        <ReactToPrint
                            copyStyles={true}
                            trigger={() => <button style={{ width: 150 }} className="row-center align-items-center txt-10 txt-phrase txt-regular txt-green-500 border-1 border-color-green-500 my-2 rounded-8 px-4 py-2 mx-2 bg-gray-500 pe-pointer">{props.language.invoices_paid_button_print}</button>}
                            content={() => componentRef.current}
                        />
                        <a href='https://app.enrg.ai/home' style={{ width: 150 }} className="row-center align-items-center txt-10 txt-phrase txt-neutral-800 txt-semibold border-0 my-2 rounded-8 px-4 py-2 mx-2 bg-green-500 pe-pointer">{props.language.invoices_paid_link_finally}</a>
                    </div> : null
                }
            </div> 
        </section>
    )
}

const mapStateToProps = (state) => ({    
    language: state.shared.language
})

const mapDispatchToProps = dispatch => ({
    loadLanguageAsync: () => dispatch(sharedActions.loadLanguageAsync())
})

export default connect(mapStateToProps, mapDispatchToProps)(Paid)