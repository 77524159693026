import React, { useEffect, useState } from "react"

import Popover from "@idui/react-popover"

import { track } from "@amplitude/analytics-browser"

const SubMenuGraphs = ({ graphData, setControlRemoteClick, activeGraphs, setGraphActive, selectedGraphs, showSubmenuGraphs, setShowSubmenuGraphs, isDisabled, language }) => {
  const [show, setShow] = useState(showSubmenuGraphs === graphData[0].group)
  // These graphs won't be activated until the user press the button
  const [graphActiveTemporal, setGraphActiveTemporal] = useState([...selectedGraphs])

  useEffect(() => {
    setGraphActiveTemporal(selectedGraphs)
  }, [selectedGraphs])

  const areArrayEquals = (a, b) => {
    return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index])
  }

  return (
    <Popover
      lazy
      fitMaxHeightToBounds
      fitMaxWidthToBounds
      trigger={"click"}
      isOpen={show || showSubmenuGraphs === graphData[0].group}
      placement="right"
      guessBetterPosition
      className={"p-0 rounded-8 bg-gray-500 genius-picker"}
      withArrow={true}
      arrowColor="#161617"
      arrowSize={20}
      width="280px"
      offset={[0, 0]}
      openingAnimationTranslateDistance={0}
      spaceBetweenPopoverAndTarget={40}
      onChangeOpen={(state) => {
        if (!isDisabled) {
          setShow(state)
          setControlRemoteClick(state)
          setShowSubmenuGraphs("")
        }
      }}
      animation={{
        animate: { opacity: 1 },
        exit: { opacity: 0, transition: { duration: 0.1 } },
        initial: { opacity: 0 },
      }}
      content={
        <div className="p-3 border-none-scroll">
          <div className="column-start graphs-picker-container mb-3">
            <div className="column-start gap-2">
              {graphData.map((_graph) => {
                const isSelected = graphActiveTemporal.findIndex((i) => i === _graph.value) >= 0

                return (
                  <div
                    key={_graph.id_graph}
                    className={`row-between align-items-center pe-pointer p-2 rounded-8 ${isSelected ? "bg-gray-800" : "bg-gray-500"}`}
                    onClick={() => {
                      if (isSelected && graphActiveTemporal.length > 0) {
                        setGraphActiveTemporal([...graphActiveTemporal.filter((g) => g !== _graph.value)])
                      } else {
                        setGraphActiveTemporal([...graphActiveTemporal, _graph.value])
                        track('Variable selected', {Variable_ID: _graph.value})
                      }
                    }}>
                    <div className={`txt-phrase txt-regular txt-6 txt-white pe-pointer`}>
                      {language[`energy_graph_label_${_graph.value.toLowerCase()}`]} {_graph.unit && `(${_graph.unit})`}
                    </div>
                    <i className={`fal fa-check-circle txt-${isSelected ? "green-500" : "gray-700"} txt-10`}></i>
                  </div>
                )
              })}
            </div>
          </div>
          <div className={`row-end border-top-1 border-color-gray-600 pt-3 px-1`}>
            <button
              className="p-2 rounded-8 bg-green-500 txt-neutral-800 txt-phrase txt-bold txt-8"
              onClick={() => {
                if (!areArrayEquals(activeGraphs, graphActiveTemporal)) {
                  setGraphActive(graphActiveTemporal)
                }
                setShow(false)
                setControlRemoteClick(false)
                setShowSubmenuGraphs("")
              }}>
              Hecho
            </button>
          </div>
        </div>
      }>
      <i className={`fa-light fa-circle-chevron-right txt-10 pe-pointer txt-${show || showSubmenuGraphs === graphData[0].group ? "green-500" : isDisabled ? "gray" : "gray-700"}`}></i>
    </Popover>
  )
}

export default SubMenuGraphs
