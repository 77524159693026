import React, { useState, useRef } from "react";

import { replaceTextValues } from "../../services/NotificationsHandler";

import moment from "moment";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fal } from '@fortawesome/pro-light-svg-icons';

import { slideOutRight } from 'react-animations';

import Radium, { StyleRoot } from 'radium';

import PopoverNotifications from "./PopoverNotifications";

import { track } from "@amplitude/analytics-browser";

const styles = {
  slideOutRight: {
    animation: 'x 0.25s',
    animationName: Radium.keyframes(slideOutRight, 'slideOutRight')
  }
}

const NotificationBox = (props) => {

  const { id_register, title, body, values, icon, color, timestamp, read, deeplink } = props.notification;

  const [isShown, setVisualization] = useState(true);
  const [category, setCategory] = useState('');
 
  const hasHovered = useRef(false);

  const handleHover = () => {
    if (!hasHovered.current) {      
      hasHovered.current = true;      

      switch (icon) {
        case 'faCircleInfo':
          setCategory('information')
          break
        case 'faTriangleExclamation':
          setCategory('warning')
          break
        case 'faSquareExclamation':
          setCategory('alert')
          break
        case 'faShieldCheck':
          setCategory('success')
          break
        default:
          break
      }

      track('Notification hover', { Notification_ID: category });
    }
  };

  const handleLeave = () => {    
    hasHovered.current = false;
  };

  return (
    <div className="position-relative row-center w-100 h-100 py-1">
      <StyleRoot className="w-100">
        <a href={deeplink} className={`pe-pointer w-100 h-100`} target="_blank" rel="noopener noreferrer">
          <div className={`col row-between align-items-start ${!read ? 'bg-gray-800' : null} p-2 h-100 rounded-8`} style={!isShown ? styles.slideOutRight : null}
            onMouseOver={handleHover}
            onMouseLeave={handleLeave}
          >
            <div className="col-1">
              <FontAwesomeIcon icon={fal[icon]} size='1x' color={color} />
            </div>
            <div className="col-10 column-start align-items-start">
              <p className="txt-title txt-semibold txt-6 txt-white pe-pointer col-10 text-wrap">{title}</p>
              <p className="txt-phrase txt-regular txt-6 txt-neutral-100 text-wrap my-2 pe-pointer">{replaceTextValues(body, values)}</p>
              <p className="txt-phrase txt-regular txt-6 txt-gray-600">{moment(timestamp).fromNow()}</p>
            </div>
            <div className="col-1 column-center align-items-center h-100">
              <PopoverNotifications
                notification={{ id: id_register, read, category }}
                setVisualization={setVisualization} />
            </div>
          </div>
        </a>
      </StyleRoot>
    </div>

  );
};

export default NotificationBox;
