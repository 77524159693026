import React from 'react';
import step1 from '../../../imgs/onboarding/step1-alerts.png';
import step1En from '../../../imgs/onboarding/english/step1-alerts-en.png';
import step2 from '../../../imgs/onboarding/step2-alerts.png';
import step2En from '../../../imgs/onboarding/english/step2-alerts-en.png';
import step3 from '../../../imgs/onboarding/step3-alerts.png';
import step3En from '../../../imgs/onboarding/english/step3-alerts-en.png';
import step4 from '../../../imgs/onboarding/step4-alerts.png';
import step4En from '../../../imgs/onboarding/english/step4-alerts-en.png';
import step5 from '../../../imgs/onboarding/step5-alerts.png';
import step5En from '../../../imgs/onboarding/english/step5-alerts-en.png';
import step6 from '../../../imgs/onboarding/step6-alerts.png';
import step6En from '../../../imgs/onboarding/english/step6-alerts-en.png';
import step7 from '../../../imgs/onboarding/step7-alerts.png';
import step7En from '../../../imgs/onboarding/english/step7-alerts-en.png';
import step8 from '../../../imgs/onboarding/step8-alerts.png';
import step8En from '../../../imgs/onboarding/english/step8-alerts-en.png';

export const stepsAlert = (language) => {
    return ([
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 122, width: 300 }}>
                    <img src={language.language_locale === 'es'? step1 : step1En } className='w-100 h-100' alt="step1-graph-calendar" />
                </div>,
            title: language.onboarding_alert_title_step1,
            description: language.onboarding_alert_description_step1,
            placement: "left",
            target: "#alerts_container",
            disableBeacon: true,
            steps: 8
        },
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 124, width: 300 }}>
                    <img src={language.language_locale === 'es'? step2 : step2En} className='w-100 h-100' alt="step2-graph-calendar" />
                </div>,
            title: language.onboarding_alert_title_step2,
            description: language.onboarding_alert_description_step2,
            placement: "left",            
            target: "#added_alert",
            disableBeacon: true,
            steps: 8,
            disableOverlayClose: true,
            spotlightClicks: true,
            hideFooter: true,
            hideFooterTitle: '¡Da click para descubrir cómo!',
            offset: 30,
            pulseEffect: true
        },
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 124, width: 300 }}>
                    <img src={language.language_locale === 'es'? step3 : step3En} className='w-100 h-100' alt="step3-graph-calendar" />
                </div>,
            title: language.onboarding_alert_title_step3,
            description: language.onboarding_alert_description_step3,
            placement: "left",
            target: "#services_picker",
            disableBeacon: true,
            steps: 8,
            hideBackButton: true
        },
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 134, width: 300 }}>
                    <img src={language.language_locale === 'es'? step4 : step4En} className='w-100 h-100' alt="step4-graph-calendar" />
                </div>,
            title: language.onboarding_alert_title_step4,
            description: language.onboarding_alert_description_step4,
            placement: "left",
            target: "#variable_picker",
            disableBeacon: true,
            steps: 8
        },
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 134, width: 300 }}>
                    <img src={language.language_locale === 'es'? step5 : step5En} className='w-100 h-100' alt="step4-graph-calendar" />
                </div>,
            title: language.onboarding_alert_title_step5,
            description: language.onboarding_alert_description_step5,
            placement: "left",
            target: "#rules_picker",
            disableBeacon: true,
            steps: 8
        },
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 124, width: 300 }}>
                    <img src={language.language_locale === 'es'? step6 : step6En} className='w-100 h-100' alt="step5-graph-calendar" />
                </div>,
            title: language.onboarding_alert_title_step6,
            description: language.onboarding_alert_description_step6,
            placement: "left-end",
            target: "#value_input",
            disableBeacon: true,
            steps: 8
        },
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 142, width: 300 }}>
                    <img src={language.language_locale === 'es'? step7 : step7En} className='w-100 h-100' alt="step6-graph-calendar" />
                </div>,
            title: language.onboarding_alert_title_step7,
            description: language.onboarding_alert_description_step7,
            placement: "left-end",
            target: "#channels_picker",
            disableBeacon: true,
            steps: 8
        },
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 124, width: 300 }}>
                    <img src={language.language_locale === 'es'? step8 : step8En} className='w-100 h-100' alt="step8-graph-calendar" />
                </div>,
            title: language.onboarding_alert_title_step8,
            description: language.onboarding_alert_description_step8,
            placement: "left-end",
            target: "#channels_picker",
            disableBeacon: true,
            steps: 8
        }
    ])
};