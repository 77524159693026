import * as users from '../creators/Users';

import { GETData, SETData } from '../../services/WebServices';

import { Alert } from 'rsuite';

const url_api = 'https://api.neu.com.co/';

export const setUser = payload => {
    return {
        type: users.SET_USER,
        payload
    }
}

export const setUserCapabilities = payload => {
    return {
        type: users.SET_USER_CAPABILITIES,
        payload
    }
}

export const setProfileProgress = payload => {
    return {
        type: users.SET_PROFILE_PROGRESS,
        payload
    }
}

export const setSavingInfoUser = payload => {
    return {
        type: users.SET_SAVING_INFO_USER,
        payload
    }
}

export const setShowCreateUserModal = payload => {
    return {
        type: users.SET_SHOW_CREATE_USER_MODAL,
        payload
    }
}

export const setShowUsersListModal = payload => {
    return {
        type: users.SET_SHOW_USERS_LIST_MODAL,
        payload
    }
}

export const setUserJobs = payload => {
    return {
        type: users.SET_USER_JOBS,
        payload
    }
}

export const setUserChannels = payload => {
    return {
        type: users.SET_USER_CHANNELS,
        payload
    }
}

export const setUserPersonalInterests = payload => {
    return {
        type: users.SET_USER_PERSONAL_INTERESTS,
        payload
    }
}

export const setUsersAccount = payload => {
    return {
        type: users.SET_USERS_ACCOUNT,
        payload
    }
}

export const setUserAccessEdit = payload => {
    return {
        type: users.SET_USER_ACCESS_EDIT,
        payload
    }
}

export const setUsersAccountLoading = payload => {
    return {
        type: users.SET_USERS_ACCOUNT_LOADING,
        payload
    }
}

export const setUserPreferenceInfo = payload => {
    return {
        type: users.SET_USER_PREFERENCE_INFO,
        payload
    }
}

export const setShowModalUserPreference = payload => {
    return {
        type: users.SET_SHOW_MODAL_USER_PREFERENCE,
        payload
    }
}

export const setUserTokenApi = payload => {
    return {
        type: users.SET_USER_TOKEN_API,
        payload
    }
}

export const setLoadingNewUser = payload => {
    return {
        type: users.SET_LOADING_NEW_USER,
        payload
    }
}

export const getUserExtraDataAsync = () => {
    return (dispatch, getState) => {

        let user = getState().users.user;

        GETData(`users/users/extradata/${user.idUser}`, 'GET')
            .then((response) => {
                if (response!== null) {dispatch(setUser({...user, ...response}))}
            })
            .catch(response => console.error(response));
    }
}

export const getUserImageAsync = () => {
    return (dispatch, getState) => {

        let user = getState().users.user;

        GETData(`users/users/${user.idUser}`, 'GET')
            .then((response) => {
                dispatch(setUser({...user, image: response.image }))
            })
            .catch(response => console.error(response));
    }
}

export const updateInfoUserAsync = (payload) => {
    return (dispatch, getState) => {

        const user = getState().users.user;
        const userJobs = getState().users.userJobs;

        dispatch(setSavingInfoUser(true));

        SETData(`users/users/${user.idUser}`, "PATCH", payload)
            .catch((response) => console.error(response))
            .finally(() => {
                SETData(`users/users/extradata/${user.idUser}`, "PATCH", payload)
                    .then(() => {
                        dispatch(setUser(
                            {
                                ...user, 
                                ...payload, 
                                jobs:  payload.roleIds ? userJobs.filter(j => payload.roleIds.includes(j.idJob)) : user.jobs 
                            }
                        ))
                    })
                    .catch(response => console.error(response))
                    .finally(() => dispatch(setSavingInfoUser(false)))
            });

    }
}

export const loadUserJobsAsync = () => {
    return (dispatch) => {

        GETData(`users/jobs`, 'GET')
            .then((response) => {
                if (response!== null) {dispatch(setUserJobs(response))}
            })
            .catch(response => console.error(response));

    }
}

export const loadUserChannelsAsync = () => {
    return (dispatch) => {

        GETData(`users/users/channels`, 'GET')
            .then((response) => {
                if (response!== null) {dispatch(setUserChannels(response))}
            })
            .catch(response => console.error(response));

    }
}

export const loadUserPersonalInterestsAsync = () => {
    return (dispatch) => {

        GETData(`users/users/interests`, 'GET')
            .then((response) => {
                if (response!== null) {dispatch(setUserPersonalInterests(response))}
            })
            .catch(response => console.error(response));

    }
}

export const createUserAsync = (payload) => {
    return (dispatch, getState) => {

        const user = getState().users.user;
        const language = getState().shared.language;      
        
        dispatch(setLoadingNewUser(true));        

        SETData(`users/users/create/${user.idUser}`, "POST", payload)
            .then((response) => {
                if (response!== null) {                    
                    Alert.success(language.alert_new_user_popup_success);
                    dispatch(setShowCreateUserModal(0));
                    dispatch(loadUsersByAccountAsync());
                    dispatch(setShowUsersListModal(true));                    
                } else
                    Alert.error(language.alert_new_user_popup_failed);
            })
            .catch(response => console.error(response))
            .finally(() => dispatch(setLoadingNewUser(false)))

    }
}

export const updateUserAsync = (payload) => {
    return (dispatch, getState) => {

        const data = {
            name: payload.name,
            lastname: payload.lastname,
            email: payload.email,
            phone: payload.phone,
            idDocument: payload.idDocument,
            idJob: payload.idJob,
            roleIds: payload.roleIds,
            servicesIds: payload.servicesIds
        };

        const language = getState().shared.language;

        SETData(`users/users/update/${payload.idUser}`, "PUT", data)
            .then((response) => {
                if (response!== null) {
                    Alert.success(language.alert_edit_user_popup_success);
                    dispatch(setShowCreateUserModal(0));
                    dispatch(loadUsersByAccountAsync());
                    dispatch(setShowUsersListModal(true));
                } else
                    Alert.error(language.alert_edit_user_popup_failed);
            })
            .catch(response => console.error(response))

    }
}

export const deleteUserAsync = (payload) => {
    return (dispatch, getState) => {

        const language = getState().shared.language;

        GETData(`users/users/${payload}`, "DELETE")
            .then((response) => {
                if (response !== null) {
                    Alert.success(language.alert_delete_user_success);
                    dispatch(setShowCreateUserModal(0));
                    dispatch(loadUsersByAccountAsync());
                    dispatch(setShowUsersListModal(true));
                } else
                    Alert.error(language.alert_delete_user_error);
            })
            .catch(response => console.error(response));

    }
}

export const loadUsersByAccountAsync = () => {
    return (dispatch, getState) => {

        const accountId = getState().accounts.account?.idAccount;

        dispatch(setUsersAccountLoading(true));

        GETData(`users/users/account/${accountId}`, "GET")
            .then(response => {                     
                if (response !== null)
                    dispatch(setUsersAccount(response));                
                else
                    dispatch(setUsersAccount([])); 
            })
            .catch(response => console.error(response))
            .finally(() => dispatch(setUsersAccountLoading(false)));

    }
}

export const createUserPreferenceInfoAsync = (payload) => {
    return (dispatch, getState) => {
        
        const language = getState().shared.language;

        SETData('users/users/preferencedata', "POST", payload)
            .then((response) => {
                if (response!== null) {                    
                    Alert.success(language.alert_create_preference_info_user_success); 
                    dispatch(setShowModalUserPreference(true));                   
                } else
                    Alert.error(language.alert_create_preference_info_user_error);
                    dispatch(setShowModalUserPreference(false));
            })
            .catch(response => console.error(response));

    }
}

export const getUserPreferenceInfoAsync = () => {
    return (dispatch, getState) => {

        let user = getState().users.user;

        GETData(`users/users/preferencedata/${user.idUser}`, 'GET')
            .then((response) => {
                if (response!== null) {dispatch(setUserPreferenceInfo(response))}
            })
            .catch(response => console.error(response));
    }
}

export const generateUserApiTokenAsync = () => {
    return (dispatch, getState) => {

        const user = getState().users.user;        
        let language = getState().shared.language;        

        const metadata = {
            id_user: user.idUser,
            email: user.email,            
            credential: "JNKDind0348NJF38HSj739hn93h87B37IOJN0hB73B8h3uUJNh8"
        }

        SETData("token/create", "POST", metadata, url_api)
            .then((response) => {                
                if (response != null) { dispatch(getUserApiTokenAsync())}
            })
            .catch(response => {
                console.error(response)
                Alert.error(language.alert_generate_token_api_error);
            });

    }
}

export const getUserApiTokenAsync = () => {
    return (dispatch, getState) => {

        let user = getState().users.user;

        GETData(`users/users/tokenapi/${user.idUser}`, 'GET')
            .then((response) => {
                if (response!== null) {dispatch(setUserTokenApi(response?.token))}
            })
            .catch(response => console.error(response));
    }
}