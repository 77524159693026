import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Drawer, Animation } from 'rsuite';

import IntervalTime from './IntervalTime';
import Input from '../shared/utilities/Input';
import CheckPicker from '../shared/utilities/CheckPicker';

import * as alertsActions from '../../store/actions/Alerts';
import * as sharedActions from '../../store/actions/Shared';

import _ from 'lodash';
import { track } from "@amplitude/analytics-browser";
import BackButton from '../shared/utilities/BackButton';

const conditions = language => [
    {
        id_condition: 0,
        label: language.alerts_condition_greater_than
    },
    {
        id_condition: 2,
        label: language.alerts_condition_same_as
    },
    {
        id_condition: 1,
        label: language.alerts_condition_less_than
    },
];

const channels = language => [
    {
        id_channel: 1,
        label: language.alerts_channel_email,
        eventProperty: 'Mail_Alert'
    },
    {
        id_channel: 3,
        label: language.alerts_channel_text_message,
        eventProperty: 'Msm_Alert'
    },
    {
        id_channel: 2,
        label: language.alerts_channel_notification,
        eventProperty: 'Notification_Alert'
    },
];

const days = language => [
    {
        id_day: 1,
        label: language.alerts_select_days_monday,
        eventProperty: 'Monday_Interval'
    },
    {
        id_day: 2,
        label: language.alerts_select_days_tuesday,
        eventProperty: 'Tuesday_Interval'
    },
    {
        id_day: 3,
        label: language.alerts_select_days_wednesday,
        eventProperty: 'Wednesday_Interval'
    },
    {
        id_day: 4,
        label: language.alerts_select_days_thursday,
        eventProperty: 'Thursday_Interval'
    },
    {
        id_day: 5,
        label: language.alerts_select_days_friday,
        eventProperty: 'Friday_Interval'
    },
    {
        id_day: 6,
        label: language.alerts_select_days_saturday,
        eventProperty: 'Saturday_Interval'
    },
    {
        id_day: 7,
        label: language.alerts_select_days_sunday,
        eventProperty: 'Sunday_Interval'
    },
];

const intervals = language => [
    {
        id_interval: 0,
        label: language.alerts_select_interval_hourly,
        eventProperty: 'Hourly_Interval'
    },
    {
        id_interval: 1,
        label: language.alerts_select_interval_daily,
        eventProperty: 'Daily_Interval'
    },
    {
        id_interval: 2,
        label: language.alerts_select_interval_weekly,
        eventProperty: 'Weekly_Interval'
    },
    {
        id_interval: 3,
        label: language.alerts_select_interval_monthly,
        eventProperty: 'Monthly_Interval'
    }
];

const Alerts = (props) => {

    const initAlert = {
        idAlert: null,
        name: null,
        serviceIds: [],
        graphIds: [],
        condition: [],
        value: null,
        channelIds: [],
        intervalTime: null,
    }

    const [alert, setAlert] = useState(initAlert);
    const [activeService, setActiveService] = useState(false);
    const [activeVariable, setActiveVariable] = useState(false);
    const [activeRule, setActiveRule] = useState(false);
    const [activeValue, setActiveValue] = useState(false);
    const [activeChannel, setActiveChannel] = useState(false);
    const [showAlertPeriod, setShowPeriodAlert] = useState(false);
    const [alertProperty, setAlertProperty] = useState({ Variable_ID: '', Alert_Type: '', Project_Quantity: 0, Interval_ID: '', Interval_Day: '' })

    let showNewAlert = props.showNewAlert;
    let newAlert = props.newAlert;
    let graphs = props.graphs;
    let language = props.language;

    const setControlledOnboarding = props.setControlledOnboarding;

    useEffect(() => {
        if (showNewAlert && newAlert) {
            setControlledOnboarding({ key: 'new_alert', value: true })
        } else {
            setControlledOnboarding({ key: 'new_alert', value: false })
        }
    }, [showNewAlert, setControlledOnboarding, newAlert])

    useEffect(() => {
        if (props.alertSelected) {

            const fromHour = parseInt(props.alertSelected?.start_hour?.split(':')[0]);
            const fromHourFormated = fromHour > 12 ? fromHour - 12 : fromHour;
            const toHour = parseInt(props.alertSelected?.end_hour?.split(':')[0]);
            const toHourFormated = toHour > 12 ? toHour - 12 : toHour;

            const alertTime = {
                dayIds: props.alertSelected?.active_week_days,
                fromHour: fromHourFormated < 10 ? '0' + String(fromHourFormated) : String(fromHourFormated),
                fromMinutes: props.alertSelected?.start_hour?.split(':')[1],
                fromPartOfDay: fromHour > 12 ? 'pm' : 'am',
                toHour: toHourFormated < 10 ? '0' + String(toHourFormated) : String(toHourFormated),
                toMinutes: props.alertSelected?.end_hour?.split(':')[1],
                toPartOfDay: toHour > 12 ? 'pm' : 'am',
                intervalIds: [props.alertSelected?.id_frequency],
                dates: []
            }

            setAlert({
                ...alert, idAlert: props.alertSelected?.id_alert,
                name: props.alertSelected?.alert_name,
                serviceIds: props.alertSelected?.services?.map(item => item?.id),
                graphIds: [props.alertSelected?.id_graph],
                condition: [props.alertSelected?.id_alert_type],
                value: props.alertSelected?.value,
                channelIds: props.alertSelected?.channels.map(item => item?.id),
                intervalTime: alertTime
            })
        }
        // eslint-disable-next-line
    }, [props.alertSelected]);


    const formatLabel = (array1, array2, keyName, labelName) => {

        let array3 = array1.map(x => array2.filter(y => y[keyName] === x))
            .flat()

        return array3.map((s, index) => {
            if (index === array3.length - 1) {
                return `${s[labelName]}. `;
            } else {
                return `${s[labelName]}, `;
            }

        }).join("");
    }

    const formatAmplitudeEvent = (array1, array2, keyName, labelName) => {

        let array3 = array1.map(x => array2.filter(y => y[keyName] === x))
            .flat()

        return array3.map((s, index) => {
            return s[labelName]
        });
    }

    const translatedGraphs = props.graphs && props.graphs.options.map((_graph) => {
        const labelTranslation = props.language[`energy_graph_label_${_graph.value.toLowerCase()}`]

        return {
            ..._graph,
            labelTranslation: labelTranslation,
        }
    })

    const formatGraphLabel = () => {
        if (!translatedGraphs) return
        const graphSelected = translatedGraphs.filter((graph) => graph.id_graph === alert.graphIds[0])
        return graphSelected[0].labelTranslation
    }

    useEffect(() => {
        if (alert.serviceIds.length) { setAlertProperty(prevState => ({ ...prevState, Project_Quantity: alert.serviceIds.length })) }
        if (alert.graphIds.length) { setAlertProperty(prevState => ({ ...prevState, Variable_ID: graphs.options.filter(g => g.id_graph === alert.graphIds[0])[0]?.value })) }
        if (alert.channelIds.length) { setAlertProperty(prevState => ({ ...prevState, Alert_Type: formatAmplitudeEvent(alert.channelIds, channels(language), 'id_channel', 'eventProperty') })) }
        if (alert.intervalTime?.intervalIds.length) { setAlertProperty(prevState => ({ ...prevState, Interval_ID: formatAmplitudeEvent(alert.intervalTime.intervalIds, intervals(language), 'id_interval', 'eventProperty') })) }
        if (alert.intervalTime?.dayIds.length) { setAlertProperty(prevState => ({ ...prevState, Interval_Day: formatAmplitudeEvent(alert.intervalTime.dayIds, days(language), 'id_day', 'eventProperty') })) }
    }, [alert, setAlertProperty, graphs, language])


    const handleClose = () => {
        props.setShowNewAlert(false)
        props.setAlertSelected(null)
        props.setEditAlert(false)
        setAlert(initAlert)
    }

    return (
        <Drawer
            show={props.showNewAlert}
            size={'xs'}
            placement={'right'}
            backdropClassName={'pe-pointer'}
            onHide={() => handleClose()}>
            <div className="vh-100 w-100 overflow-auto bg-gray-500">
                <div className='column-start align-items-center py-3 px-4 h-100'>
                    <div className='row-between w-100 align-items-center my-3'>
                        <BackButton onClick={() => handleClose()}></BackButton>
                        <div className='row-center align-items-center gap-2'>
                            <i className='fas fa-square-bolt txt-white txt-10'></i>
                            <p className='txt-phrase txt-white txt-bold txt-10'>{props.alertSelected && props.editAlert ? props.language.alerts_label_title_edit_alert : props.language.alerts_label_title_new_alert}</p>
                        </div>
                        <p className='txt-phrase txt-bold txt-green-500 txt-8 pe-pointer'
                            onClick={async () => {
                                if (props.editAlert) {
                                    await props.editAlertAsync(alert);
                                }
                                else {
                                    await props.createAlertAsync(alert);
                                }
                                props.setShowNewAlert(false);
                                props.setAlertSelected(null);
                                props.setEditAlert(false);
                                setAlert(initAlert);
                                track('Alert created', { ...alertProperty, Project_Type: props.totalServices.filter(t => t.idService === alert.serviceIds[0])[0]?.type?.name })
                            }}>
                            {props.language.alerts_button_save}
                        </p>
                    </div>
                    <div className='row-center w-100 p-2 bg-green-200 rounded-8'>
                        <p className='txt-phrase txt-semibold txt-neutral-800 txt-8 text-wrap'>{props.language.alerts_label_body_new_alert}</p>
                    </div>
                    <div className='row-center w-100 my-3'>
                        <Input label={'Nombre de la alerta'} type={'text'} value={alert.name ? alert.name : ''}
                            onChange={({ target }) => {
                                setAlert({ ...alert, name: target.value })
                            }}
                        />
                    </div>
                    <div id='services_picker' className="row-between align-items-center col-12" onClick={() => setActiveService(!activeService)}>
                        <div className="position-relative column-start align-items-center col-1 h-100">
                            <div className='row-center align-items-center position-absolute top-0 bg-gray-800 rounded-circle zindex-2' style={{ height: 30, width: 30, left: "calc(50% - 17px)" }}>
                                <i className={`fal fa-map-pin txt-10 txt-${activeService || alert.serviceIds.length ? 'green-500' : 'gray-700'}`}></i>
                            </div>
                            <div className={`w-50 h-100 position-absolute top-0 left-0 border-right-4 border-color-${activeService || alert.serviceIds.length ? 'green-500' : 'gray-700'}`}></div>
                        </div>
                        <div className='col-11 column-start ps-3 pb-3'>
                            <div className='row-between align-items-center w-100'>
                                <p className={`txt-phrase txt-semibold txt-${activeService || alert.serviceIds.length ? 'white' : 'gray-700'} txt-8`}>{alert.serviceIds.length ? language.alerts_new_alert_service_item : language.alerts_new_alert_service_label}</p>
                                <i className={`fas fa-${alert.serviceIds.length && !activeService ? 'circle-check txt-green-500 select-detail-check' : 'circle-chevron-right'} txt-10 pe-pointer ${!activeService && !alert.serviceIds.length ? 'select-detail-open txt-gray-700' : 'select-detail-close txt-green-500'}`}></i>
                            </div>
                            <p className={`txt-phrase txt-regular txt-${activeService || alert.serviceIds.length ? 'white' : 'gray-600'} txt-8 text-wrap`}>{alert.serviceIds.length ? formatLabel(alert.serviceIds, props.totalServices, 'idService', 'name') : language.alerts_new_alert_service_example}</p>
                        </div>
                    </div>
                    <Animation.Collapse in={activeService} dimension={'height'}>
                        <div className='column-start w-100'>
                            <div className='row-between align-items-center w-100 h-100'>
                                <div className="position-relative column-start align-items-center col-1 h-100">
                                    <div className={`w-50 h-100 position-absolute top-0 left-0 border-right-4 border-color-${activeService ? 'green-500' : 'gray-700'}`}></div>
                                </div>
                                <div className='col-11 column-start ps-3'>
                                    <CheckPicker
                                        keyName='idService'
                                        labelName='name'
                                        options={props.totalServices}
                                        selectedData={alert.serviceIds}
                                        onChange={(value) => { setAlert({ ...alert, serviceIds: value }) }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Animation.Collapse>
                    <div id='variable_picker' className="row-between align-items-center col-12" onClick={() => setActiveVariable(!activeVariable)}>
                        <div className="position-relative column-start align-items-center col-1 h-100">
                            <div className='row-center align-items-center position-absolute bg-gray-800 rounded-circle zindex-2' style={{ height: 30, width: 30, top: "calc(50% - 15px)", left: "calc(50% - 17px)" }}>
                                <i className={`fal fa-bars txt-10 txt-${activeVariable || alert.graphIds.length ? 'green-500' : 'gray-700'}`}></i>
                            </div>
                            <div className={`w-50 h-100 position-absolute top-0 left-0 border-right-4 border-color-${activeVariable || alert.graphIds.length ? 'green-500' : 'gray-700'}`}></div>
                        </div>
                        <div className='col-11 column-start ps-3 py-3'>
                            <div className='row-between align-items-center w-100'>
                                <p className={`txt-phrase txt-semibold txt-${activeVariable || alert.graphIds.length ? 'white' : 'gray-700'} txt-8`}>{alert.graphIds.length ? language.alerts_new_alert_variable_item : language.alerts_new_alert_variable_label}</p>
                                <i className={`fas fa-${alert.graphIds.length && !activeVariable ? 'circle-check txt-green-500 select-detail-check' : 'circle-chevron-right'} txt-10 pe-pointer ${!activeVariable && !alert.graphIds.length ? 'select-detail-open txt-gray-700' : 'select-detail-close txt-green-500'}`}></i>
                            </div>
                            <p className={`txt-phrase txt-regular txt-${activeVariable || alert.graphIds.length ? 'white' : 'gray-600'} txt-8`}>{alert.graphIds.length ? formatGraphLabel() : language.alerts_new_alert_variable_example}</p>
                        </div>
                    </div>
                    <Animation.Collapse in={activeVariable} dimension={'height'}>
                        <div className='column-start w-100'>
                            <div className='row-between align-items-center w-100 h-100'>
                                <div className="position-relative column-start align-items-center col-1 h-100">
                                    <div className={`w-50 h-100 position-absolute top-0 left-0 border-right-4 border-color-${activeVariable ? 'green-500' : 'gray-700'}`}></div>
                                </div>
                                <div className='col-11 column-start ps-3'>
                                    <CheckPicker
                                        keyName='id_graph'
                                        labelName='labelTranslation'
                                        options={translatedGraphs}
                                        selectedData={alert.graphIds}
                                        onChange={(value) => { setAlert({ ...alert, graphIds: [_.last(value)] }) }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Animation.Collapse>
                    <div id='rules_picker' className="row-between align-items-center col-12" onClick={() => setActiveRule(!activeRule)}>
                        <div className="position-relative column-start align-items-center col-1 h-100">
                            <div className='row-center align-items-center position-absolute bg-gray-800 rounded-circle zindex-2' style={{ height: 30, width: 30, top: "calc(50% - 15px)", left: "calc(50% - 17px)" }}>
                                <i className={`fal fa-inbox txt-10 txt-${activeRule || alert.condition.length ? 'green-500' : 'gray-700'}`}></i>
                            </div>
                            <div className={`w-50 h-100 position-absolute top-0 left-0 border-right-4 border-color-${activeRule || alert.condition.length ? 'green-500' : 'gray-700'}`}></div>
                        </div>
                        <div className='col-11 column-start ps-3 py-3'>
                            <div className='row-between align-items-center w-100'>
                                <p className={`txt-phrase txt-semibold txt-${activeRule || alert.condition.length ? 'white' : 'gray-700'} txt-8`}>{alert.condition.length ? language.alerts_new_alert_rule_item : language.alerts_new_alert_rule_label}</p>
                                <i className={`fas fa-${alert.condition.length && !activeRule ? 'circle-check txt-green-500 select-detail-check' : 'circle-chevron-right'} txt-10 pe-pointer ${!activeRule && !alert.condition.length ? 'select-detail-open txt-gray-700' : 'select-detail-close txt-green-500'}`}></i>
                            </div>
                            <p className={`txt-phrase txt-regular txt-${activeRule || alert.condition.length ? 'white' : 'gray-600'} txt-8`}>{alert.condition.length ? formatLabel(alert.condition, conditions(props.language), 'id_condition', 'label') : language.alerts_new_alert_rule_example}</p>
                        </div>
                    </div>
                    <Animation.Collapse in={activeRule} dimension={'height'}>
                        <div className='column-start w-100'>
                            <div className='row-between align-items-center w-100 h-100'>
                                <div className="position-relative column-start align-items-center col-1 h-100">
                                    <div className={`w-50 h-100 position-absolute top-0 left-0 border-right-4 border-color-${activeRule ? 'green-500' : 'gray-700'}`}></div>
                                </div>
                                <div className='col-11 column-start ps-3'>
                                    <CheckPicker
                                        keyName='id_condition'
                                        labelName='label'
                                        options={conditions(props.language)}
                                        selectedData={alert.condition}
                                        onChange={(value) => { setAlert({ ...alert, condition: [_.last(value)] }) }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Animation.Collapse>
                    <div id='value_input' className="row-between align-items-center col-12" onClick={() => setActiveValue(!activeValue)}>
                        <div className="position-relative column-start align-items-center col-1 h-100">
                            <div className='row-center align-items-center position-absolute bg-gray-800 rounded-circle zindex-2' style={{ height: 30, width: 30, top: "calc(50% - 15px)", left: "calc(50% - 17px)" }}>
                                <i className={`fal fa-input-numeric txt-10 txt-${activeValue || alert.value ? 'green-500' : 'gray-700'}`}></i>
                            </div>
                            <div className={`w-50 h-100 position-absolute top-0 left-0 border-right-4 border-color-${activeValue || alert.value ? 'green-500' : 'gray-700'}`}></div>
                        </div>
                        <div className='col-11 column-start ps-3 py-3'>
                            <div className='row-between align-items-center w-100'>
                                <p className={`txt-phrase txt-semibold txt-${activeValue || alert.value ? 'white' : 'gray-700'} txt-8`}>{alert.value ? language.alerts_new_alert_value_item : language.alerts_new_alert_value_label}</p>
                                <i className={`fas fa-${alert.value && !activeValue ? 'circle-check txt-green-500 select-detail-check' : 'circle-chevron-right'} txt-10 pe-pointer ${!activeValue && !alert.value ? 'select-detail-open txt-gray-700' : 'select-detail-close txt-green-500'}`}></i>
                            </div>
                            <p className={`txt-phrase txt-regular txt-${activeValue || alert.value ? 'white' : 'gray-600'} txt-8`}>{alert.value ? `${alert.value} ${alert?.graphIds?.length && props.graphs?.length ? props.graphs.options.filter(x => x?.id_graph === alert?.graphIds[0])[0]?.unit : ""}` : language.alerts_new_alert_value_example}</p>
                        </div>
                    </div>
                    <Animation.Collapse in={activeValue} dimension={'height'}>
                        <div className='column-start w-100'>
                            <div className='row-between align-items-center w-100 h-100'>
                                <div className="position-relative column-start align-items-center col-1 h-100">
                                    <div className={`w-50 h-100 position-absolute top-0 left-0 border-right-4 border-color-${activeValue ? 'green-500' : 'gray-700'}`}></div>
                                </div>
                                <div className='col-11 column-start ps-3 pb-3'>
                                    <Input label={'Valor o promedio'} type={'number'} value={alert.value ? alert.value : ''}
                                        onChange={({ target }) => {
                                            setAlert({ ...alert, value: target.value })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Animation.Collapse>
                    <div id='channels_picker' className="row-between align-items-center col-12" onClick={() => setActiveChannel(!activeChannel)}>
                        <div className="position-relative column-start align-items-center col-1 h-100">
                            <div className='row-center align-items-center position-absolute bottom-30 bg-gray-800 rounded-circle zindex-2' style={{ height: 30, width: 30, left: "calc(50% - 17px)" }}>
                                <i className={`fal fa-paper-plane txt-10 txt-${activeChannel || alert.channelIds.length ? 'green-500' : 'gray-700'}`}></i>
                            </div>
                            <div className={`w-50 h-100 position-absolute bottom-30 left-0 border-right-4 border-color-${activeChannel || alert.channelIds.length ? 'green-500' : 'gray-700'}`}></div>
                        </div>
                        <div className='col-11 column-start ps-3 py-3'>
                            <div className='row-between align-items-center w-100'>
                                <p className={`txt-phrase txt-semibold txt-${activeChannel || alert.channelIds.length ? 'white' : 'gray-700'} txt-8`}>{alert.channelIds.length ? language.new_alert_type_title : language.new_alert_select_type}</p>
                                <i className={`fas fa-${alert.channelIds.length && !activeChannel ? 'circle-check txt-green-500 select-detail-check' : 'circle-chevron-right'} txt-10 pe-pointer ${!activeChannel && !alert.channelIds.length ? 'select-detail-open txt-gray-700' : 'select-detail-close txt-green-500'}`}></i>
                            </div>
                            <p className={`txt-phrase txt-regular txt-${activeChannel || alert.channelIds.length ? 'white' : 'gray-600'} txt-8`}>{alert.channelIds.length ? formatLabel(alert.channelIds, channels(props.language), 'id_channel', 'label') : language.alerts_new_alert_channel_example}</p>
                        </div>
                    </div>
                    <Animation.Collapse in={activeChannel} dimension={'height'}>
                        <div className='column-start w-100'>
                            <div className='row-between align-items-center w-100 h-100'>
                                <div className="col-1"></div>
                                <div className='col-11 column-start ps-3'>
                                    <CheckPicker
                                        keyName='id_channel'
                                        labelName='label'
                                        options={channels(props.language)}
                                        selectedData={alert.channelIds}
                                        onChange={(value) => { setAlert({ ...alert, channelIds: value }) }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Animation.Collapse>
                    <div className='w-100 my-3 pb-5'>
                        <div className='column-start p-3 bg-gray-800 rounded-8 pe-pointer' onClick={() => setShowPeriodAlert(true)}>
                            <div className='row-between align-items-center w-100'>
                                <p className={`txt-phrase txt-semibold txt-${alert.intervalTime ? 'white' : 'gray-700'} txt-8`}>{alert.intervalTime?.intervalIds.length ? `Intervalo ${formatLabel(alert.intervalTime.intervalIds, intervals(props.language), 'id_interval', 'label')}` : language.alerts_title_interval_time}</p>
                                <i className={`fas fa-${alert.intervalTime ? 'circle-check txt-green-500 select-detail-check' : 'circle-chevron-right'} txt-10 pe-pointer txt-gray-700`}></i>
                            </div>
                            <p className={`txt-phrase txt-regular txt-${alert.intervalTime ? 'white' : 'gray-600'} txt-8 w-100 text-wrap`}>{alert.intervalTime?.dayIds.length ? `${formatLabel(alert.intervalTime.dayIds, days(props.language), 'id_day', 'label')} ${alert.intervalTime?.fromHour ? alert.intervalTime.fromHour : ''}:${alert.intervalTime?.fromMinutes ? alert.intervalTime.fromMinutes : ''}${alert.intervalTime?.fromPartOfDay ? alert.intervalTime.fromPartOfDay : ''}-${alert.intervalTime?.toHour ? alert.intervalTime.toHour : ''}:${alert.intervalTime?.toMinutes ? alert.intervalTime.toMinutes : ''}${alert.intervalTime?.toPartOfDay ? alert.intervalTime.toPartOfDay : ''}` : language.new_alert_schedule_description}</p>
                        </div>
                    </div>
                </div>
                <IntervalTime
                    onChange={value => setAlert({ ...alert, intervalTime: value })}
                    showAlertPeriod={showAlertPeriod}
                    setShowPeriodAlert={setShowPeriodAlert}
                    intervalTime={alert?.intervalTime}
                />
            </div>
        </Drawer>
    );
}

const mapStateToProps = (state) => ({
    language: state.shared.language,
    totalServices: state.services.totalServices,
    graphs: state.energy.graphs[0],
    newAlert: state.shared.startOnboarding.new_alert
});

const mapDispatchToProps = dispatch => ({
    createAlertAsync: (payload) => dispatch(alertsActions.createAlertAsync(payload)),
    editAlertAsync: (payload) => dispatch(alertsActions.editAlertAsync(payload)),
    setControlledOnboarding: (payload) => dispatch(sharedActions.setControlledOnboarding(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);