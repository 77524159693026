import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';

import * as plusActions from "../../../../store/actions/Plus";

import { VictoryChart, VictoryLabel, VictoryAxis, VictoryBar } from "victory";

import ContentLoader from "react-content-loader";

import empty_modules_plus from "../../../../imgs/empty-modules-plus.svg";

import _ from 'lodash';

import Tooltip from "../../../shared/utilities/Tooltip";

import { Link } from 'react-router-dom';

import { track } from "@amplitude/analytics-browser";

const loadingContainer = <div className="position-absolute h-100 w-100 left-0 top-0 zindex-1">
  <ContentLoader
    seed={2}
    foregroundColor="#161617"
    backgroundColor="#323234"
    style={{ width: "100%", height: "100%" }}>
    <rect x="0" y="0" width="calc(100%)" height="calc(100%)" rx="16" ry="16"></rect>
  </ContentLoader>
</div>;

const axisStyle = {
  tickLabels: {
    fill: "#FFF",
    fontSize: 28,
    fontFamily: "Inter",
    fontWeight: 900
  },
  ticks: { size: 15 },
  axis: { stroke: "transparent" },
}

const handlerFormatAxis = dow => {
  switch (dow) {
    case 1:
      return 'L';
    case 2:
      return 'M';
    case 3:
      return 'W';
    case 4:
      return 'J';
    case 5:
      return 'V';
    case 6:
      return 'S';
    case 7:
      return 'D';
    default:
      return '';
  }
};

const RenderCardEmpty = ({ language }) => {
  return (
    <div className={`swiper-slider-container column-between align-items-center bg-gray-500 rounded-16 p-3`} style={{ height: 200 }}>
      <img src={empty_modules_plus} alt="empty_radar_data" style={{ width: 154, height: 154 }} />
      <div className={`row-center mx-5 align-items-center txt-phrase txt-regular txt-6 txt-neutral-50`}>
        {language.plus_graph_title_empty_data}
      </div>
    </div>
  )
};

const AlwaysOn = (props) => {

  const [avgData, setAvgData] = useState(0);

  useEffect(() => {
    if (props.activeServicesPlus.length) props.loadAlwaysOn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeServicesPlus]);

  useEffect(() => {
    if (props.alwaysOn.length) {
      const dataValues = props.alwaysOn.filter(x => x.value > 0);
      if (dataValues.length) {
        const avg = _.mean(dataValues.map(x => x.value));
        setAvgData(avg);
      }
    }
  }, [props.alwaysOn]);

  return (
    <div className='w-100 my-3'>
      {!props.loadingAlwaysOn ? !props.alwaysOn.length ?
        <div className="column-between align-items-center bg-gray-500 border-1 border-color-gray-800 rounded-16 p-3 w-100">
          <p className="txt-phrase txt-8 txt-white txt-bold">{props.language.plus_always_on_title}</p>
          <div className="position-relative row-center align-items-center w-100 h-100">
            <RenderCardEmpty language={props.language} />
          </div>
        </div> :
        <div className="column-between align-items-center bg-gray-500 border-1 border-color-gray-800 rounded-16 p-3 w-100">
          <div className="row-between align-items-center w-100">
            <div className="row-start">
              <Tooltip text={props.language.plus_always_on_tooltip_info} amplitudeEvent={true} />
              <p className="txt-phrase txt-8 txt-white txt-bold ms-3">{props.language.plus_always_on_title}</p>
            </div>
            <Link to="/plus/always-on">
              <i className="fal fa-arrow-up-right-and-arrow-down-left-from-center txt-gray-700 txt-10 pe-pointer" onClick={() => track('Standby opened')}></i>
            </Link>
          </div>
          <VictoryChart
            height={500}
            width={700}
            domainPadding={50}
            padding={{ bottom: 90, left: 15, right: 15 }}>
            <VictoryAxis tickFormat={(x) => `${handlerFormatAxis(x)}`} style={axisStyle} />
            <VictoryBar
              x={"dow"}
              y={"value"}
              barRatio={1}
              data={props.alwaysOn}
              labels={({ datum }) => `${datum.value} Wh`}
              labelComponent={<VictoryLabel y={390} textAnchor={'start'} verticalAnchor={'start'} style={{ fill: ({ datum }) => (datum.value >= avgData ? "#161617" : "#FFF"), fontSize: 24, fontFamily: "Inter", fontWeight: 900 }} angle={270} />}
              cornerRadius={{ bottomLeft: 35, bottomRight: 35, topLeft: 35, topRight: 35 }}
              style={{ data: { fill: ({ datum }) => (datum.value >= avgData ? "url(#linearRedOrangeGradient)" : "#323234") } }} />
          </VictoryChart>
        </div>
        :
        <div className="position-relative w-100" style={{ height: 243 }}>{loadingContainer}</div>}
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeServicesPlus: state.services.activeServicesPlus,
  alwaysOn: state.plus.alwaysOn,
  loadingAlwaysOn: state.plus.loadingAlwaysOn,
  language: state.shared.language
});

const mapDispatchToProps = dispatch => ({
  loadAlwaysOn: () => dispatch(plusActions.loadAlwaysOn()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlwaysOn);


