import * as notifications from "../creators/Notifications";

import { GETData, SETData } from "../../services/WebServices";

export const setNotifications = payload => {
    return {
        type: notifications.SET_NOTIFICATIONS,
        payload,
    };
};

export const setNotificationsLoading = payload => {
    return {
        type: notifications.SET_NOTIFICATIONS_LOADING,
        payload,
    };
};

export const setNotificationsPagination = payload => {
    return {
        type: notifications.SET_NOTIFICATIONS_PAGINATION,
        payload,
    };
};

export const setTotalNotificationsInfo = payload => {
    return {
        type: notifications.SET_TOTAL_NOTIFICATIONS_INFO,
        payload,
    };
};

export const loadNotificationsAsync = (type) => {
    return (dispatch, getState) => {

        dispatch(setNotificationsLoading(true));
        const notifications = getState().notifications.notifications; 
        const page = getState().notifications.notificationsPagination || 1; 

        const userId = getState().users.user.idUser;        

        GETData(`notifications/${userId}?page=${page}&page_limit=10&all_registers=${true}`, "GET")
            .then(response => {      
                if (response !== null){
                    dispatch(setNotificationsPagination(response.pagination_info.next_page));
                    dispatch(setNotifications([...notifications, ...response.data]));
                }
            })
            .catch(response => console.error(response))
            .finally(() => dispatch(setNotificationsLoading(false)));
  };

};

export const loadTotalNotificationsAsync = () => {
    return (dispatch, getState) => {

        const userId = getState().users.user.idUser;        

        GETData(`notifications/count/${userId}`, "GET")
            .then(response => { 
                if (response !== null){
                    dispatch(setTotalNotificationsInfo(response));
                }
            })
            .catch(response => console.error(response));
  };

};

export const deleteNotificationAsync = (id) => {
    return (dispatch, getState) => {    

        const notifications = getState().notifications.notifications; 

        GETData(`notifications/${id}`, "DELETE")
            .then(() => { 

                let newNotifications = notifications.filter(item => item.id_register !== id);

                dispatch(setNotifications(newNotifications));

            })
            .catch(response => console.error(response));
  };
  
};

export const updateNotificationAsync = (payload) => {
    return (dispatch, getState) => {    

        let notifications = getState().notifications.notifications; 

        SETData(`notifications/${payload.id}`, "PUT", payload)
            .then((response) => {    

                notifications.forEach((notification) => {
                    if (notification.id_register === response.id_register) {
                        notification.read = response.read
                    }
                });

                dispatch(setNotifications([...notifications]));   

            })
            .catch(response => console.error(response));
  };
  
};