import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { useHistory } from "react-router-dom";

import * as accountsActions from '../../store/actions/Accounts';
import * as notificationsActions from '../../store/actions/Notifications';

import Support from './Support';
import Neugets from './Neugets';
import Notifications from '../notifications/Notifications';

import BackButton from './utilities/BackButton';

import Chatbot from './Chatbot';

const Header = (props) => {

    let history = useHistory();

    let account = props.account;
    let user = props.user;
    let totalNotificationsInfo = props.totalNotificationsInfo;

    const loadNeugetsByAccountAsync = props.loadNeugetsByAccountAsync;
    const loadTotalNotificationsAsync = props.loadTotalNotificationsAsync;

    const [showSupport, setShowSupport] = useState(false);
    const [showNeugets, setShowNeugets] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);
    const [showChatBot, setShowChatBot] = useState(false);

    useEffect(() => {
        if (account !== null)
            loadNeugetsByAccountAsync();
    }, [account, loadNeugetsByAccountAsync]);

    useEffect(() => loadTotalNotificationsAsync(), [loadTotalNotificationsAsync]);

    return (
        <Fragment>
            <header id='navigate_dashboard' className="row-between align-items-center w-100 px-3">
                <div className="row-center align-items-center gap-3 py-3">
                    {
                        props.backToButton &&
                            <BackButton onClick={() => history.goBack()}/>
                    }
                    <span className="txt-title txt-bold txt-14 txt-white max-width-300">{props.moduleName}</span>
                </div>
                <div className="row-start align-items-center">
                    {
                        <Chatbot showChatBot={showChatBot} setShowChatBot={setShowChatBot} enableChatBot={user?.capabilities?.show_chatbot}/>
                    }
                    {
                        user.capabilities?.show_header_emergency_button &&
                        <div id='navigate_emergency' className="button-support pe-pointer bg-gray-500 border-1 border-color-gray-800 rounded-pill overflow-hidden me-3"
                            onClick={() => {
                                window.open(user.userType.idUserType === 11 ? 'https://api.whatsapp.com/send?phone=50765229500&text=%C2%A1Estamos%20felices%20de%20poder%20ayudarte!' : user.capabilities?.emergency_line);
                            }}
                        >
                            <div className="row-center align-items-center h-100">
                                <div className="button-icon row-center align-items-center">
                                    <i className="fa-light fa-light-emergency-on txt-12 txt-gray-700" />
                                </div>
                                <span className="txt-phrase txt-regular txt-8 txt-overflow-unset txt-white pe-pointer">{props.language.support_emergency_button}</span>
                            </div>
                        </div>
                    }
                    {
                        user.capabilities?.show_header_support_button &&
                        <div id='navigate_support' className="button-support pe-pointer bg-gray-500 border-1 border-color-gray-800 rounded-pill overflow-hidden me-3" onClick={() => { setShowSupport(true); }}>
                            <div className="row-center align-items-center h-100">
                                <div className="button-icon row-center align-items-center">
                                    <i className="fa-light fa-headset txt-12 txt-gray-700" />
                                </div>
                                <span className="txt-phrase txt-regular txt-8 txt-overflow-unset txt-white pe-pointer">{props.language.support_help_button}</span>
                            </div>
                        </div>
                    }
                    {
                        user.capabilities?.show_header_notifications_button &&
                        <div id='navigate_notification' className="button-support pe-pointer bg-gray-500 border-1 border-color-gray-800 rounded-pill overflow-hidden me-3" onClick={() => { setShowNotifications(true); }}>
                            <div className="row-center align-items-center h-100">
                                <div className="position-relative button-icon row-center align-items-center">
                                    <i className="fa-light fa-bell txt-12 txt-gray-700" />
                                    <i className={`button-icon-notification trans-fade fa-solid fa-circle txt-2 txt-green-500 opacity-${totalNotificationsInfo?.recently_unread > 0 ? '100' : '0'}`} />
                                </div>
                                <span className="txt-phrase txt-regular txt-8 txt-overflow-unset txt-white pe-pointer">{props.language.notifications_title}</span>
                            </div>
                        </div>
                    }
                </div>
            </header>
            <Support showSupport={showSupport} setShowSupport={setShowSupport} />
            <Neugets showNeugets={showNeugets} setShowNeugets={setShowNeugets} />
            <Notifications showNotifications={showNotifications} setShowNotifications={setShowNotifications} />
        </Fragment>
    )
}

const mapStateToProps = (state, ownProps) => ({
    moduleName: ownProps.moduleName,
    backToButton: ownProps.backToButton,
    language: state.shared.language,
    neugets: state.accounts.neugets,
    account: state.accounts.account,
    totalNotificationsInfo: state.notifications.totalNotificationsInfo,
    user: state.users.user
});

const mapDispatchToProps = dispatch => ({
    loadNeugetsByAccountAsync: () => dispatch(accountsActions.loadNeugetsByAccountAsync()),
    loadTotalNotificationsAsync: () => dispatch(notificationsActions.loadTotalNotificationsAsync())
})

export default connect(mapStateToProps, mapDispatchToProps)(Header);