import React from 'react';

import 'react-toastify/dist/ReactToastify.css';

const RateNumber = ({ max, score, onChange }) => {

    let elements = Array.from({length: max}, (e, i)=> i);

    return(
        <div className='w-100 row-center'>
            {elements.map((item, i) => 
                <div key={i} className='col-md-auto px-1 column-between align-items-center'>
                    <i className={`${item + 1 <= score? "fas" : "far"} fa-circle txt-10 ${item + 1 <= 6 ? "txt-red-500" :  item + 1 === 7 ? "txt-orange-500" : "txt-green-500" }`} onClick={() => onChange(item + 1)}/>
                    <div className={`txt-6 mt-2 text-center ${item + 1 <= 6 ? "txt-red-500" :  item + 1 === 7 ? "txt-orange-500" : "txt-green-500" } txt-bold`}>{item + 1}</div>
                </div>)}
        </div>
    )
}

export default RateNumber