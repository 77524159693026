import React from "react"

import logoNeu from "../../../imgs/logo-neu-white.svg"
import logoErco from "../../../imgs/logo-erco.svg"
import AnimatedComponent from "../utils/AnimatedComponent"
import WalletCard from "../../shared/WalletCard"

const notifications = [
  {
    id_register: 1,
    title: "Explora el detalle de tu consumo de energía",
    body: "Ingresa a nuestra plataforma y descubre el detalle de tu consumo de energía.",
    icon: "faCircleInfo",
    color: "#00b7d3",
    timestamp: "2023-11-07T15:33:24.444373+00:00",
    read: false,
  },
  {
    id_register: 2,
    title: "Tu consumo de energía ha disminuido",
    body: "Identificamos una disminución de 12.11% en tu consumo de energía la semana pasada. ¡Revísalo aquí!",
    icon: "faShieldCheck",
    color: "#cefc34",
    timestamp: "2023-10-07T15:33:24.444373+00:00",
    read: false,
  },
  {
    id_register: 3,
    title: "No Data de NEU+",
    body: "Tu NEU+ no esta enviando datos, revisa la última conexión.",
    icon: "faCircleMinus",
    color: "#FD5D5D",
    timestamp: "2023-10-07T15:33:24.444373+00:00",
    read: false,
  },
  {
    id_register: 4,
    title: "Tu consumo de energía está en alza",
    body: "El viernes consumiste 10% más que la semana anterior.",
    icon: "faTriangleExclamation",
    color: "#ffaa00",
    timestamp: "2023-10-07T15:33:24.444373+00:00",
    read: false,
  },
]

const services = [
  { title: "Home", icon: "energy" },
  { title: "Plus", icon: "plus" },
  { title: "Genius", icon: "genius" },
  { title: "Efficiency", icon: "efficiency" },
]

export const stepsDashboard = (language) => {
  return ([
    {
      content: (
        <div className="row-center align-items-center w-100 container-tour rounded-8 mt-3" style={{ height: 140 }}>
          <img src={logoErco} alt="logo-erco" className="me-5" style={{ width: 115 }} />
          <img src={logoNeu} alt="logo-neu-energy" style={{ width: 80 }} />
        </div>
      ),
      title: language.onboarding_dashboard_title_step1,
      description: language.onboarding_dashboard_description_step1,
      placement: "center",
      target: "#navigate_dashboard",
      disableBeacon: true,
      steps: 11,
    },
    {
      content: (
        <div className="row-center align-items-center w-100 container-tour rounded-8 mt-3" style={{ height: 130 }}>
          <div className="row-between align-items-center rounded-16 bg-gray-550 px-3 py-2 gap-3" style={{ height: 60 }}>
            {["energy", "generation", "efficiency", "plus", "genius"].map(logo => (
              <i key={logo} className={`fak fa-${logo}-symbol txt-green-500 txt-14`} />
            ))}
          </div>
        </div>
      ),
      title: language.onboarding_dashboard_title_step2,
      description: language.onboarding_dashboard_description_step2,
      placement: "right",
      target: "#navigate_modules",
      floaterProps: {
        disableAnimation: true,
      },
      disableBeacon: true,
      steps: 11,
    },
    {
      content: (
        <div className="row-center align-items-center w-100 container-tour rounded-8 mt-3" style={{ height: 130 }}>
          <div className="w-75 rounded-16 bg-gray-550 row-center align-items-center" style={{ height: 60 }}>
            <AnimatedComponent data={services} type={0} />
          </div>
        </div>
      ),
      title: language.onboarding_dashboard_title_step3,
      description: language.onboarding_dashboard_description_step3,
      placement: "right",
      target: "#navigate_modules",
      floaterProps: {
        disableAnimation: true,
      },
      disableBeacon: true,
      steps: 11,
    },
    {
      content: (
        <div className="column-center align-items-center gap-2 w-100 container-tour rounded-8 mt-3" style={{ height: 150 }}>
          <div style={{ height: "35%" }}>
            <div className="button-support-onboarding bg-gray-500 border-1 border-color-gray-800 rounded-pill overflow-hidden mt-2">
              <div className="row-start align-items-center h-100">
                <div className="position-relative row-center align-items-center min-width-40">
                  <i className="button-circle-icon position-absolute fa-light fa-light-emergency-on txt-12 txt-gray-700" />
                </div>
                <span className="txt-phrase txt-regular txt-8 txt-overflow-unset txt-white pe-2">{language.support_emergency_button}</span>
              </div>
            </div>
          </div>
          <div className="row-center align-items-center w-100" style={{ height: "65%" }}>
            <div className="column-center align-items-center h-100 bg-gray-550 rounded-top-8" style={{ width: "85%" }}>
              <div className="row-start align-items-center bg-gray-800 border-1 border-color-gray-700 rounded-pill py-2 px-3 mt-1" style={{ width: 240 }}>
                <i className="fa-light fa-circle-check txt-10 txt-gray-700 me-2" />
                <p className="txt-phrase txt-regular txt-8 txt-gray-700">{language.dashboard_steps_power_outages}</p>
              </div>
              <div className="row-start align-items-center bg-gray-800 border-1 border-color-gray-700 rounded-pill py-2 px-3 mt-2" style={{ width: 240 }}>
                <i className="fa-light fa-circle-check txt-10 txt-gray-700 me-2" />
                <p className="txt-phrase txt-regular txt-8 txt-gray-700">{language.dashboard_steps_short_circuit}</p>
              </div>
            </div>
          </div>
        </div>
      ),
      title: language.onboarding_dashboard_title_step4,
      description: language.onboarding_dashboard_description_step4,
      placement: "auto",
      target: "#navigate_emergency",
      floaterProps: {
        disableAnimation: true,
      },
      disableBeacon: true,
      steps: 11,
    },
    {
      content: (
        <div className="column-center align-items-center gap-2 w-100 container-tour rounded-8 mt-3" style={{ height: 150 }}>
          <div style={{ height: "35%" }}>
            <div className="button-support-onboarding bg-gray-500 border-1 border-color-gray-800 rounded-pill overflow-hidden mt-2">
              <div className="row-start align-items-center h-100">
                <div className="position-relative row-center align-items-center min-width-40">
                  <i className="button-circle-icon position-absolute fa-light fa-light-emergency-on txt-12 txt-gray-700" />
                </div>
                <span className="txt-phrase txt-regular txt-8 txt-overflow-unset txt-white pe-2">{language.dashboard_steps_support}</span>
              </div>
            </div>
          </div>
          <div className="row-center align-items-center w-100" style={{ height: "65%" }}>
            <div className="column-center align-items-center h-100 bg-gray-550 rounded-top-8" style={{ width: "85%" }}>
              <div className="row-start align-items-center bg-gray-800 border-1 border-color-gray-700 rounded-pill py-2 px-3 mt-1" style={{ width: 240 }}>
                <i className="fa-light fa-circle-check txt-10 txt-gray-700 me-2" />
                <p className="txt-phrase txt-regular txt-8 txt-gray-700">{language.dashboard_steps_billing_problems}</p>
              </div>
              <div className="row-start align-items-center bg-gray-800 border-1 border-color-gray-700 rounded-pill py-2 px-3 mt-2" style={{ width: 240 }}>
                <i className="fa-light fa-circle-check txt-10 txt-gray-700 me-2" />
                <p className="txt-phrase txt-regular txt-8 txt-gray-700">{language.dashboard_steps_payment_methods}</p>
              </div>
            </div>
          </div>
        </div>
      ),
      title: language.onboarding_dashboard_title_step5,
      description: language.onboarding_dashboard_description_step5,
      placement: "auto",      
      target: "#navigate_support",
      floaterProps: {
        disableAnimation: true,
      },
      disableBeacon: true,
      steps: 11,
    },
    {
      content: (
        <div className="column-center align-items-center w-100 container-tour rounded-8 mt-3 py-3" style={{ height: 150 }}>
          <AnimatedComponent data={notifications} type={1} />
        </div>
      ),
      title: language.onboarding_dashboard_title_step6,
      description: language.onboarding_dashboard_description_step6,      
      placement: "auto",      
      target: "#navigate_notification",
      floaterProps: {
        disableAnimation: true,
      },
      disableBeacon: true,
      steps: 11,
    },
    {
      content: (
        <div className="column-center align-items-center w-100 container-tour rounded-8 mt-3 py-3 px-5" style={{ height: 150 }}>
          <WalletCard size={"small"} />
        </div>
      ),
      title: language.onboarding_dashboard_title_step7,
      description: language.onboarding_dashboard_description_step7,
      placement: "bottom",      
      target: "#navigate_neugets",
      floaterProps: {
        disableAnimation: true,
      },
      disableBeacon: true,
      steps: 11,
    },
  ])
}
