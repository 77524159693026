import * as creators from '../creators/Accounts';

const initialState = {
    totalAccounts: [],
    account: null,
    neugets: null,
    codeReferred: null
}

export const accountsReducer = (state = initialState, { type, payload }) => {

    switch (type) {
        case creators.SET_TOTAL_ACCOUNTS:
            return { ...state, totalAccounts: payload };
        case creators.SET_ACCOUNT:
            return { ...state, account: payload };
        case creators.SET_NEUGETS:
            return { ...state, neugets: payload };
        case creators.SET_CODE_REFERRED:
            return { ...state, codeReferred: payload };
        default:
            return state;
    }

}
