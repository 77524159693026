import * as creators from '../creators/Plus';
import _ from 'lodash';
import { addDays, startOfWeek } from 'date-fns';
import { setCalendarDates } from '../../services/CalendarHandler';

const initialState = {
    dataGraph: [],
    dataGraphPage: [],
    loadingInitData: false,
    enableHistory: true,
    devices: [],
    energyQuality: null,
    loadingEnergyQuality: false,
    alwaysOn: [],
    loadingAlwaysOn: false,
    indicators: [],
    loadingIndicators: false,
    comparatives: [],
    loadingComparatives: false,
    appliances: [],
    tagRecords: [],
    lastConection: null,
    lastPage: 1,
    lastDay: 0,
    openQualityEnergy: false,
    dataGraphQuality: [],   
    dataGraphAlwaysOn: [], 
    intervalGraphAlwaysOn: 1,
    graphDateFromAlwaysOn: addDays(startOfWeek(new Date()), 1),
    graphDateToAlwaysOn: addDays(startOfWeek(new Date()), 7),
    graphDateFromScrollAlwaysOn: addDays(startOfWeek(new Date()), 1),
    graphDateToScrollAlwaysOn: addDays(startOfWeek(new Date()), 7),
    enableScrollDataAlwaysOn: true,
    loadingAlwaysOnModule: true,
    qualityIndicators: null,
    loadingQualityIndicators: true,
    alwaysOnIndicators: [],
    loadingAlwaysOnIndicators: true,
    rankGraphIdAlwaysOn: 'Rank_Weekly'
};

export const plusReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case creators.SET_DATA_GRAPH:

            let arrayPages = state.dataGraphPage;

            const lastAvailablePage = _.last(state.dataGraphPage.filter(item => !item?.init));

            let idPageRecord = arrayPages.length ? payload?.type === 0 ? arrayPages[0]?.idPageRecord + 1 : lastAvailablePage?.idPageRecord - 1 : 0;

            payload["idPageRecord"] = idPageRecord;
            arrayPages.push(payload);
            arrayPages = arrayPages.filter(item => (item?.idPageRecord <= idPageRecord + 2 && item?.idPageRecord >= idPageRecord - 3) || item?.init);
            arrayPages.sort((a, b) => (a?.idPageRecord < b?.idPageRecord) ? 1 : ((b?.idPageRecord < a?.idPageRecord) ? -1 : 0));

            let dataGraph = arrayPages.map(value => value?.data).flat();

            return { ...state, dataGraph: dataGraph, dataGraphPage: arrayPages };
            
        case creators.SET_DATA_GRAPH_STREAMING:

            let arrayPagesStreaming = state.dataGraphPage;

            let pageInit = _.last(state.dataGraphPage);

            let pageInitModified = { ...pageInit, data: pageInit?.data.concat(payload) };

            arrayPagesStreaming[arrayPagesStreaming.length - 1] = pageInitModified;

            let dataGraphStreaming = arrayPagesStreaming.map(value => value?.data).flat();

            return { ...state, dataGraph: dataGraphStreaming, dataGraphPage: arrayPagesStreaming };

        case creators.SET_LOADING_INIT_DATA:
            return { ...state, loadingInitData: payload };
        case creators.SET_ENABLE_HISTORY:
            return { ...state, enableHistory: payload };
        case creators.SET_DEVICES:
            return { ...state, devices: payload };
        case creators.RESET_DATA:
            return { ...state, dataGraph: [], dataGraphPage: [] };
        case creators.SET_ENERGY_QUALITY:
            return { ...state, energyQuality: payload };
        case creators.SET_LOADING_ENERGY_QUALITY:
            return { ...state, loadingEnergyQuality: payload };
        case creators.SET_ALWAYS_ON:
            return { ...state, alwaysOn: payload };
        case creators.SET_LOADING_ALWAYS_ON:
            return { ...state, loadingAlwaysOn: payload };
        case creators.SET_PLUS_INDICATORS:
            return { ...state, indicators: payload };
        case creators.SET_PLUS_LOADING_INDICATORS:
            return { ...state, loadingIndicators: payload };
        case creators.SET_COMPARATIVES:
            return { ...state, comparatives: payload };
        case creators.SET_LOADING_COMPARATIVES:
            return { ...state, loadingComparatives: payload };
        case creators.SET_APPLIANCES:
            return { ...state, appliances: payload };
        case creators.SET_TAG_RECORDS:
            return { ...state, tagRecords: payload };
        case creators.SET_LAST_CONECTION:
            return { ...state, lastConection: payload };
        case creators.SET_LAST_DAY:
            return { ...state, lastDay: payload };
        case creators.SET_LAST_PAGE:
            return { ...state, lastPage: payload };
        case creators.SET_DATA_GRAPH_HISTORY_INIT:
            let newData = state.dataGraphPage.map(item =>{
                return {...item, data: payload?.data?.concat(item?.data)};
            });
            let dataGraphHistoryInit = newData.map(value => value?.data).flat();
            return { ...state, dataGraph: dataGraphHistoryInit, dataGraphPage: newData };
        case creators.SET_OPEN_QUALITY_ENERGY:
            return { ...state, openQualityEnergy: payload };     
        case creators.SET_DATA_GRAPH_ALWAYS_ON:
            return { ...state, dataGraphAlwaysOn: payload };      
        case creators.SET_INTERVAL_GRAPH_ALWAYS_ON:

            let date = new Date();
            let eventProperty = '';

            if (state.graphDateToAlwaysOn < date)
                date = state.graphDateToAlwaysOn;

            const dates = setCalendarDates(date, payload);

            switch (payload) {                
                case 1:
                    eventProperty = 'Rank_Weekly'
                    break
                case 2:
                    eventProperty = 'Rank_Monthly'
                    break
                case 3:
                    eventProperty = 'Rank_Yearly'
                    break
                default:
                    eventProperty = 'Rank_Weekly'
                    break
            }

            return { ...state, 
                intervalGraphAlwaysOn: payload,
                graphDateFromAlwaysOn: dates.graphDateFrom,
                graphDateToAlwaysOn: dates.graphDateTo,
                graphDateFromScrollAlwaysOn: dates.graphDateFrom,
                graphDateToScrollAlwaysOn: dates.graphDateTo,
                rankGraphIdAlwaysOn: eventProperty
            };  
        case creators.SET_GRAPH_DATES_ALWAYS_ON:
            return {
                ...state,
                graphDateFromAlwaysOn: payload.graphDateFrom,
                graphDateToAlwaysOn: payload.graphDateTo,
                graphDateFromScrollAlwaysOn: payload.graphDateFrom,
                graphDateToScrollAlwaysOn: payload.graphDateTo
            };
        case creators.SET_GRAPH_DATES_SCROLL_ALWAYS_ON:
            return {
                ...state,
                graphDateFromScrollAlwaysOn: payload.graphDateFromAlwaysOn,
                graphDateToScrollAlwaysOn: payload.graphDateToAlwaysOn
            };
        case creators.SET_ENABLE_SCROLL_DATA_ALWAYS_ON:
            return { ...state, enableScrollDataAlwaysOn: payload };
        case creators.SET_LOADING_ALWAYS_ON_MODULE:
            return { ...state, loadingAlwaysOnModule: payload }; 
        case creators.SET_QUALITY_INDICATORS:
            return { ...state, qualityIndicators: payload }; 
        case creators.SET_LOADING_QUALITY_INDICATORS:
            return { ...state, loadingQualityIndicators: payload }; 
        case creators.SET_ALWAYS_ON_INDICATORS:
            return { ...state, alwaysOnIndicators: payload }; 
        case creators.SET_LOADING_ALWAYS_ON_INDICATORS:
            return { ...state, loadingAlwaysOnIndicators: payload }; 
        default:
            return state;
    }
}
