import React, { useRef, useEffect } from 'react'
import { connect } from 'react-redux';

import { useHistory } from "react-router-dom";

import Header from "../shared/Header";

const Preonboarding = (props) => {

    let history = useHistory();

    let user = props.user;
    let activeServicePreonboarding = props.activeServicePreonboarding;

    const framePreonboarding = useRef(null);

    useEffect(() => {

        const userId = user?.idUser

        if (userId !== null && activeServicePreonboarding !== null) {

            framePreonboarding.current.addEventListener('load', () => {

                let window = framePreonboarding.current.contentWindow;

                let token = localStorage.getItem('token');

                setTimeout(() => {
                    window.postMessage(JSON.stringify({
                        token: token,
                        user: { idUser: userId },
                        service: activeServicePreonboarding.idService
                    }), process.env.REACT_APP_PREONBOARDING_HOST)
                }, [100]);

            });
        } else {
            history.push('/home');
        }
        // eslint-disable-next-line
    }, [user.idUser, activeServicePreonboarding]);

    //iframe listener
    useEffect(() => {

        window.addEventListener('message', handleMessageFromIframe);

        return () => {
            window.removeEventListener('message', handleMessageFromIframe);
        };
        // eslint-disable-next-line
    }, []);

    const handleMessageFromIframe = (event) => {
        if (event.origin === process.env.REACT_APP_PREONBOARDING_HOST) {
            if (event.data.action === 'navigate') {
                history.push(event.data.path);
            }
        }
    };

    return (
        <div className="position-relative w-100 vh-100">
            <div className="position-absolute w-100 left-0 top-0 border-bottom-1 border-color-gray-800 zindex-2" style={{ height: 74 }}>
                <Header moduleName={props.language.menu_onboarding} backToButton={true} />
            </div>
            <iframe key={user?.idUser} ref={framePreonboarding} title="pre-onboarding-section" className="position-absolute w-100 h-100 left-0 top-0" style={{ paddingTop: 74 }} src={process.env.REACT_APP_PREONBOARDING_HOST}></iframe>
        </div>);

}

const mapStateToProps = (state) => ({
    user: state.users.user,
    language: state.shared.language,
    activeServicePreonboarding: state.services.activeServicePreonboarding
});

export default connect(mapStateToProps)(Preonboarding)
