import * as services from "../creators/Accounts";

import { GETData } from "../../services/WebServices";

import { identify, Identify } from "@amplitude/analytics-browser"

export const setTotalAccounts = payload => {
  return {
    type: services.SET_TOTAL_ACCOUNTS,
    payload
  }
}

export const setAccount = payload => {
  return {
    type: services.SET_ACCOUNT,
    payload
  }
}

export const setNeugets = payload => {
  return {
    type: services.SET_NEUGETS,
    payload
  }
}

export const setCodeReferred = payload => {
  return {
    type: services.SET_CODE_REFERRED,
    payload
  }
}

export const loadAccountsAsync = () => {
  return (dispatch, getState) => {

    let accountSavedInLocalStorage = localStorage.getItem("account");

    if (accountSavedInLocalStorage !== null && accountSavedInLocalStorage !== undefined && accountSavedInLocalStorage !== 'undefined')
      accountSavedInLocalStorage = JSON.parse(accountSavedInLocalStorage);

    const userId = getState().users.user.idUser
    const usersNEU = [730, 1227, 848, 542, 2128]

    GETData(`users/accounts/?user=${userId}`, "GET")
      .then((response) => {
        if (response !== null) {
          const identifyObj = new Identify()
          identifyObj
            .set("accounts", response?.length && response.map(function (account) { return account?.name }));
          identify(identifyObj);
          response = response.map((item) => {
            if (item?.idAccount === 392 && usersNEU.includes(userId)) {
              return { ...item, name: "DEMO ENERGY" }
            } else {
              return item
            }
          })

          const checkIfLocalStorageHaveAValidAccount = response.some((accounts) => {
            return accounts.idAccount === accountSavedInLocalStorage?.idAccount
          })

          dispatch(setTotalAccounts(response));

          if (accountSavedInLocalStorage && checkIfLocalStorageHaveAValidAccount) {
            dispatch(setAccount(accountSavedInLocalStorage))
          } else {
            dispatch(setAccount(response[0]))
            localStorage.setItem("account", JSON.stringify(response[0]))
          }
        }
      })
      .catch((response) => console.error(response))
  }
}

export const loadNeugetsByAccountAsync = () => {
  return (dispatch, getState) => {

    const accountId = getState().accounts.account?.idAccount;

    GETData(`users/neugets/total/${accountId}`, "GET")
      .then(response => {
        if (response !== null)
          dispatch(setNeugets(response.totalNeugets));
        else
          dispatch(setNeugets(0));
      })
      .catch(response => console.error(response));
  }
}

export const getCodeReferredByAccountAsync = () => {
  return (dispatch, getState) => {

    const accountId = getState().accounts.account?.idAccount;

    GETData(`users/accounts/refcode/${accountId}`, 'GET')
      .then((response) => {
        if (response !== null) { dispatch(setCodeReferred(response)) }
      })
      .catch(response => console.error(response));
  }
}

