import React from 'react';
import step1 from '../../../imgs/onboarding/step1-service-picker.png';
import step1En from '../../../imgs/onboarding/english/step1-service-picker-en.png';
import step2 from '../../../imgs/onboarding/step2-service-picker.png';
import step2En from '../../../imgs/onboarding/english/step2-service-picker-en.png';
import step3 from '../../../imgs/onboarding/step3-service-picker.png';
import step3En from '../../../imgs/onboarding/english/step3-service-picker-en.png';

export const stepsServicePicker = (language) => {
    return ([
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 168, width: 300 }}>
                    <img src={language.language_locale === 'es'? step1 : step1En} className='w-100 h-100' alt="step1-service-picker" />
                </div>,
            title: language.onboarding_service_picker_title_step1,
            description: language.onboarding_service_picker_description_step1,
            placement: "left-start",            
            target: "#service_picker",
            disableBeacon: true,
            steps: 3,
            disableOverlayClose: true,
            spotlightClicks: true,
            hideFooter: true,
            hideFooterTitle: '¡Da click para descubrir cómo!',
            pulseEffect: true
        },
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 152, width: 300 }}>
                    <img src={language.language_locale === 'es'? step2 : step2En} className='w-100 h-100' alt="step2-service-picker" />
                </div>,
            title: language.onboarding_service_picker_title_step2,
            description: language.onboarding_service_picker_description_step2,
            placement: "left",
            target: "#type_services",
            disableBeacon: true,
            steps: 3,
            hideBackButton: true
        },
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 122, width: 300 }}>
                    <img src={language.language_locale === 'es'? step3 : step3En} className='w-100 h-100' alt="step3-service-picker" />
                </div>,
            title: language.onboarding_service_picker_title_step3,
            description: language.onboarding_service_picker_description_step3,
            placement: "left",
            target: "#services_list",
            disableBeacon: true,
            steps: 3
        },

    ])
};