import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';

import * as invoicesActions from '../../../store/actions/Invoices';
import * as paysActions from '../../../store/actions/Pays';

import { Alert, Animation } from 'rsuite';

import Input from '../../shared/utilities/Input';
import Select from '../../shared/utilities/Select';

import TotalPayment from './TotalPayment';

const isValidateEmail = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

const isValidateString = val => {
    return /^[ñíóáéú a-zA-Z ]+$/.test(val);
}

const isValidUser = (user, pseProvider) => {

    if (user.name === null || user.name === "" || !isValidateString(user.name)) {
        return false;
    }

    if (!isValidateEmail(user.email)) {
        return false;
    }

    if (user.phone === null || user.phone === "") {
        return false;
    }

    if (user.address === null || user.address === "") {
        return false;
    }

    if (user.id_document === null || user.id_document === "") {
        return false;
    }

    if ((user.financial_institution_code === null || user.financial_institution_code === "0") && pseProvider === 'payvalida') {
        return false;
    }

    return true;

}

const showError = (user, props) => {

    if (user.name === null || user.name === "" || !isValidateString(user.name)) {
        Alert.error(props.language.alert_user_popup_name);
        return;
    }

    if (user.address === null || user.address === "") {
        Alert.error(props.language.alert_user_popup_address);
        return;
    }

    if (!isValidateEmail(user.email)) {
        Alert.error(props.language.alert_user_popup_email);
        return;
    }

    if (user.phone === null || user.phone === "") {
        Alert.error(props.language.alert_user_popup_phone);
        return;
    }

    if (user.id_document === null || user.id_document === "") {
        Alert.error(props.language.alert_user_popup_id_document);
        return;
    }

    if (user.financial_institution_code === null || user.financial_institution_code === "0") {
        Alert.error(props.language.alert_pay_popup_banks);
        return;
    }

}

const PsePayment = (props) => {

    const userLegalTypes = [
        {
            label: props.language.invoices_pay_label_option_cc,
            value: "CC"
        },
        {
            label: props.language.invoices_pay_label_option_nit,
            value: "NIT",
        },
        {
            label: props.language.invoices_pay_label_option_ce,
            value: "CE"
        }
    ]
    
    const userTypes = [
        {
            label: props.language.invoices_pay_label_option_natural_user,
            value: "N"
        },
        {
            label: props.language.invoices_pay_label_option_legal_user,
            value: "J",
        }
    ]

    let account = props.account;
    let banks = props.banks;
    let infoPayment = props.infoPayment;
    let invoicesToPay = props.invoicesToPay;
    let pseProvider = props.paymentProvider;

    const loadBankDataAsync = props.loadBankDataAsync;
    const setInfoPayment = props.setInfoPayment;
    const setPsePaymentAsync = props.setPsePaymentAsync;

    const initInfo = {
        id_user: props.user.idUser,
        id_external_user: 0,
        id_account: account?.idAccount,
        name: pseProvider === 'payvalida' ? `${props.user.name} ${props.user.lastname}` : props.user.name,
        last_name: props.user.lastname,
        email: props.user.email,
        phone: props.user.phone,
        address: props.user.address,
        id_document: "",
        financial_institution_code: "0",
        user_legal_id_type: userLegalTypes[0].value,
        user_type: userTypes[0].value,
        id_bills: [],
        neugets: 0,
        commission: 0,
        total_payment: invoicesToPay.reduce((a, { total_payment }) => a + parseFloat(total_payment), 0),
    }

    const [isEnable, setEnable] = useState(false);
    const [defaultInfo, setDefaultInfo] = useState(initInfo);

    useEffect(() => {
        if (defaultInfo != null) {
            setInfoPayment(defaultInfo)
            setDefaultInfo(null)
        }
    }, [defaultInfo, setInfoPayment]);

    const [personalData, setShowPersonalData] = useState(pseProvider !== 'payvalida');
    const [bankData, setShowBankData] = useState(pseProvider === 'payvalida');

    useEffect(() => setEnable(isValidUser(infoPayment)), [setEnable, infoPayment]);

    useEffect(() => {
        !banks.length && pseProvider === 'payvalida' && loadBankDataAsync();
    }, [banks, loadBankDataAsync, pseProvider]);

    return (
        <div className='column-between align-items-start mt-4 h-100 w-100'>
            <div className="column-start align-items-center w-100">
                <div className="row-between align-items-center w-100 pe-pointer my-4" onClick={() => {setShowPersonalData(!personalData); setShowBankData(!bankData)}}>
                    <div className="txt-phrase txt-semibold txt-white txt-8 pe-pointer">{props.language.invoices_pay_title_section_personal_data}</div>
                    <i className={`fa-regular fa-chevron-right txt-white txt-10 pe-pointer ${!personalData ? 'account-detail-open' : 'account-detail-close'}`}></i>
                </div>
                <Animation.Collapse in={personalData} dimension={'height'}>
                    <div className='column-start align-items-center w-100'>
                        <div className="col-12">
                            <Input
                                label={props.language.invoices_pay_input_label_name}
                                type={'text'}
                                value={infoPayment.name ?? ""}
                                onChange={({ target }) => {
                                    setInfoPayment({ ...infoPayment, name: target.value })
                                }}
                            />
                        </div>
                        <div className="col-12 my-2">
                            <Select
                                label={props.language.invoices_pay_select_label_user_legal_type}
                                background={'gray-500'}
                                value={infoPayment.user_legal_id_type}
                                options={userLegalTypes}
                                onChange={({ target }) => {
                                    setInfoPayment({ ...infoPayment, user_legal_id_type: target.value })
                                }}
                            />
                        </div>
                        <div className="col-12">
                            <Input
                                type={'number'}
                                label={props.language.invoices_pay_input_label_idDocument}
                                value={infoPayment.id_document ?? ""}
                                onChange={({ target }) => {
                                    setInfoPayment({ ...infoPayment, id_document: target.value })
                                }}
                            />
                        </div>
                        <div className="row-between align-items-center flex-wrap my-2">
                            <div className="col-6 pe-1 text-nowrap">
                                <Input
                                    label={props.language.invoices_pay_input_label_phone}
                                    type={'number'}
                                    value={infoPayment.phone ?? ""}
                                    onChange={({ target }) => {
                                        setInfoPayment({ ...infoPayment, phone: target.value })
                                    }}
                                />
                            </div>
                            <div className="col-6 ps-1 text-nowrap">
                                <Input
                                    label={props.language.invoices_pay_input_label_address}
                                    type={'text'}
                                    value={infoPayment.address ?? ""}
                                    onChange={({ target }) => {
                                        setInfoPayment({ ...infoPayment, address: target.value })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <Input
                                label={props.language.invoices_pay_input_label_email}
                                type={'email'}
                                value={infoPayment.email ?? ""}
                                onChange={({ target }) => {
                                    setInfoPayment({ ...infoPayment, email: target.value })
                                }}
                            />
                        </div>
                    </div>
                </Animation.Collapse>
                {
                    pseProvider === 'payvalida' && <Fragment>
                        <div className="row-between align-items-center w-100 pe-pointer my-4" onClick={() => { setShowPersonalData(!personalData); setShowBankData(!bankData) }}>
                            <div className="txt-phrase txt-semibold txt-white txt-8 pe-pointer">{props.language.invoices_pay_title_section_bank_data}</div>
                            <i className={`fa-regular fa-chevron-right txt-white txt-10 pe-pointer ${!bankData ? 'account-detail-open' : 'account-detail-close'}`}></i>
                        </div>
                        <Animation.Collapse in={bankData} dimension={'height'}>
                            <div className='column-start align-items-center w-100 mb-4'>
                                <div className="col-12">
                                    <Select
                                        label={props.language.invoices_pay_select_label_user_type}
                                        background={'gray-500'}
                                        value={infoPayment.user_type}
                                        options={userTypes}
                                        onChange={({ target }) => {
                                            const type = target.value === userTypes[1].value ? userLegalTypes[1].value : userLegalTypes[0].value;
                                            setInfoPayment({ ...infoPayment, user_type: target.value, user_legal_id_type: type })
                                        }}
                                    />
                                </div>
                                <div className="col-12 mt-2">
                                    {props.banks.length ?
                                        <Select
                                            label={props.language.invoices_pay_select_label_banks}
                                            keyName={'BANK_CODE'}
                                            labelName={'BANK_NAME'}
                                            background={'gray-500'}
                                            value={infoPayment.financial_institution_code}
                                            options={props.banks}
                                            onChange={({ target }) => {
                                                setInfoPayment({ ...infoPayment, financial_institution_code: target.value });
                                            }}
                                        /> : null
                                    }
                                </div>
                            </div>
                        </Animation.Collapse>
                    </Fragment>
                }
                
            </div>
            <TotalPayment isEnable={isEnable} payAction={() => isEnable ? setPsePaymentAsync(pseProvider) : showError(infoPayment, props)}/>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.users.user,
    account: state.accounts.account,
    invoicesToPay: state.invoices.invoicesToPay,
    banks: state.invoices.banks,
    language: state.shared.language,
    infoPayment: state.pays.infoPayment,
    paymentProvider: state.pays.paymentProvider
})

const mapDispatchToProps = dispatch => ({
    loadBankDataAsync: () => dispatch(invoicesActions.loadBankDataAsync()),
    setInfoPayment: (payload) => dispatch(paysActions.setInfoPayment(payload)),
    setPsePaymentAsync: (payload) => dispatch(paysActions.setPsePaymentAsync(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(PsePayment)