import React from "react"

import step1 from "../../../imgs/onboarding/step-walktrough.png"
import step1En from "../../../imgs/onboarding/english/step-walktrough-en.png"

export const stepsWalktrough = (language) => {
  return ([
    {
      content: (
        <div className="row-center align-items-center w-100 container-tour rounded-8 mt-3" style={{ height: 162, width: 300 }}>
          <img src={language.language_locale === 'es'? step1 : step1En} className="w-100 h-100" alt="step 1 new user" />
        </div>
      ),
      title: language.onboarding_walktrough_title,
      description: language.onboarding_walktrough_description,
      placement: "center",     
      target: "#tooltip_walktrough",
      disableBeacon: true,
      steps: 3,
      walktrough: true
    }    
  ])
}