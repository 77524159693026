import * as creators from '../creators/Genius';

const initialState = {
    totalReports: [],
    graphs: [],
    activeReports: [],
    loadingReport: true,
    loadingNewReport: false,
    reportGenerated: false,
    newReport: false,
    expanded: false,
    serviceType: null,
    graphsType: null,
    totalGraphs: []
};

export const geniusReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case creators.SET_REPORTS_GENIUS:
            return { ...state, totalReports: payload };
        case creators.SET_GRAPHS_REPORT_GENIUS:
            return { ...state, graphs: payload };
        case creators.SET_ACTIVE_REPORTS:
            return { ...state, activeReports: payload };
        case creators.SET_REPORT_LOADING:
            return { ...state, loadingReport: payload };
        case creators.SET_NEW_REPORT_LOADING:
            return { ...state, loadingNewReport: payload };
        case creators.SET_REPORT_GENERATED:
            return { ...state, reportGenerated: payload };
        case creators.SET_NEW_REPORT:
            return { ...state, newReport: payload };
        case creators.SET_SHOW_CONTENT_HEADER:
            return { ...state, expanded: payload };
        case creators.SET_SERVICE_TYPE:
            return { ...state, serviceType: payload };
        case creators.SET_GRAPHS_TYPE:
            return { ...state, graphsType: payload };
        case creators.SET_TOTAL_GRAPHS_REPORT_GENIUS:
            return { ...state, totalGraphs: payload };
        default:
            return state;
    }
}