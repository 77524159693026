import React from 'react'

const BackButton = ({ onClick }) => {

    return (
        <button className='row-center align-items-center rounded-pill bg-gray-500 border border-color-gray-800' style={{ width: 32, height: 32 }} onClick={onClick}>
            <i className='fa-light fa-chevron-left txt-8 txt-white pr-pointer'></i>
        </button>
    )
}

export default BackButton