import React, { Fragment, useEffect, useState } from "react"
import { connect } from "react-redux"

import * as servicesActions from "../../store/actions/Services"
import * as sharedActions from "../../store/actions/Shared"
import * as energyActions from "../../store/actions/Energy"
import * as usersActions from "../../store/actions/Users"

import { getAnalytics, logEvent } from "firebase/analytics"

import ContentLoader from "react-content-loader"

import { Transition } from "react-transition-group"

import { Loader } from "rsuite"

import Cards from "./cards/Cards"
import Graph from "./graphs/Graph"
import Invoice from "./invoice/Invoice"
import Header from "../shared/Header"
import ServicesContainer from "./services/ServicesContainer"
// import PreferenceInfo from "../shared/users/PreferenceInfo"

import useTour from '../onboarding/hook/useTour';
import { stepsService } from '../onboarding/steps/ServiceSteps';
import { stepsServicePicker } from "../onboarding/steps/ServicePickerSteps"

const analytics = getAnalytics()

const graphLoadingContainer = (loading, language) => (
  <div className="position-absolute h-100 w-100 left-0 top-0 zindex-1 p-4">
    <Transition in={loading} timeout={100}>
      {(state) => (
        <div className={`trans-fade trans-fade-${state} position-absolute w-100 h-100 top-0 left-0 zindex-1`}>
          <div className="column-center align-items-center w-100 h-100">
            <Loader inverse center size="md" content={language.loader_text_content} />
          </div>
        </div>
      )}
    </Transition>
  </div>
)

const indicatorsLoading = (
  <div className="position-absolute w-100 h-100 left-0 top-0 zindex-1">
    <ContentLoader seed={2} backgroundColor="#161617" foregroundColor="#323234" style={{ width: "100%", height: "100%" }}>
      <rect x="0" y="0" width="calc(100%)" height="120" rx="16" ry="16"></rect>
    </ContentLoader>
  </div>
)

const CardsContent = ({ activeServices }) => {

  let mainCard;
  let widthCard = 0;

  if (activeServices.some(s => s.type.name === "energy")) {

    mainCard = <Invoice />
    widthCard = 350

  }

  return (
    <Fragment>
      {mainCard}
      <div style={{ width: `calc(100% - ${widthCard}px)` }}>
        <Cards />
      </div>
    </Fragment>
  );

}

const Energy = (props) => {

  let user = props.user
  let account = props.account
  let graphs = props.graphs
  let activeGraphs = props.activeGraphs
  let intervalGraph = props.intervalGraph
  let activeServicesEnergy = props.activeServicesEnergy
  let setShowDetailService = props.setShowDetailService
  let setCurrentModule = props.setCurrentModule
  let screenPaddingBottom = props.screenPaddingBottom;

  const loadGraphAsync = props.loadGraphAsync
  const setShowBlackScreen = props.setShowBlackScreen
  const loadIndicatorsAsync = props.loadIndicatorsAsync
  const loadGraphOptionsAsync = props.loadGraphOptionsAsync
  const getUserPreferenceInfoAsync = props.getUserPreferenceInfoAsync
  const loadServicesAsync = props.loadServicesAsync
  const loadBannersAsync = props.loadBannersAsync
  const setStartOnboarding = props.setStartOnboarding

  const [historyData, setHistoryData] = useState(props.history.location.state?.data)

      
  useEffect(() => {
    if (historyData) {
      setHistoryData(null)
    } else {
      if (!activeServicesEnergy?.filter((item) => item?.idAccount === account?.idAccount).length) {
        account && loadServicesAsync()
      }
      setShowDetailService(false)
    }
    // eslint-disable-next-line
  }, [account, loadServicesAsync])

  useEffect(() => {
    if (activeServicesEnergy.length && graphs.length) {
      loadGraphAsync()
    }
  }, [loadGraphAsync, graphs, activeServicesEnergy, intervalGraph, activeGraphs])

  useEffect(() => {
    if (activeGraphs.length){
      setShowBlackScreen(false)
    }
  }, [setShowBlackScreen, activeGraphs])

  useEffect(() => {
    if (activeServicesEnergy.length) {
      loadIndicatorsAsync()
      loadGraphOptionsAsync()
      loadBannersAsync()
    }
  }, [loadIndicatorsAsync, loadGraphOptionsAsync, activeServicesEnergy, loadBannersAsync])

  useEffect(() => {
    logEvent(analytics, "dashboard", {
      id_user: user.idUser,
      user_name: `${user.name} ${user.lastname}`,
    })

    setCurrentModule(4)

  }, [user, setCurrentModule])

  useEffect(() => {
    getUserPreferenceInfoAsync()
  }, [getUserPreferenceInfoAsync, account, intervalGraph])   

  const handleFirstTourFinish = () => {     
    setStartOnboarding({key: 'service_picker', value: true})   
  };

  const tourServiceDetail = useTour({ steps: stepsService(props.language), localStorageKey: "service-tutorial", isOpenDrawer: null, onTourFinish: handleFirstTourFinish, runTour: props.serviceDetail, setRunTour: setStartOnboarding, key: 'service_detail' });
  const tourServicePicker = useTour({ steps: stepsServicePicker(props.language), localStorageKey: "service-picker-tutorial", runTour: props.servicePicker, setRunTour: setStartOnboarding, key: 'service_picker', controlledOnboarding: props.controlledServicePicker, setShowPulseAnimation: props.setShowPulseAnimation  });



  return (
    <section className="column-start align-items-center min-vh-100 w-100" style={{ paddingBottom: screenPaddingBottom }}>
      {tourServiceDetail}
      {tourServicePicker}
      <Header moduleName={props.language.menu_energy_service} backToButton={true} />      
      <div className="column-center position-relative align-items-center flex-grow-1 w-100 pb-3 gap-3">
        <ServicesContainer />
        <div className="row-between align-items-center w-100 px-3" style={{ height: 120 }}>
          {props.indicatorsLoading ?
            <div className="position-relative h-100 w-100">{indicatorsLoading}</div> :
            <CardsContent activeServices={activeServicesEnergy} />
          }
        </div>
        <div className="position-relative w-100 px-3">
          <div className="position-relative w-100 bg-gray-500 rounded-16 border-1 border-color-gray-800">{!props.graphLoading ? <Graph /> : <div style={{ height: 670 }}>{graphLoadingContainer(props.graphLoading, props.language)}</div>}</div>
        </div>
      </div>
      {/* {!props.userPreferenceInfo && !props.graphLoading && !props.indicatorsLoading ? <PreferenceInfo /> : null} */}
    </section>
  )
}

const mapStateToProps = (state) => ({
  activeServicesEnergy: state.services.activeServicesEnergy,
  graphData: state.energy.graphData,
  intervalGraph: state.energy.intervalGraph,
  graphLoading: state.energy.graphLoading,
  indicatorsLoading: state.energy.indicatorsLoading,
  activeGraphs: state.energy.activeGraphs,
  graphs: state.energy.graphs,
  language: state.shared.language,
  user: state.users.user,
  userPreferenceInfo: state.users.userPreferenceInfo,
  account: state.accounts.account,
  showDetailService: state.energy.showDetailService,
  showBlackScreen: state.shared.showBlackScreen,
  showMenu: state.shared.showMenu,
  serviceDetail: state.shared.startOnboarding.service_detail,
  servicePicker: state.shared.startOnboarding.service_picker,
  controlledServicePicker: state.shared.controlledOnboarding.service_picker,
  screenPaddingBottom: state.shared.screenPaddingBottom
})

const mapDispatchToProps = (dispatch) => ({
  setActiveServicesEnergy: (payload) => dispatch(servicesActions.setActiveServicesEnergy(payload)),
  setActiveServicesPlus: (payload) => dispatch(servicesActions.setActiveServicesPlus(payload)),
  loadGraphAsync: () => dispatch(energyActions.loadGraphAsync()),
  loadIndicatorsAsync: () => dispatch(energyActions.loadIndicatorsAsync()),
  setShowBlackScreen: (payload) => dispatch(sharedActions.setShowBlackScreen(payload)),
  setCurrentModule: (payload) => dispatch(sharedActions.setCurrentModule(payload)),
  loadGraphOptionsAsync: () => dispatch(energyActions.loadGraphOptionsAsync()),
  getUserPreferenceInfoAsync: () => dispatch(usersActions.getUserPreferenceInfoAsync()),
  loadServicesAsync: (payload) => dispatch(servicesActions.loadServicesAsync(payload)),
  loadBannersAsync: () => dispatch(energyActions.loadBannersAsync()),
  setShowDetailService: (payload) => dispatch(energyActions.setShowDetailService(payload)),
  setStartOnboarding: (payload) => dispatch(sharedActions.setStartOnboarding(payload)),
  setShowPulseAnimation: (payload) => dispatch(sharedActions.setShowPulseAnimation(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Energy)
