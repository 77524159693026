import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as accountsActions from '../../store/actions/Accounts';

import { Drawer, Whisper, Tooltip } from 'rsuite';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';

import filePDF from '../../files/TyC+PLAN+DE+REFERIDOS+.pdf';

import {
    LinkedinShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookShareButton
} from "react-share";

import WalletCard from './WalletCard';
import BackButton from './utilities/BackButton';

const Neugets = (props) => {

    let codeReferred = props.codeReferred;
    let account = props.account;
    const getCodeReferredByAccountAsync = props.getCodeReferredByAccountAsync;

    useEffect(() => {
        if (account !== null)
            getCodeReferredByAccountAsync();
    }, [account, getCodeReferredByAccountAsync]);

    const codeMessage = props.language.neugets_text_share_code_message !== undefined && codeReferred ?
        props.language.neugets_text_share_code_message.replace("#code#", codeReferred.codeReferred).split('\\n').join(' ') : null;

    return (
        <Drawer
            show={props.showNeugets}
            size={'xs'}
            placement={'right'}
            backdropClassName={'bg-black opacity-80 pe-pointer'}
            onHide={() => props.setShowNeugets(false)}>
            <div className="vh-100 w-100 overflow-auto bg-gray-500">
                <div className='column-between align-items-center p-3 h-100'>
                    <div className='column-start'>
                        <div className='row-start w-100 align-items-center mb-3'>
                            <BackButton onClick={() => props.setShowNeugets(false)}></BackButton>
                            <p className='flex-grow-1 txt-title txt-neutral-50 txt-bold txt-12 text-end'>{props.language.neugets_drawer_main_title}</p>
                        </div>
                        <WalletCard />
                        <p className='txt-bold txt-white txt-8 my-3 w-100 text-wrap text-left'>{props.language.neugets_drawer_text_neugets_description}</p>
                        <div className='column-start'>
                            <p className='txt-regular txt-white txt-8 w-100 text-wrap text-left'>{props.language.neugets_drawer_text_use_neugets}</p>
                            <p className='txt-regular txt-white txt-8 w-100 text-wrap text-left my-3'>{props.language.neugets_drawer_text_get_neugets}</p>
                        </div>
                    </div>
                    <div className='column-start'>
                        <p className='txt-bold txt-white txt-8 my-4 w-100 text-wrap text-center'>{props.language.neugets_drawer_text_share_referral_code}</p>
                        {codeReferred &&
                            <div className='row-start w-100 align-items-center rounded-8 bg-gray-800 p-3'>
                                <div className='txt-bold txt-title txt-12 txt-white text-center w-100'>{codeReferred.codeReferral}</div>
                                <Whisper
                                    delay={200}
                                    placement="bottom"
                                    trigger="click"
                                    speaker={<Tooltip>{props.language.neugets_tooltip_copy_code}</Tooltip>}>
                                    <i className="fa-regular fa-copy txt-14 txt-white pe-pointer" onClick={() => navigator.clipboard.writeText(codeReferred.codeReferral)}></i>
                                </Whisper>
                            </div>}
                        <div className='my-3 w-100 row-center'>
                            <a className='txt-semibold txt-green-500 txt-6 text-center text-decoration-underline pe-pointer' href={filePDF} download={'TyC+PLAN+DE+REFERIDOS+.pdf'}>{props.language.neugets_drawer_text_legal_terms}</a>
                        </div>
                        <div className='row-center align-items-center w-100 my-3' style={{ gap: 24 }}>
                            <WhatsappShareButton
                                title={codeMessage}
                                url={"www.neu.com.co"}>
                                <FontAwesomeIcon icon={faWhatsapp} color={'#737374'} style={{ width: 20, height: 20 }} />
                            </WhatsappShareButton>
                            <LinkedinShareButton
                                title={codeMessage}
                                url={"www.neu.com.co"}>
                                <FontAwesomeIcon icon={faLinkedin} color={'#737374'} style={{ width: 20, height: 20 }} />
                            </LinkedinShareButton>
                            <EmailShareButton
                                subject={props.language.neugets_text_share_code_title}
                                body={codeMessage}
                                url={"www.neu.com.co"}>
                                <FontAwesomeIcon icon={faEnvelope} color={'#737374'} style={{ width: 20, height: 20 }} />
                            </EmailShareButton>
                            <FacebookShareButton
                                url={"www.neu.com.co"}
                                quote={codeMessage}
                                hashtag={"#NEUEnergy"}>
                                <FontAwesomeIcon icon={faFacebook} color={'#737374'} style={{ width: 20, height: 20 }} />
                            </FacebookShareButton>
                        </div>
                    </div>
                </div>
            </div>
        </Drawer>
    );
}

const mapStateToProps = (state) => ({
    language: state.shared.language,
    codeReferred: state.accounts.codeReferred,
    account: state.accounts.account
});

const mapDispatchToProps = dispatch => ({
    getCodeReferredByAccountAsync: () => dispatch(accountsActions.getCodeReferredByAccountAsync())
})

export default connect(mapStateToProps, mapDispatchToProps)(Neugets);