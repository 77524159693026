import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";

import ercoComputer from '../../imgs/erco-computer.png';
import ercoGeneration from '../../imgs/erco-generation.png';
import ercoEfficiency from '../../imgs/erco-efficiency.png';
import ercoScreen from '../../imgs/erco-screen.png';

import handWithPhone from '../../imgs/hand-with-phone.png';
import consumptionInfo from '../../imgs/consumption-info.png';
import energyReceipt from '../../imgs/energy-receipt.png';
//import realTimeConsumption from '../../imgs/real-time-consumption.png';

import steps from '../../imgs/steps.png';
import leftChat from '../../imgs/left-chat.png';
import rightChat from '../../imgs/right-chat.png';

const getData = (section, showInvoices, language) => {

  const onboardingGeneration = [
    {
      title: language.onboarding_generation_title1, 
      text: language.onboarding_generation_text1, 
      imgPath: ercoComputer,
      classImageContainer: 'column-center align-items-end'
    }, 
    {
      title: language.onboarding_generation_title2, 
      text: language.onboarding_generation_text2, 
      imgPath: ercoGeneration,
      classImage: 'w-100',
      classImageContainer: 'column-center align-items-end'
    },
    {
      title: language.onboarding_generation_title3, 
      text: language.onboarding_generation_text3, 
      imgPath: ercoEfficiency,
      classImage: 'w-100',
      classImageContainer: 'column-center align-items-center'
    },
    {
      title: language.onboarding_generation_title4, 
      text: language.onboarding_generation_text4, 
      imgPath: ercoScreen,
      classImage: 'w-100',
      classImageContainer: 'column-center align-items-end'
    }
  ];

  const onboardingPreonboarding = [
    {
      title: language.onboarding_preonboarding_title1, 
      text: language.onboarding_preonboarding_text1, 
      imgPath: steps,
      classImageContainer: 'column-center align-items-center'
    }, 
    {
      title: language.onboarding_preonboarding_title2, 
      text: language.onboarding_preonboarding_text2, 
      imgPath: leftChat,
      classImageContainer: 'column-center align-items-end'
    },
    {
      title: language.onboarding_preonboarding_title3, 
      text: language.onboarding_preonboarding_text3, 
      imgPath: rightChat,
      classImageContainer: 'column-center align-items-start'
    }
  ];

  const nextStepsInfoConsumption = [
    {
      title: language.onboarding_consumption_title1, 
      text: language.onboarding_consumption_text1, 
      imgPath: handWithPhone,
      classImage: 'max-height-400',
      classImageContainer: 'column-center align-items-start'
    }, 
    {
      title: language.onboarding_consumption_title2, 
      text: language.onboarding_consumption_text2, 
      imgPath: consumptionInfo,
      classImage: 'w-100',
      classImageContainer: 'column-center align-items-center'
    },
    {
      title: language.onboarding_consumption_title3, 
      text: language.onboarding_consumption_text3, 
      imgPath: energyReceipt,
      classImage: 'w-100',
      classImageContainer: 'column-center align-items-end'
    },
    {
      title: language.onboarding_consumption_title4, 
      text: language.onboarding_consumption_text4,
      classImageContainer: 'column-center align-items-center'
    }
  ];

  switch (section) {
    case 'home':
      return showInvoices ? nextStepsInfoConsumption : onboardingGeneration;
    case 'pre-onboarding':
      return onboardingPreonboarding;
    default:
      return [];
  }

}

const onboardingSections = [
  'home',
  'preonboarding'
]

const OnboardingModal = (props) => {

  const location = useLocation();

  let showBetaMessage = props.showBetaMessage;
  let user = props.user;

  const [currentView, setCurrentView] = useState(0);
  const [isShowing, setIsShowing] = useState(true);
  const [data, setData] = useState([]);
  const [section, setSection] = useState(null);

  const handleContinueClick = () => {
    
    if (currentView < data.length - 1) {

      setCurrentView(currentView + 1);

    } else {

      setIsShowing(false);

      localStorage.setItem(`show_onboarding_${section}`, false);

    }

  };

  useEffect(() => {

    if (onboardingSections.includes(section)) {

      const showSection = localStorage.getItem(`show_onboarding_${section}`);

      if ((showSection === null || showSection === 'true') && user.capabilities?.show_onboarding_modals) {
  
        setData(getData(section, user.capabilities?.show_section_invoices, props.language));
        setIsShowing(true);
  
      };

    }
  // eslint-disable-next-line
  }, [section, user.capabilities]);

  useEffect(() => setSection(location.pathname.slice(1)), [location.pathname]);

  if (!isShowing || !data.length) {
    return null;
  }

  const isLastObject = currentView === data.length - 1;

  return (
    <div className="position-absolute column-center align-items-center w-100 h-100 bg-black-80 zindex-5">
      <div className="position-relative onboarding-modal-container column-center align-items-center overflow-hidden bg-gray-500-40 border border-gray-200-10 rounded-16">
        <div className={`onboarding-modal-content column-start align-items-center gap-${showBetaMessage ? '4' : '5'} pt-4`} style={{ paddingBottom: 125 }}>
          <div className="column-center align-items-center gap-2 px-3">
            <p className="txt-title txt-14 txt-white text-center txt-bold text-wrap">
              {data[currentView].title}
            </p>
            <p className="txt-phrase txt-8 txt-gray-300 text-center txt-regular text-wrap">
              {data[currentView].text}
            </p>
            <div className="row-center gap-2 align-items-center">
              {
                data.map((_, index) => (
                  <div key={index} className={`onboarding-modal-dot rounded-50 bg-${currentView === index ? "white" : "gray-600"}`}/>
                ))
              }
            </div>
          </div>
          <div className={`w-100 ${data[currentView].classImageContainer ? data[currentView].classImageContainer : ""}`}>
            <img className={`${data[currentView].classImage ? data[currentView].classImage : ""}`} src={data[currentView].imgPath} alt={data[currentView].imgPath} />
          </div>
          {
            showBetaMessage && (
              <div className="bg-gray-800 border border-green-500-40 rounded-10 p-2 mx-4">
                <p className="txt-phrase txt-8 txt-gray-300 text-center txt-regular text-wrap">
                  {props.language.onboarding_modal_description}
                </p>
              </div>
          )}
        </div>
        <div className="position-absolute bottom-0 row-center align-items-center w-100 bg-gray-transparent-gradient-vertical" style={{ height: 120 }}>
          <button
            className="row-center align-items-center gap-2 bg-gray-600-70 border border-gray-200-10 py-2 px-3 rounded-50"
            onClick={handleContinueClick}>
              <div className="txt-phrase txt-10 txt-white text-center txt-regular text-wrap pe-pointer">
                { isLastObject ? props.language.onboarding_modal_finally : props.language.onboarding_modal_continue }
              </div>
              {
                !isLastObject && (
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="txt-10 txt-white"
                  />
                )
              }
          </button>
        </div>   
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  showBetaMessage: ownProps.showBetaMessage,
  language: state.shared.language,
  user: state.users.user
});

export default connect(mapStateToProps)(OnboardingModal);
