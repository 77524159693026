import React, { useState, useEffect } from "react";

import { Popover } from "@idui/react-popover";

const MultipleSelect = ({ options, selectedData, label, keyName = 'value', labelName = 'label', onChange, width = 280, height = 120, language, disabledData, newData, allowAllOptions = false, labelAllServices, error, background = 'bg-gray-500' }) => {

  const [active, setActive] = useState(false);
  const [selectedAllOptions, setSelectedAllOptions] = useState(false);

  let totalOptions = options.map((item) => { return item[keyName] });

  useEffect(() => {
    setSelectedAllOptions(totalOptions.length === selectedData.length);
  }, [selectedData, totalOptions]);

  return (
    <Popover
      lazy
      closeOnEnter
      fitMaxHeightToBounds
      fitMaxWidthToBounds
      trigger={'click'}
      placement={'bottom'}
      arrowColor={'#161617'}
      className={`p-0 bg-gray-500`}
      zIndex={10000}
      arrowSize={20}
      offset={[0, 0]}
      openingAnimationTranslateDistance={0}
      animation={{
        animate: { opacity: 1 },
        exit: { opacity: 0, transition: { duration: 0.1 } },
        initial: { opacity: 0 }
      }}
      withArrow={false}
      content={
        <div className="w-100 px-1">
          {options.length ?
            <div className={`column-start align-items-center border-2 border-color-gray-200 rounded-8 overflow-auto px-2 py-1`} style={{ width: width, maxHeight: height }}>
              {allowAllOptions && options.length > 1 ?
                <div className="row-between align-items-center bg-gray-800 pe-pointer p-2 rounded-8 my-2" onClick={() => { if (!selectedAllOptions) { onChange(totalOptions) } else { onChange([]) } }}>
                  <div className={`txt-phrase txt-6 txt-${!selectedAllOptions ? 'white' : 'green-500'} pe-pointer ms-2`}>{labelAllServices}</div>
                  <i className={`fa-solid fa-check-circle txt-10 txt-${!selectedAllOptions ? 'white' : 'green-500'}`} />
                </div> : null
              }
              {options.map((item) => {

                let isSelected = selectedData.includes(item[keyName]);
                let isDisabled = false;
                let isNew = false;

                if (disabledData) isDisabled = disabledData.findIndex(i => i === item[keyName]) >= 0;

                if (newData) isNew = newData.findIndex(i => i === item[keyName]) >= 0;

                return <div key={item[keyName]} className={`row-between w-100 align-items-center pe-pointer py-2 px-2 my-1 rounded-8 ${isSelected ? "bg-gray-800" : "bg-gray-500"}`}
                  onClick={() => {
                    if (isSelected) {
                      onChange(selectedData.filter(value => value !== item[keyName]));
                    } else {
                      !isDisabled && onChange([...selectedData, item[keyName]]);
                    }
                  }}>
                  <div className="row-between align-items-center w-100">
                    <div className="row-start align-items-center">
                      <div className={`txt-phrase txt-6 txt-${isDisabled ? 'gray' : 'white'} pe-pointer`}>{language ? language[item[labelName]] : item[labelName]}</div>
                      {isNew ? <p className={`txt-phrase txt-6 txt-${isDisabled ? 'gray' : 'white'} pe-pointer ms-2`}>{`[${language['genius_multipleselect_label_new_graphs']}]`}</p> : null}
                    </div>
                    {isSelected && <i className="fa-solid fa-check-circle txt-green-500 txt-10"></i>}
                  </div>
                </div>
              })}
            </div> : null
          }
        </div>
      }>
      <div className="column-start align-items-start w-100">
        <div className="row-center align-items-center w-100">
          <button className={`row-between border-2 border-color-${error ? 'red-500' : 'white'} align-items-center ${background} rounded-6 py-4 px-3 w-100`} style={{ height: 58 }} onClick={() => { setActive(!active) }}>
            <p className="txt-white txt-phrase txt-8">{label}</p>
            <i className={`fa-regular ${active ? 'fa-chevron-up' : 'fa-chevron-down'} txt-6 txt-white`}></i>
          </button>
        </div>
        {error ? <p className="txt-phrase txt-regular txt-6 txt-red-500 mt-1 w-100 text-wrap">{error}</p> : null}
      </div>
    </Popover>
  );
};

export default MultipleSelect;