import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';

import * as servicesActions from '../../store/actions/Services';
import * as sharedActions from '../../store/actions/Shared';

import { Popover } from "@idui/react-popover";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fal } from '@fortawesome/pro-light-svg-icons';

import { track } from "@amplitude/analytics-browser";

const Services = (props) => {

  //let account = props.account;
  let totalServices = props.totalServices;
  let servicesTypes = props.servicesTypes;
  let activeServices = props.activeServices;

  //const loadServicesAsync = props.loadServicesAsync;

  const [focus, setFocus] = useState(false);
  const [services, setServices] = useState(totalServices);
  const [search, setSearch] = useState('');
  const [typesSelected, setTypesSelected] = useState([...props.availableTypeServices]);
  const [selectedAllServices, setSelectedAllServices] = useState(false);
  const [show, setShow] = useState(false);
  const [servicesActive, setServicesActive] = useState([]);
  const [disabledServices, setDisabledServices] = useState([]);

  useEffect(() => {
    setServices([...totalServices.filter(s => typesSelected.includes(s.type.name) && s.name.toLowerCase().includes(search.toLowerCase()))]);
  }, [typesSelected, search, totalServices]);

  useEffect(() => {
    setSelectedAllServices(totalServices.length === activeServices.length);
  }, [activeServices, totalServices]);

  // useEffect(() => {
  //   account && loadServicesAsync()
  // }, [account, loadServicesAsync]);

  useEffect(() => {
    setDisabledServices([...typesSelected.filter(s => !totalServices.map(x => x.type.name).includes(s))]);
  }, [typesSelected, totalServices]);

  useEffect(() => {
    setServicesActive([...totalServices.filter(s => typesSelected.includes(s.type.name)).map(v => v.type.name)]);
  }, [typesSelected, totalServices]);

  return (
    <Popover
      lazy
      closeOnEnter
      fitMaxHeightToBounds
      fitMaxWidthToBounds
      trigger={'click'}
      placement={'bottomRight'}
      arrowColor={'#161617'}
      className={'p-0 bg-gray-500'}
      isOpen={show}
      withArrow={false}
      offset={[0, 0]}
      openingAnimationTranslateDistance={0}
      animation={{
        animate: { opacity: 1 },
        exit: { opacity: 0, transition: { duration: 0.1 } },
        initial: { opacity: 0 }
      }}

      onChangeOpen={(state) => {
        setShow(state);
        props.setShowBlackScreen(state);
        setFocus(state);        
      }}
      content={
        <div className="w-100 px-1">
          <div className="py-3 mx-3 border-bottom-1 border-color-gray-600">
            <div className="row-start align-items-center border-1 border-color-white p-2 rounded-8">
              <input type="text" className="txt-phrase txt-8 txt-white bg-gray-500 border-0 w-100" placeholder={props.language.services_input_label_search} value={search} onChange={({ target }) => setSearch(target.value)} />
              <i className="fa-regular fa-search txt-8 txt-white" />
            </div>
            {props.availableTypeServices.length > 1 &&
              <div className="row-start align-items-center mt-3">
                {
                  servicesTypes.filter(s => props.availableTypeServices.includes(s.key)).map((item) => {

                    const isSelected = servicesActive.includes(item.key);
                    const isDisabled = disabledServices.includes(item.key);

                    return (
                      <div key={item.key} className={`row-start align-items-center pe-pointer py-1 px-3 me-2 border-2 rounded-30 ${isDisabled ? "border-color-gray-400 txt-gray-400" : ""} ${isSelected ? "bg-green-500 border-color-green-500 txt-neutral-800" : "boder-color-white txt-white"}`}
                        onClick={() => {
                          if (isSelected) {
                            if (typesSelected.length > 1)
                              setTypesSelected([...typesSelected.filter(t => t !== item.key)]);
                          } else {
                            !isDisabled && setTypesSelected([...typesSelected, item.key]);                            
                          }
                        }}>
                        <i className={`fak fa-${item.icon}-symbol txt-8 me-`} />
                        <div className="txt-phrase txt-semibold txt-8">{item.title}</div>
                      </div>
                    )
                  }
                  )}
              </div>
            }
            {props.allowAllServices ?
              <div className="row-between align-items-center bg-gray-800 pe-pointer p-2 rounded-8 mt-3" onClick={() => { if (!selectedAllServices) { props.selectFunction(totalServices) } else { props.selectFunction([activeServices[0]]) } }}>
                <div className={`txt-phrase txt-6 txt-${!selectedAllServices ? 'white' : 'green-500'} pe-pointer ms-2`}>{props.language.services_select_all}</div>
                <i className={`fa-solid fa-check-circle txt-10 txt-${!selectedAllServices ? 'white' : 'green-500'}`} />
              </div> : null
            }
          </div>
          <div className="column-start align-items-center services-container max-height-350 mx-3 py-3" style={{ overflowY: "auto", overflowX: "hidden" }}>
            {services.map((item) => {

              const isSelected = props.activeServices.findIndex(i => i.idService === item.idService) >= 0;

              return (
                <div key={item.idService} className={`row-between align-items-center w-100 pe-pointer p-2 my-2 rounded-8 ${isSelected ? "bg-gray-800" : "bg-black-dark"}`}
                  onClick={() => {

                    let services = props.activeServices;

                    if (isSelected) {
                      if (services.length > 1) {
                        services = services.filter(i => i.idService !== item.idService);
                        props.selectFunction([...services]);
                      }
                    }
                    else {
                      services = [...services, item];
                      if (services.length < 4) props.selectFunction([...services]);                      
                      track(props?.module === 'alwaysOn' ? 'Standby project selected' : 'Plus project selected', {Project_ID: services[services.length - 1]?.name})
                    }
                    setShow(false);
                    props.setShowBlackScreen(false);
                    setFocus(false);
                  }}>
                  <div className="row-start align-items-center w-100 max-width-400">
                    <div className="col-1 row-center">
                      <FontAwesomeIcon icon={fal[item.style ? item.style.icon : "faBuilding"]} className="txt-10" color={isSelected || props.activeServices.length < 3 ? item.style ? item.style.color : "#cefc34" : "#646468"} />
                    </div>
                    <div className="col-10 column-start align-items-start px-2">
                      <div className={`txt-phrase txt-bold txt-6 w-100 text-nowrap pe-pointer txt-${isSelected || props.activeServices.length < 3 ? 'white' : 'gray-600'}`}>{item.name}</div>
                      <div className={`txt-phrase txt-regular txt-6 w-100 text-nowrap pe-pointer txt-${isSelected || props.activeServices.length < 3 ? 'white' : 'gray-600'}`}>{item.address}</div>
                    </div>
                    {isSelected && <i className="col-1 fa-solid fa-check-circle txt-green-500 text-end txt-12"></i>}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      }>
      <div className="row-center align-items-center">
        <button className={`position-relative row-center align-items-center border-1 bg-gray-450 border-color-gray-800 rounded-30 ${focus && "zindex-5"}`} style={{ height: 32, width: 32 }}>
          <i className="button-circle-icon position-absolute fa-light fa-circle-plus txt-10 txt-gray-700"></i>
        </button>
      </div>
    </Popover>
  );
};

const mapStateToProps = (state, ownProps) => ({
  account: state.accounts.account,
  servicesTypes: state.services.servicesTypes,
  totalServices: state.services.totalServices,
  selectFunction: ownProps.selectFunction,
  activeServices: ownProps.activeServices,
  allowAllServices: ownProps.allowAllServices,
  availableTypeServices: ownProps.availableTypeServices,
  language: state.shared.language
});

const mapDispatchToProps = dispatch => ({
  loadServicesAsync: (payload) => dispatch(servicesActions.loadServicesAsync(payload)),
  setShowBlackScreen: (payload) => dispatch(sharedActions.setShowBlackScreen(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Services);