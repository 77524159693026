import React, { useEffect, useState, useRef, Fragment } from "react";
import { connect } from 'react-redux';

import * as sharedActions from '../../../../../store/actions/Shared';
import * as plusActions from '../../../../../store/actions/Plus';

import { VictoryChart, VictoryLabel, VictoryAxis, VictoryBar, VictoryZoomContainer, Rect } from "victory";
import { grid, tickLabelsX, tickLabelsY, tickStyle } from "../../../../shared/svgs/GraphStyles";

import _ from 'lodash';
import moment from 'moment-timezone';

import GraphPeriodPicker from "./GraphPeriodPicker";
import Calendar from "../../../../shared/utilities/Calendar";
import { useWindowSize } from '../../../../shared/utilities/useWindowSize';
import empty_graph_energy from "../../../../../imgs/empty-graph-energy.svg";

import { Transition } from 'react-transition-group';

import { Loader } from 'rsuite';
import ButtonCircle from "../../../../shared/utilities/ButtonCircle";

import { track } from "@amplitude/analytics-browser";

import Draggable from "react-draggable";

const formatDateLabel = (intervalGraph, domain, lang) => {

	let dateFormated;
	let intervalLeft;
	let intervalRight;

	moment.locale(lang);

	switch (intervalGraph) {
		case 0:
			dateFormated = moment.utc(domain?.x[1] * 1000).format("DD MMMM YYYY");
			break;
		case 1:
		case 2:
			intervalLeft = moment.utc((domain?.x[0] + 86400) * 1000).format("MMM DD");
			intervalRight = moment.utc(domain?.x[1] * 1000).format("MMM DD");
			dateFormated = `${intervalLeft} ${lang === 'es' ? 'a' : '-'} ${intervalRight}`;
			break;
		case 3:
			intervalLeft = moment.utc((domain?.x[0] + 2592000) * 1000).format("MMM YYYY");
			intervalRight = moment.utc(domain?.x[1] * 1000).format("MMM YYYY");
			dateFormated = `${intervalLeft} ${lang === 'es' ? 'a' : '-'} ${intervalRight}`;
			break;
		default:
			dateFormated = "";
			break;
	}

	return dateFormated;

}

const capitalizedText = (text) => {

	let label = "";
	var phrase = text.toLowerCase().split(' ');

	for (var i = 0; i < phrase.length; i++) {
		if (i !== 2) {
			phrase[i] = phrase[i].charAt(0).toUpperCase() + phrase[i].substring(1);
		}
	};

	label = phrase.join(' ');

	return label;
};

const handlerFormatAxis = (date, type, lang, title = false) => {

	let stringFormats;

	switch (type) {
		case 0:
			stringFormats = ['hh:mm DD/MM/YY', 'HH', 'dddd, DD MMMM YYYY']
			break;
		case 1:
		case 2:
			stringFormats = ['DD/MM/YY', 'ddd, DD', 'MMMM YYYY']
			break;
		case 3:
			stringFormats = ['MMM, YY', 'MMM', 'YYYY']
			break;
		default:
			break;
	}

	moment.locale(lang);
	return moment.utc(date * 1000).format(stringFormats[title ? 2 : 1])


};

const Graph = (props) => {

	const [graphContainerWidth, setGraphContainerWidth] = useState(0);
	const [domain, setDomain] = useState(null);
	const [entireDomain, setEntireDomain] = useState(null);
	const [grabbing, setGrabbing] = useState(false);
	const [emptyData, setEmptyData] = useState(false);

	const graphContainer = useRef(null);

	let showCalendar = props.showCalendar;
	let dataGraph = props.dataGraph;
	let intervalGraph = props.intervalGraph;
	let graphDateFrom = props.graphDateFrom;
	let graphDateTo = props.graphDateTo;
	let activeServicesPlus = props.activeServicesPlus;

	const size = useWindowSize();

	const setShowBlackScreen = props.setShowBlackScreen;
	const setShowCalendar = props.setShowCalendar;


	useEffect(() => { setShowBlackScreen(showCalendar) }, [setShowBlackScreen, showCalendar]);

	useEffect(() => setGraphContainerWidth(graphContainer?.current?.getBoundingClientRect().width), [size, props.graphLoading]);

	useEffect(() => setDomain(null), [intervalGraph, graphDateFrom, graphDateTo, activeServicesPlus]);

	useEffect(() => {
		const emptyDataGraph = dataGraph.filter(d => d?.value === 0);
		setEmptyData(emptyDataGraph.length === dataGraph.length);
		// eslint-disable-next-line
	}, [dataGraph]);

	useEffect(() => {
		if (dataGraph.length) {
			const intervalX = [dataGraph[0]?.date_unix, _.last(dataGraph)?.date_unix];
			setEntireDomain({ x: intervalX });
			if (!domain) {
				setDomain({ x: [intervalX[1] - (intervalGraph === 1 ? (604800) : intervalGraph === 0 ? 86400 : intervalGraph === 2 ? (86400 * 30) : 31536000), intervalX[1]] });
			}
		}
		// eslint-disable-next-line
	}, [dataGraph]);

	useEffect(() => {
		if (domain) {
			const intervalLeft = (domain.x[0] - entireDomain.x[0]) / (intervalGraph === 1 ? 604800 : intervalGraph === 0 ? 86400 : intervalGraph === 2 ? 86400 * 30 : 31536000);
			const intervalRight = (entireDomain.x[1] - domain.x[1]) / (intervalGraph === 1 ? 604800 : intervalGraph === 0 ? 86400 : intervalGraph === 2 ? 86400 * 30 : 31536000);
			if (intervalLeft < 0.6 && props.enableScrollData) {
				props.loadGraphAlwaysOnAsync(true, true);
			}
			if (intervalRight < 0.6 && props.enableScrollData && entireDomain.x[1] - moment().unix() <= 0) {
				props.loadGraphAlwaysOnAsync(true, false);
			}
		}
		// eslint-disable-next-line
	}, [domain, props.enableScrollData]);

	const getMax = (arr) => {
		arr = arr.filter(value => value?.value > 0 && value?.date_unix >= domain?.x[0] && value?.date_unix <= domain?.x[1])
			.map(value => value?.value)
			.reduce((a, b) => a.concat(b), []);
		return _.mean(arr);
	}

	const handleCalendar = (value) => {
		props.setGraphDates(value);
		props.loadGraphAlwaysOnAsync(false, true);
		setShowBlackScreen(false);
		setShowCalendar(false);
	}

	const graphLoadingContainer = (loading, language) => <div className="position-absolute h-100 w-100 left-0 top-0 zindex-1 p-4">
		<Transition
			in={loading}
			timeout={100}>
			{state => (
				<div className={`trans-fade trans-fade-${state} position-absolute w-100 h-100 top-0 left-0 zindex-1`}>
					<div className="column-center align-items-center w-100 h-100">
						<Loader inverse center size="md" content={language.loader_text_content} />
					</div>
				</div>
			)}
		</Transition>
	</div>;

	const RenderEmptyData = () => {
		return (
			<div className="column-center align-items-center w-100" style={{ height: 500 }}>
				<img src={empty_graph_energy} alt="empty_graph_energy" style={{ width: 155, height: 84 }} />
				<div className="txt-gray-600 txt-phrase txt-semibold txt-10 text-wrap mt-2">{props.language.energy_graph_title_empty_data}</div>
				<div className="txt-gray-600 txt-phrase txt-regular txt-8 text-wrap mt-2">{props.language.energy_graph_description_empty_data}</div>
			</div>
		);
	};

	const eventHandler = () => {
		track('Standby graphic scrolled', {Rank_ID: props.rankGraphIdAlwaysOn})	
	}

	return (
		<div className="position-relative w-100 p-3">
			<div className="position-relative w-100 bg-gray-500 border-1 border-color-gray-800 rounded-16 p-3">
				{props.graphLoading ? <div style={{ height: 500 }}>{graphLoadingContainer(props.graphLoading, props.language)}</div> :
					<Fragment>
						<div className="row-between w-100 align-items-center">
							<div className="row-start align-items-center">
								<div className="position-relative column-center align-items-center">
									<ButtonCircle
										icon='calendar'
										sizeIcon={8}
										onClick={() => { setShowCalendar(!showCalendar) }}
									/>
									<Calendar show={showCalendar} start={graphDateFrom} end={graphDateTo} interval={intervalGraph} setDates={handleCalendar} />
								</div>
								<p className="txt-phrase txt-semibold txt-white txt-8 mx-3">{capitalizedText(formatDateLabel(intervalGraph, domain, props.language.language_locale))}</p>
							</div>
							<GraphPeriodPicker />
						</div>
						<div ref={graphContainer} className={`row-start align-items-center w-100 ${grabbing ? "pe-grabbing" : "pe-grab"}`} style={{ overflowX: "hidden", overflowY: "hidden" }} onMouseDown={() => setGrabbing(true)} onMouseUp={() => setGrabbing(false)}>
							<Draggable axis="x" onStart={eventHandler} bounds='parent'>
								{emptyData === false && dataGraph.length ?
									<div style={{ width: graphContainerWidth }}>
										<VictoryChart
											animate={false}
											height={450}
											width={graphContainerWidth}
											domain={entireDomain}
											padding={{ top: 20, bottom: 100, left: 0, right: 0 }}
											containerComponent={
												<VictoryZoomContainer
													allowZoom={false}
													allowDrag={false}
													allowDraw={false}
													allowResize={false}
													zoomDimension={"x"}
													zoomDomain={domain}
													activateData={false}
													onZoomDomainChange={(domainZoom) => {														
														setDomain({ x: domainZoom.x });
													}} />
											}>
											<VictoryBar
												x={"date_unix"}
												y={"value"}
												data={dataGraph.filter(item => item?.date_unix >= domain?.x[0] && item?.date_unix <= domain?.x[1])}
												barRatio={10}
												barWidth={intervalGraph === 1 ? 60 : intervalGraph === 3 ? 40 : 20}
												labels={({ datum }) => datum?.value === 0 ? '' : datum?.value > 1000 ? `${((datum?.value) / 1000)?.toFixed(2)} kWh` : `${datum?.value} Wh`}
												labelComponent={<VictoryLabel y={340} dy={-5} textAnchor={'inherit'} verticalAnchor={'start'} style={{ fill: ({ datum }) => (datum?.value >= getMax(dataGraph) ? "#161617" : "#FFF"), fontSize: 10, fontFamily: "Inter", fontWeight: 600 }} angle={270} />}
												cornerRadius={{ topLeft: 4, topRight: 4 }}
												style={{ data: { fill: ({ datum }) => (datum?.value >= getMax(dataGraph) ? "url(#linearRedOrangeGradient)" : "#323234") } }}
											/>
											<VictoryAxis
												dependentAxis
												tickFormat={(x) => Math.round(x * 100) / 100}
												tickLabelComponent={<VictoryLabel dx={40} dy={-10} textAnchor={'middle'} verticalAnchor={'middle'} backgroundPadding={10} backgroundStyle={tickStyle} backgroundComponent={<Rect rx={8} ry={8}></Rect>}></VictoryLabel>}
												style={{ axis: { stroke: "transparent" }, grid: grid, tickLabels: tickLabelsY }} />
											<VictoryAxis tickValues={dataGraph.filter(item => item?.date_unix >= domain?.x[0] && item?.date_unix <= domain?.x[1]).map(item => item?.date_unix)} tickFormat={(x) => `${handlerFormatAxis(x, intervalGraph, props.language.language_locale)}`} style={{ tickLabels: tickLabelsX }} />
										</VictoryChart>
									</div> : <RenderEmptyData />}
							</Draggable>
						</div>
					</Fragment>}
			</div>
		</div>
	)

};

const mapStateToProps = (state) => ({
	showCalendar: state.shared.showCalendar,
	language: state.shared.language,
	dataGraph: state.plus.dataGraphAlwaysOn,
	intervalGraph: state.plus.intervalGraphAlwaysOn,
	enableScrollData: state.plus.enableScrollDataAlwaysOn,
	graphDateFrom: state.plus.graphDateFromAlwaysOn,
	graphDateTo: state.plus.graphDateToAlwaysOn,
	activeServicesPlus: state.services.activeServicesPlus,
	graphLoading: state.plus.loadingAlwaysOnModule,
	rankGraphIdAlwaysOn: state.plus.rankGraphIdAlwaysOn
});

const mapDispatchToProps = dispatch => ({
	setShowBlackScreen: (payload) => dispatch(sharedActions.setShowBlackScreen(payload)),
	setShowCalendar: (payload) => dispatch(sharedActions.setShowCalendar(payload)),
	loadGraphAlwaysOnAsync: (payload, type) => dispatch(plusActions.loadGraphAlwaysOnAsync(payload, type)),
	setGraphDates: (payload) => dispatch(plusActions.setGraphDatesAlwaysOn(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Graph);