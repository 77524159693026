import React, { useEffect, useRef } from "react";

import { connect } from "react-redux";

import * as plusActions from '../../../../store/actions/Plus';

import ContentLoader from "react-content-loader";

import { track } from "@amplitude/analytics-browser";

const indicatorsLoading = <div className="position-absolute w-100 h-100 left-0 top-0 zindex-1">
    <ContentLoader
        seed={2}
        backgroundColor="#161617"
        foregroundColor="#323234"
        style={{ width: "100%", height: "100%" }}>
        <rect x="0" y="0" width="calc(100%)" height="calc(100%)" rx="16" ry="16"></rect>
    </ContentLoader>
</div>;

const RenderWidgets = ({ item, language }) => {

    const hasHovered = useRef(false);

    const handleHover = () => {
        if (!hasHovered.current) {
            hasHovered.current = true;
            track('Standby widget hover', { Standby_Widget_ID: item?.title });            
        }
    };

    const handleLeave = () => {
        hasHovered.current = false;
    };

    return (
        <div
            className="column-between align-items-center bg-gray-500 border-1 border-color-gray-800 rounded-16 p-3 w-100"
            style={{ height: 140 }}
            onMouseOver={handleHover}
            onMouseLeave={handleLeave}>
            <p className="txt-phrase txt-6 txt-white">{language[item.title]}</p>
            <p className={`txt-bold txt-phrase txt-12 ${item.color}`}>{item.value}</p>
            <div className="row-center align-items-center">
                {item.kpi && <i className={`fa-regular fa-chevron-${item.increment ? "up" : "down"} me-2 ${item.positive ? "txt-red-500" : "txt-green-500"}`} />}
                <p className="txt-regular txt-6 txt-phrase txt-white">{item?.text_value}{language[item?.text]}</p>
            </div>
        </div>);
};

const Cards = (props) => {

    let activeServicesPlus = props.activeServicesPlus;
    let loadAlwaysOnIndicators = props.loadAlwaysOnIndicators;
    let alwaysOnIndicators = props.alwaysOnIndicators;

    useEffect(() => {
        const request = async () => await loadAlwaysOnIndicators();
        if (activeServicesPlus.length) request()
    }, [activeServicesPlus, loadAlwaysOnIndicators]);

    return (
        <div className="row-between align-items-center w-100 mt-3 px-3" style={{ gap: '16px' }}>
            {props.loadingAlwaysOnIndicators ?
                <div className="position-relative w-100" style={{ height: 140 }}>
                    {indicatorsLoading}
                </div> :
                alwaysOnIndicators.length &&
                alwaysOnIndicators.map((d, index) => (
                    <div key={index} style={{ width: `${100 / alwaysOnIndicators.length}%` }}>
                        <RenderWidgets key={index} item={d} language={props.language} />
                    </div>
                ))
            }
        </div>
    )
};

const mapStateToProps = (state) => ({
    alwaysOnIndicators: state.plus.alwaysOnIndicators,
    loadingAlwaysOnIndicators: state.plus.loadingAlwaysOnIndicators,
    devices: state.plus.devices,
    activeServicesPlus: state.services.activeServicesPlus,
    language: state.shared.language
})

const mapDispatchToProps = dispatch => ({
    loadAlwaysOnIndicators: () => dispatch(plusActions.loadAlwaysOnIndicators()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Cards)

