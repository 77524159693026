import React from "react";

export const RadialBlueGradient = () => (
    <svg style={{ height: 0, width: 0, display: 'flex' }}>
        <defs>
            <radialGradient id="radialBlueGradient" gradientTransform="translate(-0.5 -0.5) scale(2, 2)">
                <stop offset="0%" stopColor="transparent" />
                <stop offset="100%" stopColor="#00B7D3" />
            </radialGradient>
        </defs>
    </svg>
)

export const RadialGreenGradient = () => (
    <svg style={{ height: 0, width: 0, display: 'flex' }}>
        <defs>
            <radialGradient id="radialGreenGradient" gradientTransform="translate(-0.5 -0.5) scale(2, 2)">
                <stop offset="0%" stopColor="transparent" />
                <stop offset="100%" stopColor="#CEFC34" />
            </radialGradient>
        </defs>
    </svg>
)

export const RadialPurpleGradient = () => (
    <svg style={{ height: 0, width: 0, display: 'flex' }}>
        <defs>
            <radialGradient id="radialPurpleGradient" gradientTransform="translate(-0.5 -0.5) scale(2, 2)">
                <stop offset="0%" stopColor="transparent" />
                <stop offset="100%" stopColor="#9F14E5" />
            </radialGradient>
        </defs>
    </svg>
)

export const LinearRedOrangeGradient = () => (
    <svg style={{ height: 0, width: 0, display: 'flex' }}>
        <defs>
            <linearGradient id="linearRedOrangeGradient" x1="1" y1="0.5" x2="0" y2="0.5">
                <stop offset="0" stopColor="#FC3535" />
                <stop offset="100%" stopColor="#FFAA00" />
            </linearGradient>
        </defs>
    </svg>
)