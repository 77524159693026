import React, { Fragment } from "react";

import { connect } from "react-redux";

import { VictoryChart, VictoryPolarAxis, VictoryLabel, VictoryStack, VictoryBar } from 'victory';

import { Swiper, SwiperSlide } from 'swiper/react';

import _ from 'lodash';

import empty_modules_plus from "../../../../imgs/empty-modules-plus.svg";

import ContentLoader from "react-content-loader";

import { track } from "@amplitude/analytics-browser";

const RenderCardEmpty = ({ language }) => {
    return (
        <div className={`swiper-slider-container column-between align-items-center bg-gray-500 rounded-16 p-3`} style={{ height: 200 }}>
            <img src={empty_modules_plus} alt="empty_radar_data" style={{ width: 154, height: 154 }} />
            <div className={`row-center mx-5 align-items-center txt-phrase txt-regular txt-6 txt-neutral-50`}>
                <div className="w-auto bg-gray-800 rounded-30 py-2 px-4">
                    {language.plus_graph_title_empty_data}
                </div>
            </div>
        </div>
    )
};

const loadingContainer = <div className="position-absolute h-100 w-100 left-0 top-0 zindex-1">
    <ContentLoader
        seed={2}
        foregroundColor="#161617"
        backgroundColor="#323234"
        style={{ width: "100%", height: "100%" }}>
        <rect x="0" y="0" width="calc(100%)" height="calc(100%)" rx="16" ry="16"></rect>
    </ContentLoader>
</div>;

const PowerFactor = (props) => {

    let deviceActive = props.activeServicesPlus.length ? props.devices.filter(value => value?.id_service === props.activeServicesPlus[0]?.idService) : [];

    return (
        <Fragment>
            {
                props.loadingData ?
                    <div className="position-relative w-100 mt-3" style={{ minHeight: 312 }}>{loadingContainer}</div> :
                    !props.dataGraph.length || !('power_factor_1' in _.last(props.dataGraph)) ?
                        <div className="position-relative column-between align-items-center bg-gray-500 border-1 border-color-gray-800 rounded-16 py-3 w-100 mt-3">
                            <p className="txt-phrase txt-8 txt-white txt-bold">{props.language.power_factor_title}</p>
                            <div
                                id="prev-slide-quality"
                                className="position-absolute left-0 row-center align-items-center h-100 pe-pointer"
                                style={{ width: 40, height: 40, zIndex: 1 }}
                                onClick={() => track('Quality widget ll clicked')}>
                                <i className="fa-regular fa-chevron-left txt-14 txt-white pe-pointer" />
                            </div>
                            <div className="position-relative row-center align-items-center w-100 h-100">
                                <Swiper
                                    loop={true}
                                    autoplay={{ delay: 5000, disableOnInteraction: false }}
                                    navigation={{ nextEl: "#next-slide-quality", prevEl: "#prev-slide-quality" }}
                                    slidesPerView={1}>
                                    <SwiperSlide>
                                        <RenderCardEmpty language={props.language} />
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                            <div
                                id="next-slide-quality"
                                className="position-absolute row-center align-items-center h-100 pe-pointer right-0"
                                style={{ width: 40, height: 40 }}
                                onClick={() => track('Quality widget ll clicked')} >
                                <i className="fa-regular fa-chevron-right txt-14 txt-white pe-pointer" />
                            </div>
                        </div> :
                        <div className="position-relative column-between align-items-center bg-gray-500 border-1 border-color-gray-800 rounded-16 py-3 w-100 mt-3">
                            <div className="position-relative row-center align-items-center w-100 h-100">
                                <div
                                    id="prev-slide-quality"
                                    className="position-absolute left-0 row-center align-items-center h-100 pe-pointer zindex-2"
                                    style={{ width: 40, height: 40 }}
                                    onClick={() => track('Quality widget ll clicked')} >
                                    <i className="fa-regular fa-chevron-left txt-14 txt-white pe-pointer" />
                                </div>
                                <svg style={{ height: 0 }}>
                                    <defs>
                                        <linearGradient id="green">
                                            <stop offset="0.14%" stopColor="#A1C52A" />
                                            <stop offset="99.52%" stopColor="#00B4FF" />
                                        </linearGradient>
                                        <linearGradient id="red">
                                            <stop offset="0.14%" stopColor="#FC3535" />
                                            <stop offset="99.52%" stopColor="#FFAA00" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <div className="position-relative w-100">
                                    <Swiper
                                        loop={true}
                                        autoplay={{ delay: 5000, disableOnInteraction: false }}
                                        navigation={{ nextEl: "#next-slide-quality", prevEl: "#prev-slide-quality" }}
                                        slidesPerView={1}>
                                        {(deviceActive[0]?.phases === 2 ? [1, 2] : [1, 2, 3]).map((item) =>
                                            <SwiperSlide key={item}>
                                                <div className={`row-center align-items-center w-100 px-3 mb-2`}>
                                                    <p className="txt-phrase txt-8 txt-white txt-bold">{props.language.power_factor_title}</p>
                                                </div>
                                                <div className={`row-center align-items-center w-100`}>
                                                    <p className="txt-phrase txt-6 txt-white txt-bold bg-gray-450 border-1 border-color-gray-800 rounded-30 py-2 px-3">{`${props.language.power_factor_label_phase} ${item}`}</p>
                                                </div>
                                                <div className="w-100">
                                                    <VictoryChart polar
                                                        maxDomain={{ x: 360, y: 100 }}
                                                        height={80} width={130}
                                                        padding={{ top: 10, left: 20, right: 20, bottom: 10 }}
                                                        style={{ background: { fill: '#323234' } }}
                                                    >
                                                        <VictoryPolarAxis dependentAxis
                                                            style={{
                                                                axis: { stroke: "none" },
                                                                tickLabels: { fill: "none" },
                                                                grid: { stroke: "#161617", strokeWidth: 3, strokeOpacity: 0.3 }
                                                            }}
                                                        />
                                                        <VictoryPolarAxis
                                                            tickValues={[0, 360]}
                                                            style={{
                                                                tickLabels: { fill: "none" }
                                                            }}
                                                        />
                                                        <VictoryStack
                                                            style={{ data: { fill: "none", stroke: _.last(props.dataGraph)[`power_factor_${item}`] >= 0.92 ? "url(#green)" : _.last(props.dataGraph)[`power_factor_${item}`] >= 0.8 ? "#FFAA00" : "url(#red)", strokeWidth: 1, width: 360, opacity: _.last(props.dataGraph)[`power_factor_${item}`] >= 0.7 ? 0.9 : 0.2 } }}
                                                        >
                                                            <VictoryBar data={[{ x: 0, y: _.last(props.dataGraph)[`power_factor_${item}`] * 100 }, { x: 360, y: _.last(props.dataGraph)[`power_factor_${item}`] * 100 }]} />
                                                        </VictoryStack>
                                                        <VictoryLabel
                                                            textAnchor="middle" verticalAnchor="middle"
                                                            x={65} y={40}
                                                            style={{ fontFamily: "Sora", fontSize: _.last(props.dataGraph)[`power_factor_${item}`] >= 0.8 ? 16 : 14, fill: _.last(props.dataGraph)[`power_factor_${item}`] >= 0.92 ? "url(#green)" : _.last(props.dataGraph)[`power_factor_${item}`] >= 0.8 ? "#FFAA00" : "url(#red)" }}
                                                            text={(_.last(props.dataGraph)[`power_factor_${item}`] ).toFixed(3) + ""}
                                                        />
                                                    </VictoryChart>
                                                </div>
                                                <div className={`row-center mx-5 align-items-center txt-phrase txt-regular txt-6 txt-neutral-50 text-wrap`}>
                                                    {_.last(props.dataGraph)[`power_factor_${item}`] >= 0.92 ?
                                                        <p className={`txt-phrase txt-semibold txt-blue-green-gradient txt-8 text-wrap text-center`}>{props.language.power_factor_label_optime_range}</p> :
                                                        _.last(props.dataGraph)[`power_factor_${item}`] >= 0.8 ?
                                                            <p className={`txt-phrase txt-semibold txt-orange-500 txt-8 text-wrap text-center`}>{props.language.power_factor_label_reactive_range}</p> :
                                                            <p className={`txt-phrase txt-semibold txt-red-orange-gradient txt-8 text-wrap text-center`}>{props.language.power_factor_label_deficient_range}</p>
                                                    }
                                                </div>
                                            </SwiperSlide>)}
                                    </Swiper>
                                </div>
                                <div
                                    id="next-slide-quality"
                                    className="position-absolute row-center align-items-center h-100 pe-pointer right-0 zindex-1"
                                    style={{ width: 40, height: 40 }}
                                    onClick={() => track('Quality widget ll clicked')} >
                                    <i className="fa-regular fa-chevron-right txt-14 txt-white pe-pointer" />
                                </div>
                            </div>
                        </div>
            }
        </Fragment>
    )

};

const mapStateToProps = (state, ownProps) => ({
    dataGraph: state.plus.dataGraph,
    loadingData: state.plus.loadingInitData,
    language: state.shared.language,
    devices: state.plus.devices,
    activeServicesPlus: state.services.activeServicesPlus,
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(PowerFactor);