import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';

import * as sharedActions from '../../store/actions/Shared';
import * as geniusActions from '../../store/actions/Genius';

import { Popover } from '@idui/react-popover';

const ExportReport = ({ htmlUrl, excelUrl, ...props }) => {    

    const [show, setShow] = useState(false);
    const [focus, setFocus] = useState(false);
    const [fileName, setFileName] = useState("");

    return (
        <Popover
            lazy
            fitMaxHeightToBounds
            fitMaxWidthToBounds
            trigger={'click'}
            placement={'bottomRight'}
            className={'p-0 rounded-8 bg-gray-500'}
            withArrow={false}
            isOpen={show}
            width="400px"
            height='220px'
            offset={[0, 0]}
            openingAnimationTranslateDistance={0}
            onChangeOpen={(state) => {
                props.setShowBlackScreen(state);
                setShow(state);
                setFocus(state);
            }}
            animation={{
                animate: { opacity: 1 },
                exit: { opacity: 0, transition: { duration: 0.1 } },
                initial: { opacity: 0, }
            }}
            content={
                <Fragment>
                    <div className='column-between h-100 w-100 p-3'>
                        <div className='row-start'>
                            <p className='txt-phrase txt-regular txt-white txt-10'>{props.language.genius_download_file_report}</p>
                        </div>
                        <div className='row-start align-items-center w-100 py-1 border-bottom-1 border-color-gray-700'>
                            <i className="fal fa-file txt-10 txt-gray-700" style={{ width: '5%' }}></i>
                            <input value={fileName} onChange={({ target }) => setFileName(target.value)} placeholder='Nombre del documento' type='text' autoFocus={true} className='input-export-report txt-white txt-phrase txt-8 ms-2' style={{ width: '95%' }} />
                        </div>
                        <div className='row-end my-2'>
                            <button onClick={() => { props.downloadReportsAsync({ url: htmlUrl, filename: fileName !== "" ? `${fileName}.html` : '' }); setFileName(''); setShow(false); props.setShowBlackScreen(false); }} className={`me-${excelUrl !== '' ? 3 : 0} bg-green-500 rounded-8 py-2 px-4 txt-blue-900 txt-phrase txt-8 txt-bold`} style={{ minWidth: 155 }}>
                                <div className="row-start align-items-center">
                                    <i className="fal fa-file-code txt-10"></i>
                                    <div className="txt-phrase txt-semibold txt-8 ms-2 pe-pointer">{props.language.genius_download_html_report}</div>
                                </div>
                            </button>
                            {excelUrl !== '' ? <button onClick={() => { props.downloadReportsAsync({ url: excelUrl, filename: fileName !== "" ? `${fileName}.xlsx` : '' }); setFileName(''); setShow(false); props.setShowBlackScreen(false); }} className='bg-green-500 rounded-8 py-2 px-4 txt-blue-900 txt-phrase txt-8 txt-bold' style={{ minWidth: 155 }}>
                                <div className="row-start align-items-center">
                                    <i className="fal fa-file-excel txt-10"></i>
                                    <div className="txt-phrase txt-semibold txt-8 ms-2 pe-pointer">{props.language.genius_download_excel_report}</div>
                                </div>
                            </button> : null}
                        </div>
                    </div>
                </Fragment>}>
            {!focus ? <div className="pe-pointer bg-gray-450 border-1 border-color-gray-800 rounded-30 py-2 pe-3">
                <div className="row-start align-items-center h-100">
                    <div className="row-center align-items-center min-width-32">
                        <i className="fa-light fa-arrow-down txt-8 txt-gray-700" />
                    </div>
                    <span className="txt-phrase txt-8 txt-overflow-unset txt-white pe-pointer">{props.language.genius_download_report}</span>
                </div>
            </div> :
            <div className="row-center zindex-7 pe-pointer align-items-center bg-gray-800 rounded-circle" style={{height: 30, width: 30}}>
                <i className="fa-light fa-circle-xmark txt-10 txt-gray-700" />
            </div>}
        </Popover>

    );
}

const mapStateToProps = (state) => ({
    language: state.shared.language
});

const mapDispatchToProps = dispatch => ({
    setShowBlackScreen: (payload) => dispatch(sharedActions.setShowBlackScreen(payload)),
    downloadReportsAsync: (payload) => dispatch(geniusActions.downloadReportsAsync(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportReport);