import React from 'react';
import step1 from '../../../imgs/onboarding/step1-graph-calendar.png';
import step1En from '../../../imgs/onboarding/english/step1-graph-calendar-en.png';
import step2 from '../../../imgs/onboarding/step2-graph-calendar.png';
import step2En from '../../../imgs/onboarding/english/step2-graph-calendar-en.png';

export const stepsGraphCalendar = (language) => {
    return ([
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 122, width: 300 }}>
                    <img src={language.language_locale === 'es'? step1 : step1En} className='w-100 h-100' alt="step1-graph-calendar" />
                </div>,
            title: language.onboarding_graph_calendar_title_step1,
            description: language.onboarding_graph_calendar_description_step1,
            placement: "left-start",
            target: "#date_calendar_picker",
            disableBeacon: true,
            steps: 2,
            disableOverlayClose: true,
            spotlightClicks: true,
            hideFooter: true,
            hideFooterTitle: '¡Da click para descubrir cómo!',
            pulseEffect: true
        },
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 122, width: 300 }}>
                    <img src={language.language_locale === 'es'? step2: step2En } className='w-100 h-100' alt="step2-graph-calendar" />
                </div>,
            title: language.onboarding_graph_calendar_title_step2,
            description: language.onboarding_graph_calendar_description_step2,
            placement: "left-end",
            target: "#calendar_fast_access",
            disableBeacon: true,
            steps: 2,
            disableOverlayClose: true,
            hideBackButton: true
        },
    ])
};