export const SET_GRAPH_DATA = 'SET_GRAPH_DATA';
export const SET_GRAPH_LOADING = 'SET_GRAPH_LOADING';
export const SET_INTERVAL_GRAPH = 'SET_INTERVAL_GRAPH';
export const SET_GRAPH_DATES = 'SET_GRAPH_DATES';
export const SET_GRAPH_OPTIONS = 'SET_GRAPH_OPTIONS';
export const SET_GRAPH_ACTIVE = 'SET_GRAPH_ACTIVE';
export const SET_INDICATORS = 'SET_INDICATORS';
export const SET_INVOICE_INFO = 'SET_INVOICE_INFO';
export const SET_INDICATORS_LOADING = 'SET_INDICATORS_LOADING';
export const SET_GRAPH_DATES_SCROLL = 'SET_GRAPH_DATES_SCROLL';
export const SET_ENABLE_SCROLL_DATA = 'SET_ENABLE_SCROLL_DATA';
export const SET_SHOW_REPORT_MODAL = 'SET_SHOW_REPORT_MODAL';
export const SET_REPORT_LOADING = 'SET_REPORT_LOADING';
export const SET_SCROLL_ACTIVE = 'SET_SCROLL_ACTIVE';
export const SET_TARIFF = 'SET_TARIFF';
export const SET_BANNERS_DATA = 'SET_BANNERS_DATA';
export const SET_SHOW_DETAIL_SERVICE = 'SET_SHOW_DETAIL_SERVICE';
export const SET_DETAIL_DATA_GRAPH = 'SET_DETAIL_DATA_GRAPH';
export const SET_PREV_STATE_BAR = 'SET_PREV_STATE_BAR';
export const SET_ENABLE_AVERAGE_LINE = 'SET_ENABLE_AVERAGE_LINE';