import React from "react";
import { connect } from 'react-redux';

import * as invoicesActions from '../../store/actions/Invoices';

import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import enLocale from 'date-fns/locale/en-US';

import CurrencyFormat from 'react-currency-format';

const InvoiceBubble = (props) => {

    let selectedInvoices = props.selectedInvoices;
    let invoice = props.invoice;
    let language = props.language;
    let invoiceDetails = props.invoiceDetails;

    let isSelected = selectedInvoices.includes(invoice.id_bill);

    let color;
    let bg_color;
    let card_color;
    let state_string;
    let date;
    let date_string;

    const expiration = new Date(invoice.date_expiration);

    const locale = language.language_locale === 'es' ? esLocale : enLocale;

    const setSelectedInvoices = props.setSelectedInvoices;

    switch (invoice.state) {
        case 0:
            if (expiration < new Date()) {
                color = 'red-500';
                bg_color = 'red';
                card_color = 'red-200';
                state_string = language.invoices_state_overdue;
                date = invoice.date_expiration;
                date_string = language.invoices_state_due_date;
            }
            else {
                color = 'orange-500';
                bg_color = 'orange';
                card_color = 'orange-200';
                state_string = language.invoices_state_pending;
                date = invoice.date_expiration;
                date_string = language.invoices_state_due_date;
            }
            break;
        case 1:
            color = 'green-500';
            bg_color = 'green';
            card_color = 'green-500';
            state_string = language.invoices_state_paid;
            date = invoice.date_payment;
            date_string = language.invoices_paid_text_date_payment;
            break;
        default:
            break;
    }

    return (
        <div id={`${invoice.id_bill}`} className={`row-between align-items-center pe-pointer w-100 px-3 bg-gray-450 py-2 my-2 rounded-16 ${invoiceDetails?.reference === invoice.reference && !props.invoicesLoading && `elevation-${invoice.state === 0 ? 'red' : 'green'}`}`}
            onClick={() => props.handlerClick(invoice)}>
            <i className={`${isSelected ? "fa-regular fa-check-circle txt-green-500" : "fa-regular fa-circle txt-white"} txt-10 pe-pointer me-3`} onClick={() => {
                isSelected ? selectedInvoices.length > 1 && setSelectedInvoices([...selectedInvoices.filter(i => i !== invoice.id_bill)]) :
                    setSelectedInvoices([...selectedInvoices, invoice.id_bill])
            }}></i>
            <div className="flex-grow-1 position-relative pe-pointer">
                <div className="row-between align-items-center w-100 my-2 pe-pointer">
                    <div className="column-start w-75">
                        <div className="txt-phrase txt-bold txt-6 txt-white text-nowrap max-width-150 pe-pointer">{invoice.service_name}</div>
                        <div className="txt-phrase txt-regular txt-6 txt-white pe-pointer">{invoice.reference}</div>
                    </div>
                    <div className={`row-center align-items-center rounded-pill px-2 py-1 w-25 pe-pointer border-1 border-color-${bg_color}-500 bg-${bg_color}-500-40`}>
                        <div className={`txt-phrase txt-regular txt-6 pe-pointer txt-${card_color}`}>{state_string}</div>
                    </div>
                </div>
                <div className="row-between align-items-end mb-2 pe-pointer">
                    <div className="column-start">
                        <CurrencyFormat
                            value={invoice.total_payment}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                            renderText={value => <div className={`txt-phrase txt-bold txt-8 txt-${color} pe-pointer`}>{value}</div>} />
                        <div className={`txt-phrase txt-6 pe-pointer txt-${color}`}>{date_string} {format(new Date(date), "dd MMM. yyyy", { locale: locale })}</div>
                    </div>
                    <i className={`fa-regular fa-eye txt-8 txt-white pe-pointer pe-pointer opacity-${invoiceDetails?.reference === invoice.reference ? 100 : 10}`}></i>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state, owmProps) => ({
    selectedInvoices: state.invoices.selectedInvoices,
    language: state.shared.language,
    invoiceDetails: state.invoices.invoiceDetails,
    invoice: owmProps.invoice,
    handlerClick: owmProps.handlerClick,
    invoicesLoading: state.invoices.invoicesLoading
});

const mapDispatchToProps = dispatch => ({
    setSelectedInvoices: (payload) => dispatch(invoicesActions.setSelectedInvoices(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceBubble);