import React from "react"
import { Modal } from "rsuite"

const DeleteAlertModal = ({ openModal, setOpenModal, deleteAlertAsync, alert, language }) => {
    return (
        <Modal
            size="xs"
            show={openModal}
            overflow={false}
            backdropClassName={"bg-black opacity-80 pe-pointer"}
            dialogClassName="rounded"
            onHide={() => setOpenModal(false)}>
            <div className="column-start w-100 h-100 px-4 py-3">
                <div>
                    <p className="txt-red-40 txt-bold txt-phrase txt-12">
                        <i className="fa-solid fa-do-not-enter"></i>{" "}
                        {language.alerts_messager_delete_title_confirmation}
                    </p>
                </div>
                <p className="txt-white txt-phrase txt-10 py-3">{language.alerts_messager_delete_confirmation}</p>
                <div className="row-end align-items-center gap-5 ">
                    <p className="txt-red-40 txt-phrase txt-semibold txt-10 pe-pointer" onClick={() => { setOpenModal(false) }}>{language.alerts_messager_keep_option}</p>
                    <button className="py-2 px-3 bg-red-40 rounded-8" onClick={async () => { setOpenModal(false); await deleteAlertAsync(alert.id_alert); }}>
                        <p className="txt-white txt-phrase txt-semibold txt-10 pe-pointer">{language.alerts_messager_delete_option}</p>
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteAlertModal