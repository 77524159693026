import React, { useState, useEffect } from "react"
import { connect } from "react-redux"

import * as energyActions from "../../../store/actions/Energy"
import * as sharedActions from "../../../store/actions/Shared"

import { track } from "@amplitude/analytics-browser"

import { Popover } from "@idui/react-popover"
import ExtraItemsPopover from "../../genius/ExtraItemsPopover"

const graphIntervalData = [
  {
    interval: 0,
    labelName: "energy_period_picker_hour_to_hour",
    eventProperty: "Rank_Hourly",
    labelDescription: "energy_period_picker_hour_to_hour_description",
  },
  {
    interval: 1,
    labelName: "energy_period_picker_day_to_day",
    eventProperty: "Rank_Weekly",
    labelDescription: "energy_period_picker_day_to_day_description",
  },
  {
    interval: 2,
    labelName: "energy_period_picker_day_to_day_monthly",
    eventProperty: "Rank_Monthly",
    labelDescription: "energy_period_picker_day_to_day_monthly_description",
  },
  {
    interval: 3,
    labelName: "energy_period_picker_month_to_month",
    eventProperty: "Rank_Yearly",
    labelDescription: "energy_period_picker_month_to_month_description",
  },
]

const GraphPeriodPicker = (props) => {
  const language = props.language
  const setControlledOnboarding = props.setControlledOnboarding
  const setIntervalGraph = props.setIntervalGraph
  let graphPeriodPicker = props.graphPeriodPicker
  let activeGraphs = props.activeGraphs

  const changePeriod = (interval) => {    
    if (props.intervalGraph !== interval) {
      setIntervalGraph(interval)
    }
  }

  const [focus, setFocus] = useState(false)
  const [show, setShow] = useState(false)

  const getIntervalLabel = graphIntervalData.filter((data) => data.interval === props.intervalGraph)[0]?.labelName

  useEffect(() => {
    if(show && graphPeriodPicker){
      setControlledOnboarding({key: 'graph_period_picker', value: true})
    }else{
      setControlledOnboarding({key: 'graph_period_picker', value: false})
    }    
  }, [show, setControlledOnboarding, graphPeriodPicker])    

  return (
    <Popover
      lazy
      closeOnRemoteClick={graphPeriodPicker ? false : true}
      fitMaxHeightToBounds
      fitMaxWidthToBounds
      trigger={"click"}
      placement={"bottom"}
      className={"p-0 rounded-8 bg-gray-500"}
      withArrow={false}
      isOpen={show}
      width="200px"
      offset={[0, 0]}
      openingAnimationTranslateDistance={0}
      onChangeOpen={(state) => {
        props.setShowBlackScreen(state)
        setShow(state)
        setFocus(state)
        props.onChange(state)               
      }}
      animation={{
        animate: { opacity: 1 },
        exit: { opacity: 0, transition: { duration: 0.1 } },
        initial: { opacity: 0 },
      }}
      content={
        <div id="date_interval" className="w-100 p-2 overflow-auto border-none-scroll" style={{ maxHeight: "55vh" }}>
          {graphIntervalData.length ? (
            <div className="column-start graphs-picker-container ">
              {graphIntervalData.map((item) => {
                let isSelected
                isSelected = props.intervalGraph === item["interval"]

                return (
                  <div                    
                    key={item.interval}
                    className={`row-between align-items-center pe-pointer py-2 ps-3 pe-2 my-1 rounded-8 ${isSelected ? "bg-gray-550" : "bg-gray-500"}`}
                    onClick={() => {
                      if(!activeGraphs.includes('REACTIVE_CAPACITIVE_EXCESS_ENERGY')){
                        changePeriod(item["interval"])                      
                        track('Rank selected', {Rank_ID: item['eventProperty'], Project_Type: props.activeServicesEnergy.map(function (service) { return service?.type?.name })})
                      }                     
                    }}>
                    <div className="column-start max-width-280 w-100">
                      <ExtraItemsPopover
                        key={item.interval}
                        placement="right"
                        content={
                          <div className="w-100">
                            <div className="column-start align-items-center">
                              <div className="row-between w-100 align-items-center px-3 py-2">
                                <p className={`txt-phrase text-wrap txt-regular txt-6 txt-white pe-pointer prevent-select`}>{language[item.labelDescription]}</p>
                              </div>
                            </div>
                          </div>
                        }
                        button={
                          <div className="row-between max-width-280 w-100">
                            <div className={`txt-phrase text-wrap txt-regular txt-6 txt-${activeGraphs.includes('REACTIVE_CAPACITIVE_EXCESS_ENERGY') && item.interval !== 3 ? 'gray-700' : 'white'} pe-pointer prevent-select`}>{language[item.labelName]}</div>
                            <i className={`fal fa-check-circle txt-${isSelected ? "green-500" : "gray-700"} txt-10`}></i>
                          </div>
                        }
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          ) : null}
        </div>
      }>
      <button id="date_range_picker" className={`row-center ${graphPeriodPicker && props.showPulseAnimation && 'pulse'} align-items-center border-1 border-color-gray-800 bg-gray-450 gap-2 rounded-pill py-2 ps-3 pe-2 ${focus && "zindex-7"}`}>
        <p className="txt-phrase txt-white txt-6 text-nowrap">{language[getIntervalLabel]}</p>
        <i className={`fa-light fa-circle-chevron-down txt-10 txt-gray-700 transition-transform ${focus ? "rotate-180" : ""}`}></i>
      </button>
    </Popover>
  )
}

const mapStateToProps = (state) => ({
  intervalGraph: state.energy.intervalGraph,
  language: state.shared.language,
  graphPeriodPicker: state.shared.startOnboarding.graph_period_picker,
  showPulseAnimation: state.shared.showPulseAnimation,
  activeServicesEnergy: state.services.activeServicesEnergy,
  activeGraphs: state.energy.activeGraphs,
})

const mapDispatchToProps = (dispatch) => ({
  setIntervalGraph: (payload) => dispatch(energyActions.setIntervalGraph(payload)),
  setShowBlackScreen: (payload) => dispatch(sharedActions.setShowBlackScreen(payload)),
  setControlledOnboarding: (payload) => dispatch(sharedActions.setControlledOnboarding(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(GraphPeriodPicker)
