import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import * as loginActions from '../store/actions/Login';
import * as sharedActions from '../store/actions/Shared';

import { Route, Redirect, Switch } from 'react-router-dom';

import { Transition } from 'react-transition-group';

import { Loader } from 'rsuite';

import Energy from './energy/Energy';
import Menu from './menu/Menu';
import Invoices from './invoices/Invoices';
import Account from './shared/Account';
import Plus from "./plus/Plus";
import Efficiency from './efficiency/Efficiency';
import TagManager from './plus/dashboard/tags/TagManager';
import Genius from './genius/Genius';
import AlwaysOn from './plus/dashboard/alwayson/AlwaysOn';
import QualityEnergy from './plus/dashboard/quality/QualityEnergy';
import Dashboard from './dashboard/Dashboard';
import Preonboarding from './preonboarding/Preonboarding';
import OnboardingModal from './onboarding/OnboardingModal';
import NpsSurvey from './nps/NpsSurvey';

import Alerts from './alerts/Alerts';
import LumiBanner from './shared/LumiBanner';
import OnboardingSections from './onboarding/OnboardingSections';

import useTour from './onboarding/hook/useTour';
import { stepsProfile } from './onboarding/steps/ProfileSteps';
import { stepsAlert } from './onboarding/steps/AlertSteps';
import { stepsWalktrough } from './onboarding/steps/WalktroughSteps';

import { LinearRedOrangeGradient, RadialBlueGradient, RadialGreenGradient, RadialPurpleGradient } from './shared/svgs/Gradients';

const ProtectedRoute = ({ component: Component, hasAccess, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (hasAccess) {
                    return <Component {...props} />;
                } else {
                    return <Redirect to="/login" />;
                }
            }}
        />
    );
};


const Main = (props) => {

    let user = props.user;

    const loadInitInfoAsync = props.loadInitInfoAsync;
    const setShowCalendar = props.setShowCalendar;

    const [showAccount, setShowAccount] = useState(false);

    const tourProfile = useTour({steps: stepsProfile(props.language), localStorageKey: "profile-tutorial", isOpenDrawer: showAccount, runTour: props.profile, setRunTour: props.setStartOnboarding, key: 'profile'});
    const tourAlerts = useTour({steps: stepsAlert(props.language), localStorageKey: "alerts-tutorial", isOpenDrawer: props.showDrawerAlerts, runTour: props.newAlert, setRunTour: props.setStartOnboarding, key: 'new_alert', controlledOnboarding: props.controlledNewAlert, setShowPulseAnimation: props.setShowPulseAnimation });
    const tourWalktrough = useTour({steps: stepsWalktrough(props.language), localStorageKey: "walktrough-tutorial", runTour: props.walktrough, setRunTour: props.setStartOnboarding, key: 'walktrough'})

    useEffect(() => loadInitInfoAsync(), [loadInitInfoAsync]);    

    return (
        <Fragment>
            {tourProfile}      
            {tourAlerts}    
            {tourWalktrough} 
            <RadialBlueGradient />
            <RadialPurpleGradient />
            <RadialGreenGradient />
            <LinearRedOrangeGradient />
            <main id='tooltip_walktrough' className="position-relative row-start align-items-start min-vh-100 w-100 overflow-x-hidden d-none d-lg-flex">
                <Transition
                    in={props.loading}
                    timeout={100}>
                    {state => (
                        <div className={`trans-fade trans-fade-${state} position-absolute w-100 h-100 top-0 left-0 zindex-1`}>
                            <div className="column-center align-items-center w-100 h-100">
                                <Loader inverse center size="md" content={props.language.loader_text_content} />
                            </div>
                        </div>
                    )}
                </Transition>
                <Transition
                    in={props.showBlackScreen}
                    timeout={100}>
                    {state => (
                        <div className={`position-fixed zindex-5 left-0 top-0 w-100 vh-100 trans-fade trans-fade-${state}`} onClick={() => setShowCalendar(false)}>
                            <div className="bg-black opacity-80 w-100 h-100"></div>
                        </div>)}
                </Transition>
                {!props.loading && props.step &&
                    (props.step === 3 ?
                        props.user.capabilities?.show_section_onboarding ?
                            <Redirect to="/onboarding" /> :
                            <Fragment>
                                <LumiBanner/>
                                <Menu
                                    setShowAccount={setShowAccount} />
                                <Account
                                    showAccount={showAccount}
                                    setShowAccount={setShowAccount} />
                                <Alerts />
                                <OnboardingSections showAccount={showAccount} setShowAccount={setShowAccount} />
                                {/* {user?.capabilities?.show_chatbot ? <Chatbot showChatBot={showChatBot} setShowChatBot={setShowChatBot}/> : <NpsBetaSurvey show={showNpsBetaSurvey} />} */}
                                <OnboardingModal showBetaMessage={user.capabilities?.show_feedback_new_platform_survey}/>
                                { user.capabilities?.show_nps_survey && <NpsSurvey /> }
                                <section id="main-container" className="min-vh-100">
                                    <Switch>
                                        <Route path='/home/detail' exact component={Energy} />
                                        <Route path='/home' exact component={Dashboard} />
                                        <ProtectedRoute path='/invoices' exact component={Invoices} hasAccess={user.capabilities?.show_section_invoices} />
                                        <ProtectedRoute path='/plus' exact component={Plus} hasAccess={user.capabilities?.show_section_plus} />
                                        <ProtectedRoute path='/genius' exact component={Genius} hasAccess={user.capabilities?.show_section_genius} />
                                        <ProtectedRoute path='/efficiency' exact component={Efficiency} hasAccess={user.capabilities?.show_section_efficiency} />
                                        <ProtectedRoute path='/plus/tag' exact component={TagManager} hasAccess={user.capabilities?.show_section_plus} />
                                        <ProtectedRoute path='/plus/always-on' exact component={AlwaysOn} hasAccess={user.capabilities?.show_section_efficiency} />
                                        <ProtectedRoute path='/plus/quality' exact component={QualityEnergy} hasAccess={user.capabilities?.show_section_efficiency} />
                                        <Route path='/pre-onboarding' exact component={Preonboarding} />
                                        <Route render={() => <Redirect to="/login" />} />
                                    </Switch>
                                </section>
                            </Fragment> :
                        <Redirect to="/login" />)}
            </main >
        </Fragment>);
}

const mapStateToProps = (state) => ({
    step: state.login.step,
    loading: state.login.loading,
    showBlackScreen: state.shared.showBlackScreen,
    language: state.shared.language,
    user: state.users.user,
    newAlert: state.shared.startOnboarding.new_alert,
    profile: state.shared.startOnboarding.profile,
    walktrough: state.shared.startOnboarding.walktrough,
    controlledNewAlert: state.shared.controlledOnboarding.new_alert,
    showDrawerAlerts: state.alerts.showDrawerAlerts
})

const mapDispatchToProps = dispatch => ({
    loadInitInfoAsync: () => dispatch(loginActions.loadInitInfoAsync()),
    setShowCalendar: (payload) => dispatch(sharedActions.setShowCalendar(payload)),
    setStartOnboarding: (payload) => dispatch(sharedActions.setStartOnboarding(payload)),
    setShowPulseAnimation: (payload) => dispatch(sharedActions.setShowPulseAnimation(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Main)
