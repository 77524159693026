import * as creators from "../creators/Chatbot";

import { SETData, GETData } from "../../services/WebServices";

import moment from "moment";

export const setActiveChat = (payload) => {
  return {
    type: creators.SET_ACTIVE_CHAT,
    payload,
  };
};

export const setLoadingChat = (payload) => {
  return {
    type: creators.SET_LOADING_CHAT,
    payload,
  };
};

export const setMessages = (payload) => {
  return {
    type: creators.SET_MESSAGES,
    payload,
  };
};

export const setCurrentConversation = (payload) => {
  return {
    type: creators.SET_CURRENT_CONVERSATION,
    payload,
  };
};

export const setShowChatBot = (payload) => {
  return {
    type: creators.SET_SHOW_CHATBOT,
    payload,
  };
};

export const setActiveChatMessages = (payload) => {
  return {
    type: creators.SET_ACTIVATE_CHAT_MESSAGES,
    payload,
  };
};

export const setConversations = (payload) => {
  return {
    type: creators.SET_CONVERSATIONS,
    payload,
  };
};

export const setShowBannerChatbot = (payload) => {
  return {
    type: creators.SET_SHOW_BANNER_CHATBOT,
    payload
  };
};

export const loadConversations = () => {
  return async (dispatch) => {
    const user = JSON.parse(localStorage.getItem("user"));

    GETData(`history?user=${user.idUser}`, "GET", process.env.REACT_APP_CHATBOT_API_URL)
      .then((response) => dispatch(setConversations(response)))
      .catch((err) => console.error(err));
  };
};

export const loadActiveChat = (payload) => {
  return async (dispatch) => {
    const user = JSON.parse(localStorage.getItem("user"));

    dispatch(setLoadingChat(true));

    await GETData(`plus/chatbot/${user.idUser}`, "GET")
      .then((result) => {
        if (result?.body?.length) {
          const entity = {
            id_user: user.idUser,
            id_conversation: payload,
          };

          SETData("messages", "POST", entity, process.env.REACT_APP_CHATBOT_API_URL)
            .then((response) => {
              const conversationMessages = [];

              response.forEach((message) => {
                const userMessage = {
                  id_content: message.id_content,
                  message: message.request,
                  sender: "user",
                  date: moment(message.created_at).format("h:mm A"),
                  full_date: moment(message.created_at).format("ddd DD MMMM YYYY"),
                  onBoarding: null,
                };

                const botMessage = {
                  id_content: message.id_content,
                  message: message.response_summary ?? message.response,
                  sender: "chatbot",
                  date: moment(message.created_at).format("h:mm A"),
                  full_date: moment(message.created_at).format("ddd DD MMMM YYYY"),
                  onBoarding: null,
                  score: message.score,
                };

                conversationMessages.push(userMessage);

                conversationMessages.push(botMessage);
              });

              if (!conversationMessages.length) {
                dispatch(setActiveChatMessages(true));
              }

              dispatch(setLoadingChat(false));

              dispatch(setMessages(conversationMessages));

              dispatch(setCurrentConversation(payload));
            })
            .catch((response) => console.error(response));

          dispatch(setActiveChat(true));
        } else {
          dispatch(setActiveChat(false));
        }
      })
      .catch((err) => console.error(err));
  };
};

export const setActiveChatAsync = () => {
  return async (dispatch) => {
    const user = JSON.parse(localStorage.getItem("user"));

    await SETData(`plus/chatbot/set/${user.idUser}`, "POST")
      .then((result) => {
        if (result?.body) {
          dispatch(setActiveChat(true));
        }
      })
      .catch((err) => console.error(err));
  };
};

export const setConversation = (payload) => {
  return (dispatch, getState) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const activeServicesEnergy = getState().services.activeServicesEnergy;
    const currentConversationId = getState().chatbot.currentConversationId;
    const messages = getState().chatbot.messages;

    const services = activeServicesEnergy.map((x) => x?.idService);

    const entity = {
      id_user: user.idUser,
      id_conversation: currentConversationId,
      services: services,
      message: payload,
    };

    const newMessages = [
      ...messages,
      {
        message: payload,
        sender: "user",
        date: moment().format("h:mm A"),
        full_date: moment().format("ddd DD MMMM YYYY"),
        onBoarding: null,
      },
      {
        message: "pending",
        sender: "chatbot",
        date: moment().format("h:mm A"),
        full_date: moment().format("ddd DD MMMM YYYY"),
        onBoarding: null,
      },
    ];

    dispatch(setMessages(newMessages));

    SETData("send", "POST", entity, process.env.REACT_APP_CHATBOT_IA_API_URL)
      .then((response) => {

        let chatbotResponse = ""
        let idContent = 0

        if (response === null) {

          chatbotResponse = "Lo siento, parece que hubo un error al intentar procesar tu pregunta. Por favor, podrías reformularla para poder brindarte la información que necesitas.";

        } else {

          chatbotResponse = response.response
          idContent = response.id_content

          if (response.status_code === 2) {
            chatbotResponse = "Lo siento, parece que hubo un error al intentar procesar tu pregunta. Por favor, podrías reformularla para poder brindarte la información que necesitas.";
          } else if (response.status_code === 3) {
            chatbotResponse = "Lo siento, Por favor selecciona al menos un servicio al cual quieras relacionar esta pregunta.";
          }

        }

        const updatedMessages = newMessages.map((item) => {
          if (item.sender === "chatbot" && item.message === "pending") {
            return { ...item, id_content: idContent, message: chatbotResponse };
          }

          return item;

        });

        if (response.id_conversation) {
          dispatch(setCurrentConversation(response.id_conversation));
        }

        dispatch(setMessages(updatedMessages));
      })
      .catch((response) => console.error(response));
  };
};

export const setScore = (payload) => {
  return (dispatch, getState) => {
    const id_content = payload.id_content;
    const score = payload.score;

    const messages = getState().chatbot.messages;

    const updatedMessages = messages.map((item) => {
      if (item.id_content === id_content) {
        return { ...item, score: score };
      }
      return item;
    });

    dispatch(setMessages(updatedMessages));

    const entity = {
      id_content,
      score,
    };

    SETData("score", "PUT", entity, process.env.REACT_APP_CHATBOT_API_URL).catch((response) => console.error(response));
  };
};
