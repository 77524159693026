import * as alerts from "../creators/Alerts";
import * as notifications from "./Notifications";

import { GETData, SETData } from "../../services/WebServices";

import { Alert } from 'rsuite';

export const setAlerts = payload => {
    return {
        type: alerts.SET_ALERTS,
        payload,
    };
};

export const setAlertsLoading = payload => {
    return {
        type: alerts.SET_ALERTS_LOADING,
        payload,
    };
};

export const setAlertRegisters = payload => {
    return {
        type: alerts.SET_ALERT_REGISTERS,
        payload,
    };
};

export const setAlertRegistersPagination = payload => {
    return {
        type: alerts.SET_ALERT_REGISTERS_PAGINATION,
        payload,
    };
};

export const setShowDrawerAlerts = payload => {
    return {
        type: alerts.SET_SHOW_DRAWER_ALERTS,
        payload,
    };
};

export const loadAlertsAsync = () => {
    return (dispatch, getState) => {

        dispatch(setAlertsLoading(true));

        const userId = getState().users.user.idUser;

        GETData(`alerts/${userId}?page_limit=20`, "GET")
            .then(response => {
                if (response !== null) {
                    dispatch(setAlerts(response.data));
                } else {
                    dispatch(setAlerts([]));
                }
            })
            .catch(response => console.error(response))
            .finally(() => dispatch(setAlertsLoading(false)));
    };

};


export const createAlertAsync = (payload) => {
    return (dispatch, getState) => {

        const userId = getState().users.user.idUser;
        const language = getState().shared.language;
        let fromHour = payload?.intervalTime?.fromHour;
        let toHour = payload?.intervalTime?.toHour;
        fromHour = payload?.intervalTime?.fromPartOfDay === 'pm' ? String(parseInt(fromHour) + 12) : fromHour;
        toHour = payload?.intervalTime?.toPartOfDay === 'pm' ? String(parseInt(toHour) + 12) : toHour;

        const parameters = {
            id_graph: payload?.graphIds[0],
            id_alert_type: payload?.condition[0],
            value: payload?.value,
            id_user: userId,
            alert_name: payload?.name,
            id_frequency: payload?.intervalTime?.intervalIds[0],
            start_hour: fromHour + ":" + payload?.intervalTime?.fromMinutes + ":00",
            end_hour: toHour + ":" + payload?.intervalTime?.toMinutes + ":00",
            active_week_days: payload?.intervalTime?.dayIds,
            id_channels: payload?.channelIds,
            id_services: payload?.serviceIds
        }

        SETData(`alerts`, "POST", parameters)
            .then((response) => {
                if (response !== null) {
                    Alert.success(language.alerts_messager_alert_create_success, 8000);
                } else
                    Alert.error(language.alerts_messager_alert_create_error, 8000);
            })
            .catch(response => console.error(response))
            .finally(() => dispatch(loadAlertsAsync()));

    }
}


export const editAlertAsync = (payload) => {
    return (dispatch, getState) => {

        const language = getState().shared.language;
        let fromHour = payload?.intervalTime?.fromHour;
        let toHour = payload?.intervalTime?.toHour;
        fromHour = payload?.intervalTime?.fromPartOfDay === 'pm' ? String(parseInt(fromHour) + 12) : fromHour;
        toHour = payload?.intervalTime?.toPartOfDay === 'pm' ? String(parseInt(toHour) + 12) : toHour;

        const parameters = {
            id_graph: payload?.graphIds[0],
            id_alert_type: payload?.condition[0],
            value: payload?.value,
            alert_name: payload?.name,
            id_frequency: payload?.intervalTime?.intervalIds[0],
            start_hour: fromHour + ":" + payload?.intervalTime?.fromMinutes + ":00",
            end_hour: toHour + ":" + payload?.intervalTime?.toMinutes + ":00",
            active_week_days: payload?.intervalTime?.dayIds,
            id_channels: payload?.channelIds,
            id_services: payload?.serviceIds
        }

        SETData(`alerts/${payload?.idAlert}`, "PATCH", parameters)
            .then((response) => {
                if (response !== null) {
                    Alert.success(language.alerts_messager_alert_edit_success, 6000);
                } else
                    Alert.error(language.alerts_messager_alert_edit_error, 8000);
            })
            .catch(response => console.error(response))
            .finally(() => dispatch(loadAlertsAsync()));

    }
}

export const deleteAlertAsync = (id) => {
    return (dispatch, getState) => {
        const language = getState().shared.language;
        GETData(`alerts/${id}`, "DELETE")
            .then((_response) => {
                Alert.success(language.alerts_messager_alert_delete_success, 6000);
            })
            .catch(response => console.error(response))
            .finally(() => dispatch(loadAlertsAsync()));;
    };

};

export const loadAlertRegistersAsync = () => {
    return (dispatch, getState) => {

        dispatch(notifications.setNotificationsLoading(true));

        const alerts = getState().alerts.alertRegisters;
        const page = getState().alerts.alertRegistersPagination || 1;

        const userId = getState().users.user.idUser;

        GETData(`notifications/${userId}?page=${page}&page_limit=10&all_registers=${false}`, "GET")
            .then(response => {
                if (response !== null) {
                    dispatch(setAlertRegistersPagination(response.pagination_info.next_page));
                    dispatch(setAlertRegisters([...alerts, ...response.data]));
                }
            })
            .catch(response => console.error(response))
            .finally(() => dispatch(notifications.setNotificationsLoading(false)));
    };

};
