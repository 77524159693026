import React from "react"

import step1 from "../../../imgs/onboarding/step1-service-detail.png"
import step1En from "../../../imgs/onboarding/english/step1-service-detail-en.png"
import step2 from "../../../imgs/onboarding/step2-service-detail.png"
import step2En from "../../../imgs/onboarding/english/step2-service-detail-en.png"

export const stepsService = (language) => {
  return (
    [
      {
        content: (
          <div className="row-center align-items-center w-100 mt-3" style={{ height: 152, width: 300 }}>
            <img src={language.language_locale === 'es'? step1 : step1En} className="w-100 h-100" alt="setp 1 service detail" />
          </div>
        ),
        title: language.onboarding_service_title_step1,
        description: language.onboarding_service_description_step1,
        placement: "bottom",
        target: "#menu_services",
        disableBeacon: true,
        steps: 2,
      },
      {
        content: (
          <div className="row-center align-items-center w-100 mt-3" style={{ height: 152, width: 300 }}>
            <img src={language.language_locale === 'es'? step2 : step2En} className="w-100 h-100" alt="setp 2 service detail" />
          </div>
        ),
        title: language.onboarding_service_title_step2,
        description: language.onboarding_service_description_step2,
        placement: "bottom-start",        
        target: "#active_service",
        disableBeacon: true,
        steps: 2,
      },
    ]
  )
} 
