import React, { Component, Fragment } from 'react';

import CurrencyFormat from 'react-currency-format';

import { Loader } from 'rsuite';

export default class PaidStatus extends Component {
    render() {

        let statusMessage;
        let statusIcon;
        let margin;
        let light  = this.props.light;
        let transaction = this.props.transaction;
        let language = this.props.language;

        if (transaction !== null) {

            switch (transaction.state) {
                case "APROBADA":
                    margin = <i className="far fa-pipe txt-14 txt-green-500"></i>
                    statusMessage = <div className="txt-green-500 txt-title txt-semibold txt-10 ms-3">{language.invoices_paid_title_state_approved}</div>;
                    statusIcon = <i className="far fa-check-circle txt-14 txt-green-500 ms-3"></i>;
                    break;
                case "RECHAZADA":
                case "FALLIDA":
                    margin = <i className="far fa-pipe txt-14 txt-red-500"></i>
                    statusMessage = <div className="txt-red-500 txt-title txt-semibold txt-10 ms-3">{language.invoices_paid_title_state_declined}</div>;
                    statusIcon = <i className="far fa-times-circle txt-14 txt-red-500 ms-3"></i>;
                    break;
                default:
                    margin = <i className="far fa-pipe txt-14 txt-blue-500"></i>
                    statusMessage = <div className="txt-blue-500 txt-title txt-semibold txt-10 ms-3">{language.invoices_paid_title_state_pending}</div>;
                    statusIcon = <i className="far fa-clock txt-14 txt-blue-500 ms-3"></i>;
                    break;
            }

        }

        return (
            <div className="column-between align-items-center flex-grow-1 w-100 p-4">
                {transaction !== null && Object.keys(language).length ?
                    <Fragment>
                        <div className='column start align-items-start w-100'>
                            <div className='row-between align-items-start'>                                
                                <div className='column start align-items-start'>
                                    <div className={`txt-title txt-${light ? "black" : "neutral-50"} txt-12 txt-bold`}>{language.invoices_paid_title_payment_receipt}</div>
                                    <div className={`txt-phrase txt-${light ? "black" : "white"} txt-8 txt-regular`}>{transaction.date}</div>
                                </div>
                                <a href='https://app.enrg.ai/home' className={`row-center bg-gray-800 rounded-pill px-2 py-1 ${light && "d-none"}`}>
                                    <i className="far fa-close txt-8 txt-neutral-50"></i>
                                </a>
                            </div>
                            <div className={`row-start align-items-center rounded-8 bg-${light ? "gray-50" : "gray-800"} py-2 pe-4 mt-4`}>                               
                                {margin}
                                {statusIcon}
                                {statusMessage}
                            </div>
                        </div>                        
                        <div className='column-start w-100 py-5'>
                            <div className='row-between align-items-center'>
                                <div className='txt-phrase txt-gray-400 txt-10 txt-regular'>{language.invoices_paid_text_method}</div>
                                <div className={`txt-phrase txt-${light ? "black" : "white"} txt-10 txt-regular`}>{language[`invoices_paid_text_${transaction.method}_method`]}</div>
                            </div>
                            <div className='row-between align-items-center mt-3'>
                                <div className='txt-phrase txt-gray-400 txt-10 txt-regular'>{language.invoices_paid_text_ref}</div>
                                <div className={`txt-phrase txt-${light ? "black" : "white"} txt-10 txt-regular`}>{transaction.reference}</div>
                            </div>
                            <div className='row-between align-items-center mt-3'>
                                <div className='txt-phrase txt-gray-400 txt-10 txt-regular'>{language.invoices_paid_text_description}</div>
                                <div className={`txt-phrase txt-${light ? "black" : "white"} txt-10 txt-regular`}>{transaction.description}</div>
                            </div>
                            {
                                transaction.email ?
                                <div className='row-between align-items-center mt-3'>
                                    <div className='txt-phrase txt-gray-400 txt-10 txt-regular'>{language.invoices_paid_text_email}</div>
                                    <div className={`txt-phrase txt-${light ? "black" : "white"} txt-10 txt-regular`}>{transaction.email}</div>
                                </div> : null
                            }
                        </div>
                        <div className={`column-start w-100 rounded-16 bg-${light ? "gray-50" : "gray-800"} p-4`}>
                            <div className={`txt-title txt-${light ? "black" : "white"} txt-10 txt-bold`}>{language.invoices_paid_text_transaction_details}</div>
                            <div className='row-between align-items-center mt-2'>
                                <div className='txt-phrase txt-gray-400 txt-8 txt-regular'>{language.invoices_paid_text_total_bill}</div>
                                <CurrencyFormat
                                    className={`txt-phrase txt-${light ? "black" : "white"} txt-8 txt-medium`}
                                    value={transaction.amount}
                                    prefix="$"
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true} />
                            </div>
                            <div className='row-between align-items-center mt-2'>
                                <div className='txt-phrase txt-gray-400 txt-8 txt-regular'>{language.invoices_paid_text_neugets_discount}</div>
                                <CurrencyFormat
                                    className={`txt-phrase txt-${light ? "black" : "white"} txt-8 txt-medium`}
                                    value={transaction.neugets}
                                    prefix="$"
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true} />
                            </div>
                            {
                                transaction.commission > 0 ?
                                <div className='row-between align-items-center mt-2'>
                                    <div className='txt-phrase txt-gray-400 txt-8 txt-regular'>{language.invoices_paid_text_commission}</div>
                                    <CurrencyFormat
                                        className={`txt-phrase txt-${light ? "black" : "white"} txt-8 txt-medium`}
                                        value={transaction.commission}
                                        prefix="$"
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true} />
                                </div> : null
                            }
                            <div className={`row-between align-items-center mt-2 border-top-1 border-color-${light ? "gray-800" : "gray-50"} py-2`}>
                                <div className={`txt-title txt-${light ? "black" : "white"} txt-12 txt-bold`}>{language.invoices_paid_text_total_payment}</div>
                                <CurrencyFormat
                                    className={`txt-phrase txt-${light ? "black" : "white"} txt-12 txt-semibold`}
                                    value={transaction.total}
                                    prefix="$"
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true} />
                            </div>
                        </div>                        
                    </Fragment> :
                    <Loader inverse center size="md" content={language.loader_text_content} />
                }
            </div>
        )
    }
};
