import * as creators from '../creators/Users';

const initialState = {
    user: null,
    profileProgress: 0,
    savingInfoUser: false,
    userAccessEdit: null,
    showCreateUserModal: false,
    showUsersListModal: false,
    userJobs: [],
    usersAccount: [],
    usersAccountLoading: true,
    userChannels: [],
    userPersonalInterests: [],
    userPreferenceInfo: null,
    showUserPreferencesModal: null,
    userTokenApi: null,
    usersLoading: false
};

export const usersReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case creators.SET_USER:
            return { ...state, user: payload };
        case creators.SET_USER_CAPABILITIES:
            const user = state.user;
            return { ...state, user: { ...user, capabilities: { ...user.capabilities, ...payload } } };
        case creators.SET_PROFILE_PROGRESS:
            return { ...state, profileProgress: payload };
        case creators.SET_SAVING_INFO_USER:
            return { ...state, savingInfoUser: payload };
        case creators.SET_USER_ACCESS_EDIT:
            return { ...state, userAccessEdit: payload };
        case creators.SET_SHOW_CREATE_USER_MODAL:
            return { ...state, showCreateUserModal: payload };
        case creators.SET_SHOW_USERS_LIST_MODAL:
            return { ...state, showUsersListModal: payload };
        case creators.SET_USER_JOBS:
            return { ...state, userJobs: payload };
        case creators.SET_USERS_ACCOUNT:
            return { ...state, usersAccount: payload };
        case creators.SET_USERS_ACCOUNT_LOADING:
            return { ...state, usersAccountLoading: payload };
        case creators.SET_USER_CHANNELS:
            return { ...state, userChannels: payload };
        case creators.SET_USER_PERSONAL_INTERESTS:
            return { ...state, userPersonalInterests: payload };
        case creators.SET_USER_PREFERENCE_INFO:
            return { ...state, userPreferenceInfo: payload };
        case creators.SET_SHOW_MODAL_USER_PREFERENCE:
            return { ...state, showUserPreferencesModal: payload };
        case creators.SET_USER_TOKEN_API:
            return { ...state, userTokenApi: payload };
        case creators.SET_LOADING_NEW_USER:
            return { ...state, usersLoading: payload };
        default:
            return state;
    }
}