import React from "react"
import { DateRange } from "react-date-range"
import { es, enUS, pt } from "react-date-range/dist/locale"
import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file

import { Transition } from "react-transition-group"

import { startOfToday, format } from "date-fns"

import { track } from "@amplitude/analytics-browser"

const CalendarRangePicker = ({ show, setShow, dateRange, setDateRange, language }) => {

  return (
    <Transition in={show} timeout={50}>
      {(state) => (
        <div className={`position-absolute column-start align-items-center top-100 zindex-15 mt-3 trans-fade trans-fade-${state}`}>
          <DateRange
            locale={language?.language_locale === "es" ? es : language?.language_locale === "en" ? enUS : pt}
            rangeColors={["rgba(127, 127, 127, 0.8)", "rgba(194, 194, 194, 0.6)", "#ffffff"]}
            dateDisplayFormat="eee. dd, LLLL"
            onChange={(date) => {
              setDateRange([date.selection])        
            }}
            showSelectionPreview={false}
            ranges={dateRange}
            minDate={new Date("2020-07-01T19:21:00.000Z")}
            maxDate={startOfToday()}
          />

          <div className="d-flex bg-gray-500 w-full pb-2 pt-1 pe-3 justify-content-end" style={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}>
            <button className="p-2 rounded-8 bg-green-500 txt-phrase txt-bold txt-8 txt-neutral-800" onClick={() => {setShow(false); track('Genius date selected', {Rank_Date: `${format(dateRange[0]?.startDate, 'ddMMMyy')}_${format(dateRange[0]?.endDate, 'ddMMMyy')}`})}}>
              {language?.genius_done_button}
            </button>
          </div>
        </div>
      )}
    </Transition>
  )
}

export default CalendarRangePicker
