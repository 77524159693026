import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Drawer } from 'rsuite';

import NewAlert from './NewAlert';
import PopoverAlerts from './PopoverAlerts';
import * as alertsActions from '../../store/actions/Alerts';

import emptyDataAlerts from '../../imgs/empty_state_alerts.svg';
import { track } from "@amplitude/analytics-browser";
import DeleteAlertModal from './DeleteAlertModal';
import BackButton from '../shared/utilities/BackButton';

import ContentLoader from "react-content-loader";

const AlertsLoading = () => {

    const height = 85;

    return (
        <div className="position-absolute w-100 h-100 left-0 top-0 zindex-1 py-1">           
            <ContentLoader
                seed={2}
                backgroundColor="#323234"
                foregroundColor="#161617"
                style={{ width: "100%", height: "100%" }}>
                {Array.from({ length: 5 }, (_x, i) => i).map((i) => <rect key={i} x="0" y={`${i * (height + 10)}`} width="100%" height={height} rx="8" ry="8"></rect>)}
            </ContentLoader>
        </div>
    )
};

const RenderAlert = ({ language, alert, setShowNewAlert, setAlertSelected, setEditAlert, setOpenDeleteModal }) => {
    return (
        <div className='column-start bg-gray-800 rounded-8 p-3'>
            <div className='row-between w-100 align-items-center'>
                <i className='fal fa-square-bolt txt-10 txt-green-500'></i>
                <PopoverAlerts
                    onChangeEdit={() => { setAlertSelected(alert); setShowNewAlert(true); setEditAlert(true) }}
                    onChangeDuplicate={() => { setAlertSelected(alert); setShowNewAlert(true); setEditAlert(false) }}
                    setOpenDeleteModal={setOpenDeleteModal}
                />
            </div>
            <p className='txt-phrase col-10 txt-semibold txt-white txt-8 my-3'>{alert?.alert_name ? alert?.alert_name : language.alerts_label_no_name + alert?.id_alert}</p>
            <div className='col-10 row-start align-items-center'>
                <i className='fal fa-building txt-10 txt-gray-600 me-2'></i>
                <p className='txt-phrase txt-regular txt-gray-600 txt-8'>{alert?.services[0]?.name}</p>
            </div>
        </div>
    )
}

const Alerts = (props) => {

    const [showNewAlert, setShowNewAlert] = useState(false);
    const [alertSelected, setAlertSelected] = useState(null);
    const [editAlert, setEditAlert] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false)

    let showBlackScreenDrawer = props.showBlackScreenDrawer;
    let showDrawerAlerts = props.showDrawerAlerts;

    const loadAlertsAsync = props.loadAlertsAsync;

    useEffect(() => loadAlertsAsync(), [loadAlertsAsync]);      

    return (
        <Drawer
            show={showDrawerAlerts}
            size={'xs'}
            placement={'right'}
            backdropClassName={'pe-pointer'}
            onHide={() => { props.setShowDrawerAlerts(false) }}
            onEntering={() => track('Alert viewed', {Project_Type:props.activeServicesEnergy.map(function (service) { return service?.type?.name })})}>
            <div id='alerts_container' className="column-start align-items-center vh-100 w-100 overflow-auto bg-gray-500">
                {
                    showBlackScreenDrawer ?
                        <div className="position-absolute left-0 top-0 h-100 w-100 zindex-5">
                            <div className="bg-black opacity-80 w-100 h-100" />
                        </div> : null
                }
                <div className='column-start align-items-start px-4'>
                    <div className='row-between w-100 align-items-center my-3'>
                        <BackButton onClick={() => { props.setShowDrawerAlerts(false); }} />
                        <p className='flex-grow-1 txt-title txt-neutral-50 txt-bold txt-12 text-end'>{props.language.alerts_label_main_title_drawer}</p>
                    </div>
                    <div className='column-center w-100 align-items-center bg-gray-800 p-3 mt-3 rounded-16'>
                        <i className='fas fa-sensor-on txt-18 txt-green-500'></i>
                        <p className='txt-phrase txt-regular txt-white txt-8 w-100 text-wrap text-center mt-3'>{props.language.alerts_label_creation_indications}</p>
                        <button id='added_alert' className={`row-center align-items-center p-2 gap-2 bg-green-500 rounded-8 w-100 mt-3 ${props.newAlert && props.showPulseAnimation && 'pulse'}`} onClick={() => { setShowNewAlert(true) }}>
                            <i className='fas fa-plus txt-neutral-800 txt-10'></i>
                            <p className='txt-phrase txt-semibold txt-neutral-800 txt-8 pe-pointer'>{props.language.alerts_button_add_new_alert}</p>
                        </button>
                    </div>
                    <p className='txt-title txt-neutral-50 txt-bold txt-12 mt-3 mb-2 pb-2'>{props.language.alerts_label_recently_created}</p>
                    {props.loading ?                        
                        <div className="position-relative w-100 h-100 min-height-300"><AlertsLoading /></div>:
                        props.alerts.length ?
                            <div className="alert-container w-100" style={{ overflowY: "auto" }}>
                                {props.alerts.map((alert) => (
                                    <div key={alert.id_alert}>
                                        <RenderAlert
                                            language={props.language}
                                            alert={alert}
                                            setShowNewAlert={setShowNewAlert}
                                            setAlertSelected={setAlertSelected}
                                            setEditAlert={setEditAlert}
                                            setOpenDeleteModal={setOpenDeleteModal}
                                            key={alert.id_alert} />
                                        <DeleteAlertModal openModal={openDeleteModal} setOpenModal={setOpenDeleteModal} alert={alert} deleteAlertAsync={props.deleteAlertAsync} language={props.language} />
                                    </div>
                                ))}
                            </div> :
                            <div className="w-100 h-100 column-center align-items-center px-3 overflow-hidden">
                                <img src={emptyDataAlerts} className="my-4" alt="logo-empty-data-alerts" />
                                <div className="txt-phrase txt-semibold txt-10 txt-gray-400 text-center text-wrap">{props.language.alerts_label_title_empty_state}</div>
                                <div className="txt-phrase txt-8 txt-gray-400 text-center py-3">{props.language.alerts_label_body_empty_state}</div>
                            </div>}
                    {props.newAlert && <div className='txt-white txt-14 w-100 bg-gray-500' style={{ height: 300 }} />}
                </div>
                <NewAlert showNewAlert={showNewAlert} setShowNewAlert={setShowNewAlert} alertSelected={alertSelected} setAlertSelected={setAlertSelected} setEditAlert={setEditAlert} editAlert={editAlert} />
            </div>
        </Drawer>
    );
}

const mapStateToProps = (state) => ({
    language: state.shared.language,
    showBlackScreenDrawer: state.shared.showBlackScreenDrawer,
    alerts: state.alerts.alerts,
    loading: state.alerts.loading,
    showDrawerAlerts: state.alerts.showDrawerAlerts,
    newAlert: state.shared.startOnboarding.new_alert,
    showPulseAnimation: state.shared.showPulseAnimation,
    activeServicesEnergy: state.services.activeServicesEnergy  
});

const mapDispatchToProps = dispatch => ({
    loadAlertsAsync: () => dispatch(alertsActions.loadAlertsAsync()),
    deleteAlertAsync: (payload) => dispatch(alertsActions.deleteAlertAsync(payload)),
    setShowDrawerAlerts: (payload) => dispatch(alertsActions.setShowDrawerAlerts(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);