import React from 'react';
import step1 from '../../../imgs/onboarding/step1-invoice.png';
import step1En from '../../../imgs/onboarding/english/step1-invoice-en.png';
import step2 from '../../../imgs/onboarding/step2-invoice.png';
import step2En from '../../../imgs/onboarding/english/step2-invoice-en.png';

export const stepsInvoice = (language) => {
    return ([
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 124, width: 300 }}>
                    <img src={language.language_locale === 'es'? step1 : step1En} className='w-100 h-100' alt="step1-invoice" />
                </div>,
            title: language.onboarding_invoice_title_step1,
            description: language.onboarding_invoice_description_step1,
            placement: "right",
            target: "#test",
            disableBeacon: true,
            steps: 2
        },
        {
            content:
                <div className='row-center align-items-center w-100 mt-3' style={{ height: 126, width: 300 }}>
                    <img src={language.language_locale === 'es'? step2 : step2En} className='w-100 h-100' alt="step2-invoice" />
                </div>,
            title: language.onboarding_invoice_title_step2,
            description: language.onboarding_invoice_description_step2,
            placement: "left-start",
            target: "#test_1",
            disableBeacon: true,
            steps: 2
        },

    ])
};