import React from "react"
import { connect } from "react-redux"

import * as geniusActions from "../../store/actions/Genius"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { fal } from "@fortawesome/pro-light-svg-icons"

const ActiveReports = (props) => {
  let activeReports = props.activeReports
  let expanded = props.expanded
  let reportDetailed = props.reportDetailed
  let newReport = props.newReport
  let language = props.language
  let showActiveReports = props.showActiveReports

  const scrollToReport = props.scrollToReport
  const setExpanded = props.setShowContentHeader
  const setActiveReports = props.setActiveReports
  const setNewReport = props.setNewReport
  const setReportDetailed = props.setReportDetailed
  const setSelectedTypeOfReport = props.setSelectedTypeOfReport

  return (
    <div className="row-start scrollspy gap-2">
      {props.totalReports.length && activeReports.length ?
        activeReports.slice(0, showActiveReports).map((item) => (
          <div
            href={`#r${item?.id_report}`}
            key={item.id_report}
            style={{ height: 36 }}
            className={`list-group-item list-group-item-action row-between align-items-center rounded-pill eye-transition px-2 py-1 gap-3 ${newReport ? "pe-default" : "pe-pointer"}`}
            onClick={() => {
              if (newReport) {
                return
              } else if (!expanded) {
                setExpanded(true)
                setReportDetailed(item)
                scrollToReport(item.id_report)
              } else if (expanded && reportDetailed?.id_report !== item.id_report) {
                setReportDetailed(item)
                scrollToReport(item.id_report)
              } else if (expanded && reportDetailed?.id_report === item.id_report) {
                setExpanded(false)
              }
            }}>
            <i className={`fal eye-transition txt-gray-700 txt-8 zindex-5 ${expanded && reportDetailed?.id_report === item.id_report ? "fa-eye" : "fa-circle-chevron-down"}`}></i>
            <div className="txt-phrase txt-6 text-nowrap">{item.file_name && item.file_name.split(".")[0]}</div>
            {activeReports.length > 1 && (
              <FontAwesomeIcon
                icon={fal["faCircleXmark"]}
                className="txt-8 txt-gray-700 ms-3 zindex-5"
                onClick={(e) => {
                  e.stopPropagation()
                  setActiveReports(activeReports.filter((i) => i.id_report !== item.id_report))
                  if (expanded && reportDetailed?.id_report === item.id_report) {
                    setExpanded(false)
                  }
                }}
              />
            )}
          </div>
        )) :
        !newReport &&
        <div className="row-between align-items-center bg-gray-450 rounded-pill px-2 py-1">
          <p className="txt-phrase txt-gray-700 txt-6 mx-2">{language.genius_no_reports}</p>
        </div>}
      {props.newReport &&
        <div className="row-between align-items-center rounded-pill gap-3 px-2 py-1 txt-white border-1 border-color-gray-700 bg-gray-450">
          <div className="txt-phrase txt-6 text-nowrap">{language.genius_new_report}</div>
          <i className="fa-light fa-circle-xmark txt-8 txt-gray-700 pe-pointer zindex-5"
            onClick={() => {
              setNewReport(false)
              setExpanded(false)
              setSelectedTypeOfReport([])
            }}></i>
        </div>
      }
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  language: state.shared.language,
  activeReports: state.genius.activeReports,
  expanded: state.genius.expanded,
  totalReports: state.genius.totalReports,
  newReport: state.genius.newReport,
  reportDetailed: ownProps.reportDetailed,
  showActiveReports: ownProps.showActiveReports,
  setReportDetailed: ownProps.setReportDetailed,
  scrollToReport: ownProps.scrollToReport,
  setSelectedTypeOfReport: ownProps.setSelectedTypeOfReport
})

const mapDispatchToProps = (dispatch) => ({
  setActiveReports: (payload) => dispatch(geniusActions.setActiveReports(payload)),
  setNewReport: (payload) => dispatch(geniusActions.setNewReport(payload)),
  setShowContentHeader: (payload) => dispatch(geniusActions.setShowContentHeader(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ActiveReports)
