import React from 'react'
import { connect } from 'react-redux';

import { format } from 'date-fns';
import esLocale from 'date-fns/locale/es';
import enLocale from "date-fns/locale/en-US"

import { Link } from 'react-router-dom';

import empty_card_bill from "../../../imgs/empty-card-bill.svg";

const Invoice = (props) => {

    let locale = props.language.language_locale === "es" ? esLocale : enLocale

    return (
        <div className="position-relative column-center align-items-center bg-green-blue-gradient h-100 rounded-16" style={{ width: 350 }}>
            {
                props.invoiceInfo ?
                    <Link to="/invoices" className="positon-absolute column-between column-start w-100 h-100 p-3">
                        <div className="row-between align-items-center">
                            <div className="row-start align-items-center pe-pointer gap-2">
                                <i className="fa-solid fa-file-invoice-dollar txt-10 txt-green-500 pe-pointer"></i>
                                <p className="txt-phrase txt-green-500 txt-6 pe-pointer">{props.language.energy_invoice_show_bills}</p>
                            </div>
                            <p className="txt-phrase txt-bold txt-blue-500 txt-6 pe-pointer text-capitalize">{format(new Date(props.invoiceInfo.year, props.invoiceInfo.month - 1, 1), "MMMM yyyy", { locale: locale })}</p>
                        </div>
                        <div className="row-between align-items-center">
                            <p className="txt-title txt-bold txt-green-500 txt-12 pe-pointer">{props.invoiceInfo.total_payment}</p>
                            {
                                props.invoiceInfo.state ?
                                    <div className="row-end align-items-end">
                                        <i className="fa-light fa-check-double txt-12 txt-green-500"></i>
                                        <p className="txt-phrase txt-semibold txt-8 txt-green-500 ps-3">{props.language.energy_invoice_state_paid}</p>
                                    </div> :
                                    <div className="row-start align-items-center bg-green-500 rounded-8 py-2 px-3 pe-pointer">
                                        <i className="fa-solid fa-wallet txt-10 txt-neutral-800 pe-pointer"></i>
                                        <p className="txt-phrase txt-bold txt-8 txt-neutral-800 ps-3 pe-pointer">{props.language.energy_invoice_state_to_pay}</p>
                                    </div>
                            }
                        </div>
                    </Link> :
                    <div className="w-100 h-100 px-3 py-2 column-center align-items-center bg-gray-500 rounded-16">
                        <img src={empty_card_bill} alt="empty_card_bill" style={{ width: 105, height: 63 }} />
                        <div className="txt-gray-600 txt-phrase txt-semibold txt-8 text-wrap mt-2">{props.language.energy_invoice_no_bills}</div>
                    </div>
            }
        </div>)
}

const mapStateToProps = (state) => ({
    invoiceInfo: state.energy.invoiceInfo,
    language: state.shared.language
});

export default connect(mapStateToProps)(Invoice);