import React, { useState } from "react";
import { connect } from 'react-redux';

import * as sharedActions from '../../store/actions/Shared';
import * as notificationsActions from '../../store/actions/Notifications';

import { Popover } from "@idui/react-popover";

import { track } from "@amplitude/analytics-browser";

const PopoverNotifications = (props) => {  

  let notification = props.notification;

  const [focus, setFocus] = useState(false);  
  const [show, setShow] = useState(false);  

  const handlerClose = (state) => {
    setShow(state);
    props.setShowBlackScreenDrawer(state);
    setFocus(state); 
    show && track('Notification opened', {Notification_ID: notification?.category})
  }

  return (
    <Popover
      lazy
      closeOnEnter
      fitMaxHeightToBounds
      fitMaxWidthToBounds
      trigger={'click'}
      placement={'bottomRight'}      
      className={'p-0 bg-gray-500'}
      isOpen={show}
      width={200}
      zIndex={10000}      
      offset={[0, 0]}
      openingAnimationTranslateDistance={0}
      animation={{
        animate: { opacity: 1 },
        exit: { opacity: 0, transition: { duration: 0.1 } },
        initial: { opacity: 0 }
      }}
      onChangeOpen={(state) => {handlerClose(state)}}
      withArrow={false} 
      content={
        <div className="column-start w-100 p-2">
          <div className="row-center align-items-center p-2 bg-gray-800-hover rounded-8 pe-pointer"
            onClick={() => {
              handlerClose(false);
              props.updateNotificationAsync({
                id: notification.id,
                read: !notification.read
              })
            }}>
            <div className="col-3 row-center">
              <i className="fa-light fa-check-double txt-8 txt-green-500"></i>
            </div>
            <p className="col-9 txt-phrase txt-6 txt-neutral-50">{notification.read ? props.language.notifications_notification_set_unread : props.language.notifications_notification_set_read}</p>            
          </div>
          <div className="row-center align-items-center p-2 bg-gray-800-hover rounded-8 pe-pointer mt-2" 
            onClick={() => {
              handlerClose(false);
              props.setVisualization(false);
              props.deleteNotificationAsync(notification.id)
            }}>
            <div className="col-3 row-center">
              <i className="fa-light fa-trash txt-8 txt-red-500"></i>
            </div>
            <p className="col-9 txt-phrase txt-6 txt-neutral-50">{props.language.notifications_notification_delete}</p>            
          </div>
        </div>
      }>
      <div className="row-center align-items-center w-100">
        <i className={`fa-light fa-ellipsis-vertical txt-10 txt-white pe-pointer ${focus && "zindex-5"}`}></i>
      </div>
    </Popover>
  );
};

const mapStateToProps = (state, ownProps) => ({  
  language: state.shared.language,
  notification: ownProps.notification,
  setVisualization: ownProps.setVisualization
});

const mapDispatchToProps = dispatch => ({  
  setShowBlackScreenDrawer: (payload) => dispatch(sharedActions.setShowBlackScreenDrawer(payload)),
  deleteNotificationAsync: (payload) => dispatch(notificationsActions.deleteNotificationAsync(payload)),
  updateNotificationAsync: (payload) => dispatch(notificationsActions.updateNotificationAsync(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(PopoverNotifications);