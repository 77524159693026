import React, {useState, useEffect} from "react";
import { connect } from 'react-redux';

import { VictoryChart, VictoryBar, VictoryAxis, VictoryLabel, VictoryGroup, VictoryZoomContainer } from "victory";

import _ from 'lodash';

import empty_data_tag from "../../../../imgs/empty-data-tag.svg";


const axisStyle = {
    axis: {
        stroke: "transparent",
        strokeOpacity: 0.2,
        fontFamily: "Inter"
    },    
    ticks: {
        stroke: "transparent",
        size: 5
    },
    tickLabels: {
        fontSize: 12,
        padding: 5,
        fill: "#F9FAFB"
    }
}


const GraphPower = (props) => {
    
    const tag = props.data.length > 0 && props.data.filter(x => x.processed).length > 0;  
    const [grabbing, setGrabbing] = useState(false);  
    const [domain, setDomain] = useState(null);
    const [entireDomain, setEntireDomain] = useState(null);

    useEffect(() => {
        if(props.data.length){
            const maxValueY = _.maxBy(props.data, d => d?.active_power)?.active_power * 1.1;
            const intervalY = [0, maxValueY];
            const intervalX = [1, props.data.length + 1];
            setEntireDomain({x: intervalX, y: intervalY});
            setDomain({x: [props.data.length - 3 < 1 ? 0 : props.data.length - 3, props.data.length + 1], y: intervalY});
        }
    }, [props.data])

    return (
        <div className="ps-4 w-100">
            {!props.loadingData && (
                <div className="column-start align-items-start">                                      
                    { tag ?               
                        <div className="row-center align-items-center w-100 overflow-hidden">                            
                            <div className="column-start align-items-start ps-5 pt-5">
                                <div className="row-start align-items-center">                                    
                                    <div className="row-center align-items-center bg-gray-800 rounded-30 p-2 me-2">
                                        <i className="fa-solid fa-circle txt-6 me-2 txt-green-500"></i>
                                        <div className="txt-white txt-phrase txt-6 txt-regular">{props.language.tag_graph_power_legend_active_power}</div>
                                    </div>
                                    <div className="row-center align-items-center bg-gray-800 rounded-30 p-2 me-2">
                                        <i className="fa-solid fa-circle txt-6 me-2 txt-green-550"></i>
                                        <div className="txt-white txt-phrase txt-6 txt-regular">{props.language.tag_graph_power_legend_reactive_power}</div>
                                    </div>
                                </div>
                                <div className={`${grabbing ? "pe-grabbing" : "pe-grab"}`} style={{ width: props.graphContinerWidth }} onMouseDown={() => setGrabbing(true)} onMouseUp={() => setGrabbing(false)}>
                                    <VictoryChart
                                        animate={false}
                                        height={400}
                                        width={props.graphContinerWidth}                                        
                                        domain={entireDomain} 
                                        containerComponent={
                                            <VictoryZoomContainer 
                                                allowPan={props.data.length > 3}
                                                allowDrag={false}
                                                allowDraw={false}
                                                allowResize={false}
                                                allowZoom={false}
                                                zoomDimension={"x"}
                                                zoomDomain={domain} 
                                                onZoomDomainChange={(domainZoom) => {
                                                    if(props.data?.length > 3){
                                                        setDomain({...domain, x: domainZoom.x});
                                                    }
                                                }}                                           
                                            />
                                        }                                                                                         
                                    >
                                        <VictoryGroup                                            
                                            offset={80}
                                            colorScale={["#CEFC34", "#A1C52A"]}
                                        >
                                            <VictoryBar 
                                                key={1}
                                                x={"id_tag"} 
                                                y={"active_power"} 
                                                data={props.data.filter(item => item?.id_tag >= domain?.x[0] && item?.id_tag <= domain?.x[1])}
                                                barRatio={1}                                               
                                                barWidth={70}                                               
                                                cornerRadius={{ topRight: 8, topLeft: 8 }}                                                 
                                                labels={({ datum }) => `${datum?.active_power?.toFixed(2)} W`}
                                                labelComponent={<VictoryLabel dx={0} style={{ fill: '#f9fafb', fontSize: 12, fontFamily: "Inter", fontWeight: 900 }} angle={0} />} 
                                            />
                                            <VictoryBar 
                                                key={2}
                                                x={"id_tag"} 
                                                y={"reactive_power"} 
                                                data={props.data.filter(item => item?.id_tag >= domain?.x[0] && item?.id_tag <= domain?.x[1])}
                                                barRatio={1}                                               
                                                barWidth={70}                                              
                                                cornerRadius={{ topRight: 8, topLeft: 8 }}                                                 
                                                labels={({ datum }) => `${datum?.reactive_power?.toFixed(2)} VAr`}
                                                labelComponent={<VictoryLabel dx={0} style={{ fill: '#f9fafb', fontSize: 12, fontFamily: "Inter", fontWeight: 900 }} angle={0} />} 
                                            />
                                        </VictoryGroup>                  
                                        <VictoryAxis tickValues={props.data.filter(item => item?.id_tag >= domain?.x[0] && item?.id_tag <= domain?.x[1]).map(item => item?.id_tag)} style={axisStyle} tickFormat={(x) => `Tag ${x}`} tickCount={props.data.length}/>
                                        <VictoryAxis dependentAxis style={axisStyle} />
                                    </VictoryChart>
                                </div>
                            </div>
                        </div> : 
                        <div className="column-center align-items-center w-100 mt-3" style={{height: 500}}>                            
                            <img src={empty_data_tag} alt="empty_data_tag" style={{ width: 270, height: 190 }} />
                            <div className="txt-gray-600 txt-phrase txt-12 txt-bold w-25 text-center mt-3">{props.language.tag_graph_power_title_empty_data}</div> 
                            <div className="txt-gray-600 txt-phrase txt-10 txt-regular w-50 text-wrap text-center">{props.language.tag_graph_power_phrase_empty_data}</div>                                
                        </div>
                    }                   
                </div>)}
        </div>
    )
}

const mapStateToProps = (state) => ({    
    language: state.shared.language
});

export default connect(mapStateToProps)(GraphPower);