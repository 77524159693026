import React, { useRef, useEffect } from 'react'
import { connect } from 'react-redux';

import Header from "../shared/Header";
import { track } from '@amplitude/analytics-browser';

const Efficiency = (props) => {

    let user = props.user;
    let account = props.account;
    let screenPaddingBottom = props.screenPaddingBottom;

    const urlFrame = process.env.REACT_APP_EFFICIENCY_HOST;

    const frameEfficiency = useRef(null)

    useEffect(() => {

        if (account !== null) {

            frameEfficiency.current.addEventListener('load', () => {

                let window = frameEfficiency.current.contentWindow;

                let token = localStorage.getItem('token');

                setTimeout(() => {
                    window.postMessage(JSON.stringify({
                        user: user.idUser,
                        email: user.email,
                        account: account.idAccount,
                        token_key: 'token',
                        token_value: token,
                        screenPaddingBottom
                    }), urlFrame)
                }, [100]);

            });
            track('Efficiency viewed')
        }
        // eslint-disable-next-line
    }, [user, account, urlFrame]);

    useEffect(() => {

        let window = frameEfficiency.current.contentWindow;

        window.postMessage(JSON.stringify({
            screenPaddingBottom
        }
        ), urlFrame);

    }, [screenPaddingBottom, urlFrame])

    return (
        <div className="position-relative w-100 vh-100">
            <div className="position-absolute w-100 left-0 top-0 border-bottom-1 border-color-gray-800 zindex-2" style={{ height: 74 }}>
                <Header moduleName={props.language.menu_efficiency_service} backToButton={false} />
            </div>
            <iframe key={account?.idAccount} ref={frameEfficiency} title="efficiency-section" className="position-absolute w-100 h-100 left-0 top-0" style={{ paddingTop: 74 }} src={`${urlFrame}/home`}></iframe>
        </div>
    );

}

const mapStateToProps = (state) => ({
    user: state.users.user,
    account: state.accounts.account,
    language: state.shared.language,
    screenPaddingBottom: state.shared.screenPaddingBottom
});

export default connect(mapStateToProps)(Efficiency)