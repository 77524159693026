import React from 'react'
import { connect } from 'react-redux';

const Picker = ({ label, value, onChange, language }) => {
    return (
        <div className="column-star align-items-start">
            <label className="txt-gray-400 txt-phrase txt-6 mb-2">{label}</label>
            <div className="row-start align-items-center">
                <div className={`column-center align-items-center py-2 border-color-white border-2 rounded-10 pe-pointer ${value && "bg-white" }`} style={{width:80}} onClick={() => onChange(true) }>
                    <div className={`txt-phrase txt-semibold ${value ? "txt-black" : "txt-white"} txt-8 c-pointer`}>{language.picker_yes_button}</div>
                </div>
                <div className={`column-center align-items-center ms-2 py-2 border-color-white border-2 rounded-10 pe-pointer ${!value && "bg-white" }`} style={{width:80}} onClick={() => onChange(false)}>
                    <div className={`txt-phrase txt-semibold ${!value ? "txt-black" : "txt-white"} txt-8 c-pointer`}>{language.picker_no_button}</div>
                </div>
            </div>
        </div>);
}

const mapStateToProps = (state) => ({
    language: state.shared.language,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(Picker);