import React, { useState } from "react";
import { connect } from 'react-redux';

import * as sharedActions from '../../store/actions/Shared';

import { Popover } from "@idui/react-popover";

const PopoverAlerts = (props) => {  

  const [focus, setFocus] = useState(false);  
  const [show, setShow] = useState(false); 

  const handlerClose = (state) => {
    setShow(state);
    props.setShowBlackScreenDrawer(state);
    setFocus(state); 
  }

  return (
    <Popover
      lazy
      closeOnEnter
      fitMaxHeightToBounds
      fitMaxWidthToBounds
      trigger={'click'}
      placement={'bottomRight'}      
      className={'p-0 bg-gray-500'}
      isOpen={show}
      width={200}
      zIndex={10000}      
      offset={[0, 0]}
      openingAnimationTranslateDistance={0}
      animation={{
        animate: { opacity: 1 },
        exit: { opacity: 0, transition: { duration: 0.1 } },
        initial: { opacity: 0 }
      }}
      onChangeOpen={(state) => handlerClose(state)}
      withArrow={false} 
      content={
        <div className="column-start w-100 p-3">
          <div className="row-start align-items-center w-100 pe-pointer p-2 bg-gray-800-hover rounded-8" onClick={() => {props.onChangeEdit(true); handlerClose(false); }}>
            <i className="fal fa-pen-to-square txt-8 txt-green-500 me-2"></i>
            <p className="txt-phrase txt-medium txt-neutral-50 txt-6 pe-pointer">{props.language.alerts_label_title_edit_alert}</p>
          </div>
          <div className="row-start align-items-center w-100 pe-pointer my-4 p-2 bg-gray-800-hover rounded-8" onClick={() => {props.onChangeDuplicate(true); handlerClose(false); }}>
            <i className="fal fa-clone txt-8 txt-blue-500 me-2"></i>
            <p className="txt-phrase txt-medium txt-neutral-50 txt-6 pe-pointer">{props.language.popover_duplicate_alert}</p>
          </div>
          <div className="row-start align-items-center w-100 pe-pointer p-2 bg-gray-800-hover rounded-8" onClick={() => {props.setOpenDeleteModal(true); handlerClose(false);}}>
            <i className="fal fa-trash txt-8 txt-red-500 me-2"></i>
            <p className="txt-phrase txt-medium txt-neutral-50 txt-6 pe-pointer">{props.language.popover_delete_alert}</p>
          </div>
        </div>
      }>
      <div className="row-center align-items-center w-100">
        <i className={`fa-light fa-ellipsis-vertical txt-10 txt-green-500 pe-pointer ${focus && "zindex-5"}`}></i>
      </div>
    </Popover>
  );
};

const mapStateToProps = (state) => ({  
  language: state.shared.language  
});

const mapDispatchToProps = dispatch => ({  
  setShowBlackScreenDrawer: (payload) => dispatch(sharedActions.setShowBlackScreenDrawer(payload))  
});

export default connect(mapStateToProps, mapDispatchToProps)(PopoverAlerts);