import React, { Fragment, useState, useEffect } from "react";
import { connect } from 'react-redux';

import * as loginActions from '../../store/actions/Login';

import { Redirect } from "react-router-dom";

import { Transition } from 'react-transition-group';

import { Loader, Alert } from 'rsuite';

import logo from '../../imgs/logo-enrg-white.svg';

import { getAnalytics, logEvent } from "firebase/analytics";

import InputRounded from "../shared/utilities/InputRounded";

import AuthCode from 'react-auth-code-input';

const analytics = getAnalytics();

const showAlert = message => {
  Alert.error(message);
}

const Login = (props) => {

  let step = props.step;

  const loadInitInfoAsync = props.loadInitInfoAsync;

  const [inputValue, setInputValue] = useState("");
  const [data, setData] = useState("");

  const setInput = ((input) => {

    let value = input ? input : inputValue;

    if (step === 2) {

      props.loadInfoUserAsync(value, showAlert);
      setInputValue("");

    } else {

      props.generateCodeAsync(value, showAlert);

      setData(value);
      setInputValue("");

    }
  });

  useEffect(() => loadInitInfoAsync(), [loadInitInfoAsync]);

  useEffect(() => logEvent(analytics, "login"), []);  

  return (
    <section className="bg-black vh-100 w-100">
      <Transition
        in={props.loading}
        timeout={100}>
        {state => (
          <div className={`trans-fade trans-fade-${state} position-absolute w-100 h-100 top-0 left-0 zindex-1`}>
            <div className="column-center align-items-center w-100 h-100">
              <Loader inverse center size="md" content={props.language.loader_text_content} />
            </div>
          </div>
        )}
      </Transition>
      {step === 3 ?
        <Redirect to="/home" /> :
        (step === 1 || step === 2) && !props.loading &&
        <div className="container column-between align-items-center h-100 py-5">
          <div className="column-start align-items-center">
            <img src={logo} className="mb-4" alt="logo-neu-energy" style={{ width: 120 }} />
            <h1 className="txt-title txt-16 txt-white text-center txt-bold text-wrap">{step === 2 ? props.language.login_title_step_two : props.language.login_main_title}</h1>
          </div>
          <div className="column-center align-items-center col-8 flex-grow-1">
            <div className="col-6 column-center align-items-start">
              {step === 2 ?
                <Fragment>
                  <p className="txt-phrase txt-gray-300 txt-8 py-2">{props.language.login_input_label_code} {data}</p>
                  <div className="row-center align-items-center custom-verification-code bg-gray-500 rounded-pill py-4 px-5">
                    <AuthCode 
                      autoFocus={true} 
                      containerClassName='row-between gap-4 ps-3' 
                      inputClassName="login-input-code bg-transparent txt-20 text-white txt-bold" 
                      placeholder="-" 
                      allowedCharacters='numeric' 
                      onChange={(value) => {
                        if (value.length === 6){
                          setInputValue(value);
                          setInput(value);
                        }
                      }} />
                  </div>
                  <div className="row-between align-items-center gap-2 my-2">
                    <p className="txt-phrase txt-gray-600 txt-8">{props.language.login_question_code}</p>
                    {props.resending ?
                      <p className="txt-phrase txt-gray-200 txt-8 m-0">{props.language.login_await_resend_code} {props.counter} {props.language.login_unit_await_resend_code}</p> :
                      <p className="txt-phrase txt-gray-200 txt-8 pe-pointer m-0" onClick={() => props.generateCodeAsync(data, showAlert)}><ins>{props.language.login_resend_code}</ins></p>
                    }
                  </div>
                </Fragment> :
                <Fragment>
                  <p className="txt-phrase text-center txt-gray-300 txt-10 pb-2">{props.language.login_title_description}</p>
                  <InputRounded
                    placeholder={props.language.login_input_label_data}
                    icon='user'
                    type='text'
                    focus={true}
                    onChange={({ target }) => setInputValue(target.value.trimStart().trimEnd())}
                    onPressEnter={() => setInput()} />
                </Fragment>}
            </div>
          </div>
          <button className="row-center align-items-center txt-phrase txt-regular txt-10 bg-gray-500 txt-white py-2 px-4 border border-color-gray-800 rounded-pill" onClick={() => setInput()}>{props.language.login_button_enter}
            <i className="fa-regular fa-chevron-right txt-gray-400 txt-12 ms-3"></i>
          </button>
        </div>
      }
    </section>);
};

const mapStateToProps = (state) => ({
  step: state.login.step,
  loading: state.login.loading,
  resending: state.login.resending,
  counter: state.login.counter,
  language: state.shared.language
})

const mapDispatchToProps = dispatch => ({
  generateCodeAsync: (payload, showAlert) => dispatch(loginActions.generateCodeAsync(payload, showAlert)),
  loadInfoUserAsync: (payload, showAlert) => dispatch(loginActions.loadInfoUserAsync(payload, showAlert)),
  loadInitInfoAsync: () => dispatch(loginActions.loadInitInfoAsync())
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
