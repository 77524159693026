import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import * as paysActions from '../../../store/actions/Pays';

import { Alert } from 'rsuite';

import cardTag from '../../../imgs/card-tag.svg';

import Input from '../../shared/utilities/Input';
import Select from '../../shared/utilities/Select';
import TotalPayment from './TotalPayment';

const isValidateString = val => {
    return /^(?=.*[a-zA-Zñíóáéú])[ñíóáéú a-zA-Z]+$/.test(val);
}

const isValidUser = data => {

    if (data.phone === null || data.phone === "") {
        return false;
    }

    if (data.address === null || data.address === "") {
        return false;
    }

    if (data.id_document === null || data.id_document === "") {
        return false;
    }

    if (data.card_name === null || data.card_name === "" || !isValidateString(data.card_name)) {
        return false;
    }

    if (data.card_number === null || data.card_number === "") {
        return false;
    }

    if (data.card_expiration_month === null || data.card_expiration_month === "") {
        return false;
    }

    if (data.card_expiration_year === null || data.card_expiration_year === "") {
        return false;
    }

    if (data.card_cvv === null || data.card_cvv === "") {
        return false;
    }

    return true;

}

const showError = (data, props) => {

    if (data.address === null || data.address === "") {
        Alert.error(props.language.alert_user_popup_address);
        return;
    }

    if (data.phone === null || data.phone === "") {
        Alert.error(props.language.alert_user_popup_phone);
        return;
    }

    if (data.id_document === null || data.id_document === "") {
        Alert.error(props.language.alert_user_popup_id_document);
        return;
    }

    if (data.card_name === null || data.card_name === "" || !isValidateString(data.card_name)) {
        Alert.error(props.language.alert_pay_popup_card_name);
        return;
    }

    if (data.card_number === null || data.card_number === "") {
        Alert.error(props.language.alert_pay_popup_card_number);
        return;
    }

    if (data.card_expiration_month === null || data.card_expiration_month === "") {
        Alert.error(props.language.alert_pay_popup_card_expiration_month);
        return;
    }

    if (data.card_expiration_year === null || data.card_expiration_year === "") {
        Alert.error(props.language.alert_pay_popup_card_expiration_year);
        return;
    }

    if (data.card_cvv === null || data.card_cvv === "") {
        Alert.error(props.language.alert_pay_popup_card_cvv);
        return;
    }

}

const separarTexto = (texto) => {
    const expresionRegular = new RegExp('.{1,4}', 'g');
    const grupos = texto.match(expresionRegular);
    return grupos ? grupos.join(' ') : '';
}

let fees = Array.from({ length: 36 }, (e, i) => { return { value: i + 1, label: i + 1 } });

const CardPayment = (props) => {

    let account = props.account;
    let infoPayment = props.infoPayment;
    let invoicesToPay = props.invoicesToPay;

    const setInfoPayment = props.setInfoPayment;
    const setCardPaymentAsync = props.setCardPaymentAsync;

    const initInfo = {
        id_user: props.user.idUser,
        id_external_user: 0,
        id_account: account?.idAccount,
        name: props.user.name,
        lastname: props.user.lastname,
        email: props.user.email,
        phone: props.user.phone,
        address: props.user.address,
        id_document: "",
        id_bills: [],
        card_name: "",
        card_number: "",
        card_expiration_year: "",
        card_expiration_month: "",
        card_cvv: "",
        payments: 1,
        neugets: 0,
        commission: 0.007,
        total_payment: invoicesToPay.reduce((a, { total_payment }) => a + parseFloat(total_payment), 0),
    }

    const [isEnable, setEnable] = useState(false);
    const [defaultInfo, setDefaultInfo] = useState(initInfo);

    useEffect(() => {
        if (defaultInfo != null) {
            setInfoPayment(defaultInfo)
            setDefaultInfo(null)
        }
    }, [defaultInfo, setInfoPayment]);

    useEffect(() => setEnable(isValidUser(infoPayment)), [setEnable, infoPayment]);

    return (
        <div className='column-between align-items-start mt-4 h-100 w-100'>
            <div className='column-start'>
                <div className='row-center w-100'>
                    <div className='column-between min-width-280 max-width-280 min-height-150 bg-gray-transparent-gradient-diagonal p-3 rounded-16 border-1 border-color-white'>
                        <div className='row-between align-item-center w-100'>
                            <div className='txt-title txt-semibold txt-8 txt-white'>{props.language.invoices_card_payment_bank}</div>
                            <img src={cardTag} alt="card-tag" style={{ width: 18 }} />
                        </div>
                        <div className='row-between align-items-end w-100'>
                            <div className='col-8 column-start align-items-start overflow-hidden'>
                                <div className='col-12 row-start align-items-center pb-1'>
                                    <div className='col-8 txt-phrase txt-medium txt-6 txt-white me-3'>{infoPayment.card_name === '' ? `${props.language.invoices_card_payment_cardholder}` : `${infoPayment.card_name}`.toUpperCase()}</div>
                                    <div className='col-4 txt-phrase txt-medium txt-6 txt-white'>{infoPayment.card_expiration_month === '' ? 'MM' : infoPayment.card_expiration_month}/{infoPayment.card_expiration_year === '' ? 'YY' : infoPayment.card_expiration_year}</div>
                                </div>
                                <div className='txt-phrase txt-medium txt-8 txt-white'>{separarTexto(infoPayment.card_number === '' ? 'XXXXXXXXXXXXXXXX' : `${infoPayment.card_number}`)}</div>
                            </div>
                            <div className='col-2 row-center align-items-center px-2 py-1 bg-gray-600 rounded-4'>
                                <i className='fa-duotone fa-credit-card txt-gray-50 txt-12 pe-pointer'></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='txt-phrase txt-semibold txt-8 txt-gray-95 my-3'>{props.language.invoices_card_payment_cardholder_name_data}</div>
                <Input
                    value={infoPayment.card_name ?? ""}
                    label={props.language.invoices_card_payment_cardholder_name}
                    type={'text'}
                    onChange={({ target }) => {
                        setInfoPayment({ ...infoPayment, card_name: target.value })
                    }}
                />
                <div className='txt-phrase txt-regular txt-6 txt-white mt-1 mb-2'>{props.language.invoices_card_payment_equal_cardholder_name}</div>
                <div className='row-between align-items-center gap-2'>
                    <Input
                        label={props.language.invoices_card_payment_cardholder_document}
                        value={infoPayment.id_document ?? ""}
                        type={'text'}
                        onChange={({ target }) => {
                            setInfoPayment({ ...infoPayment, id_document: target.value })
                        }}
                    />
                    <Input
                        label={props.language.invoices_card_payment_cardholder_address}
                        value={infoPayment.address ?? ""}
                        type={'text'}
                        onChange={({ target }) => {
                            setInfoPayment({ ...infoPayment, address: target.value })
                        }}
                    />
                </div>
                <div className='txt-phrase txt-semibold txt-8 txt-gray-95 my-3'>{props.language.invoices_card_payment_data}</div>
                <Input
                    label={props.language.invoices_card_payment_number}
                    value={infoPayment.card_number ?? ""}
                    type={'number'}
                    onChange={({ target }) => {
                        target.value.length <= 16 && setInfoPayment({ ...infoPayment, card_number: target.value })
                    }}
                />
                <div className='row-start align-items-center my-2'>
                    <div className="col-6 pe-1 text-nowrap">
                        <Input
                            label='MM'
                            value={infoPayment.card_expiration_month ?? ""}
                            type={'number'}
                            onChange={({ target }) => {
                                target.value < 12 && setInfoPayment({ ...infoPayment, card_expiration_month: target.value })
                            }}
                        />
                    </div>
                    <div className="col-6 ps-1 text-nowrap">
                        <Input
                            label='AA'
                            value={infoPayment.card_expiration_year ?? ""}
                            type={'number'}
                            onChange={({ target }) => {
                                target.value.length <= 2 && setInfoPayment({ ...infoPayment, card_expiration_year: target.value })
                            }}
                        />
                    </div>
                </div>
                <Input
                    label='CVC/CVV'
                    value={infoPayment.card_cvv ?? ""}
                    type={'number'}
                    onChange={({ target }) => {
                        target.value.length <= 4 && setInfoPayment({ ...infoPayment, card_cvv: target.value })
                    }}
                />
                <div className='col-12 my-2'>
                    <Select
                        label={props.language.invoices_card_payment_instalments}
                        background='gray-500'
                        options={fees}
                        value={infoPayment.payments}
                        onChange={({ target }) => {
                            setInfoPayment({ ...infoPayment, payments: target.value });
                        }}
                    />
                </div>
            </div>
            <TotalPayment isEnable={isEnable} payAction={() => isEnable ? setCardPaymentAsync() : showError(infoPayment, props)} />
        </div>
    )
}

const mapStateToProps = (state) => ({
    infoPayment: state.pays.infoPayment,
    account: state.accounts.account,
    user: state.users.user,
    language: state.shared.language,
    invoicesToPay: state.invoices.invoicesToPay
})

const mapDispatchToProps = dispatch => ({
    setInfoPayment: (payload) => dispatch(paysActions.setInfoPayment(payload)),
    setCardPaymentAsync: () => dispatch(paysActions.setCardPaymentAsync())
})

export default connect(mapStateToProps, mapDispatchToProps)(CardPayment)