import React, { useState } from "react"
import { DateRange } from "react-date-range"
import { es, enUS, pt } from "react-date-range/dist/locale"
import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file

import { Transition } from "react-transition-group"

import { startOfToday, startOfWeek, endOfWeek, isDate, isSameWeek } from "date-fns"

import { calendarIntervals, setCalendarDates, setCalendarDatesEnergy } from "../../../services/CalendarHandler"
import { Calendar } from "react-calendar"

const defaultRangeDate = [
  {
    id: 1,
    interval: 1,
    labelName: "energy_graph_calendar_week_range",
  },
  {
    id: 2,
    interval: 2,
    labelName: "energy_graph_calendar_month_range",
  },
]

const CalendarRangePicker = (props) => {
  const [previewDates, setPreviewDates] = useState({ startDate: null, endDate: null })

  let show = props.show
  let setShow = props.setShow
  let interval = props.interval
  let setDates = props.setDates
  let setIntervalGraph = props.setIntervalGraph
  let dateRangeEnergy = props.dateRangeEnergy
  let language = props.language

  return (
    <Transition in={show} timeout={50}>
      {(state) => (
        <>
          {interval < 2 ? (
            <div className={`position-absolute column-start align-items-center top-100 zindex-15 mt-2 trans-fade trans-fade-${state}`} style={{ right: -50 }}>
              <DateRange
                className="energy-calendar"
                locale={language.language_locale === "es" ? es : language.language_locale === "en" ? enUS : pt}
                rangeColors={["rgba(127, 127, 127, 0.8)", "rgba(194, 194, 194, 0.6)", "#ffffff"]}
                dateDisplayFormat="eee. dd, LLLL"
                onChange={(date) => {
                  setDates(setCalendarDatesEnergy(date.selection, interval))
                }}
                showSelectionPreview={false}
                ranges={dateRangeEnergy}
                minDate={new Date("2019-07-01T19:21:00.000Z")}
                maxDate={startOfToday()}
                showDateDisplay={false}
                showPreview={isDate(previewDates.startDate)}
                preview={{ startDate: previewDates.startDate, endDate: previewDates.endDate, color: "#cefc34" }}
                onPreviewChange={(previewDate) => {
                  if (previewDate === null) return
                  if (interval === 0) {
                    setPreviewDates({ startDate: previewDate, endDate: previewDate })
                  }
                  if (interval === 1) {
                    const startDate = startOfWeek(previewDate, { weekStartsOn: 1 })
                    const endDate = endOfWeek(previewDate, { weekStartsOn: 1 })
                    const previewDateObject = { startDate, endDate }
                    setPreviewDates(previewDateObject)
                  }
                }}
              />
              {interval === 1 && (
                <div id="calendar_fast_access" className="w-100 bg-gray-500 px-2 py-3">
                  <p className="txt-phrase txt-gray-700 txt-6 ps-2">{language.energy_graph_calendar_fast_access}</p>
                  <div className="row-start align-items-center gap-2 pt-2">
                    {defaultRangeDate.map((range) => {
                      const isSelected = range.interval === 1 && isSameWeek(startOfToday(), dateRangeEnergy[0].startDate, { weekStartsOn: 1 })
                      const lastDates = { startDate: startOfToday(), endDate: startOfToday() }

                      return (
                        <button
                          key={range.id}
                          className={`row-center align-items-center py-1 px-3 rounded-pill bg-gray-550 ${isSelected ? "border-1 border-color-gray-700 pe-default" : "pe-pointer opacity-5"}`}
                          disabled={isSelected}
                          onClick={() => {
                            setIntervalGraph(range.interval)
                            setDates(setCalendarDatesEnergy(lastDates, range.interval))
                          }}>
                          <p className={`txt-phrase txt-${isSelected ? "gray-700" : "white"} txt-6 text-nowrap`}>{language[range.labelName]}</p>
                        </button>
                      )
                    })}
                  </div>
                </div>
              )}
              <div className="d-flex bg-gray-500 w-full pb-3 pe-2 justify-content-end" style={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}>
                <button className="p-2 rounded-8 bg-green-500 txt-phrase txt-bold txt-8 txt-neutral-800" onClick={() => setShow(false)}>
                  {language.energy_graph_calendar_button}
                </button>
              </div>
            </div>
          ) : (
            <div className={`position-absolute column-start align-items-center top-80 -right-20 zindex-6 trans-fade trans-fade-${state}`} style={{ width: 300, right: -50 }}>
              <Calendar
                next2Label={null}
                prev2Label={null}
                value={dateRangeEnergy[0].startDate}
                minDetail={calendarIntervals[interval]}
                maxDetail={calendarIntervals[interval]}
                maxDate={new Date()}
                minDate={new Date("2019-07-01T19:21:00.000Z")}
                className={"bg-gray-500 txt-phrase txt-8 p-2 rounded-8"}
                nextLabel={<i className="fa-regular fa-chevron-right txt-8 txt-white pe-pointer"></i>}
                prevLabel={<i className="fa-regular fa-chevron-left txt-8 txt-white pe-pointer"></i>}
                onChange={(value) => {
                  setDates(setCalendarDates(value, interval))
                }}
              />
            </div>
          )}
        </>
      )}
    </Transition>
  )
}

export default CalendarRangePicker
