import React from 'react'

const Input = ({ label, value = "", type, required, disabled = false, onChange, onPressEnter, focus = false, error }) => {
    return (
        <div className='column-start w-100'>
            <div className="form-floating w-100">
                <input disabled={disabled} type={type} autoFocus={focus} className={`form-control border-1 border-color-${error ? 'red-500' : 'white'} bg-transparent txt-white txt-phrase txt-8`} id="floatingInput" placeholder="placeholder" required={required} value={value ?? ""} onChange={onChange} onKeyDown={({ keyCode }) => keyCode === 13 && onPressEnter && onPressEnter()} />
                <label htmlFor="floatingInput" className="txt-gray-700 txt-phrase">{label}</label>
            </div>
            {error ? <p className='txt-phrase txt-regular txt-6 txt-red-500 mt-1 w-100 text-wrap'>{error}</p> : null}
        </div>
    );
}

export default Input