import React, { useState } from 'react';
import { connect } from 'react-redux';

import * as sharedActions from '../../store/actions/Shared';

import 'react-toastify/dist/ReactToastify.css';
import { toast } from "react-toastify";

import RateNumber from '../shared/utilities/RateNumber';

const PopUpScore = (props) => {

    const initValue = {
        qualify: 8,
        comment: '',
        specific_reason: '',
        channel: 0            
    }

    const maxCount = 200;

    const [charCounter, setCharCounter] = useState(0);        
    const [surveyValue, setSurveyValue] = useState(initValue);        
    const [score, setScore] = useState(0);      
    const [firstSend, setFirstSend] = useState(false);
    const [service, setService] = useState(0);       
    
    const handleSend = () => {  
        if (firstSend) {
            if(surveyValue.qualify < 7) props.setNpsSurveyAsync({...surveyValue, type: service, label: false});
            if(surveyValue.qualify >= 7) props.setNpsSurveyAsync({...surveyValue, type: service, label: true});
            toast.dismiss()
        } else {
            setFirstSend(true)
        }                        
    }

    const inputHandler = (e) => {
        if (charCounter <= maxCount) {
            setCharCounter(e.target.value.length);                
        }
        setSurveyValue({...surveyValue, comment: e.target.value})
    };    
    
    const inputHandlerSpecific = (e) => {
        if (charCounter <= maxCount) {
            setCharCounter(e.target.value.length);                
        }
        setSurveyValue({...surveyValue, specific_reason: e.target.value})
    };
        
    return (
        <div className='w-100 align-items-center column-center' style={{paddingLeft: 12}}>
            {!firstSend && (
                <div className='w-100 align-items-center column-center'>
                    <div className='col-10 px-0 text-wrap text-center txt-phrase txt-10 txt-medium txt-green-500 my-3'>{props.language.shared_survey_nps_title}</div>
                    <div className='col-12 px-0 text-wrap text-center txt-8 txt-regular txt-white'>{props.language.shared_survey_nps_text_question}</div>                        
                    <div className='w-100 align-items-center column-center mt-3 bg-gray-500 px-3 pt-3 pb-2 rounded-8'>
                        <RateNumber max={10} score={score} onChange={value => {
                            setSurveyValue({...surveyValue, qualify: value})
                            setScore(value)
                        }}/>
                    </div>
                    <div className='col-12 px-0 text-wrap text-center txt-8 txt-regular txt-white mt-2'>{props.language.shared_survey_nps_text_comment}</div> 
                    <div className='w-100 position-relative'>                            
                        <div htmlFor='counter' className='position-absolute w-100 px-3 zindex-2' style={{right: 0, bottom: 15}}>
                            <div className='w-100 align-items-end row-end border-top-1 border-color-white py-1'>
                                <p className='txt-phrase txt-medium txt-white txt-6'>{charCounter}/200</p>
                            </div>
                        </div>
                        <textarea                                
                            className={`text-area-nps w-100 rounded-8 p-3 txt-white txt-phrase txt-regular txt-8 my-2`}
                            style={{resize:'none', height:140}}
                            maxLength={"200"}
                            id={'counter'}
                            onChange={inputHandler}                                
                        />                            
                    </div>
                </div>
            )}                            
            {firstSend && (
                <div className='w-100 align-items-center column-center'>
                    <div className='col-10 px-0 text-wrap text-center txt-phrase txt-10 txt-medium txt-green-500 my-2'>{props.language.shared_survey_nps_text_secondary_title}</div>
                    <div className='col-12 px-0 text-wrap text-center txt-8 txt-regular txt-white'>{props.language.shared_survey_nps_text_description}</div>
                    <div className='col-12 px-0 text-wrap txt-phrase txt-8 txt-regular text-center txt-white my-2'>{surveyValue.qualify < 7 ? props.language.shared_survey_nps_text_question_bad : surveyValue.qualify >= 7 && props.language.shared_survey_nps_text_question_good }</div>
                    {service !== 4 ? <div className='col-12 column-start bg-gray-500 rounded-8 p-3'>                            
                        <div className="form-check" onChange={({ target }) => setService(parseInt(target.value))}>
                            <input type="radio" className="form-check-input" id={`radio0`} name="optradio" value={0}/>{props.language.shared_survey_nps_list_option_bill}
                            <label className="form-check-label" htmlFor={`radio0`}></label>
                        </div>
                        <div className="form-check" onChange={({ target }) => setService(parseInt(target.value))}>
                            <input type="radio" className="form-check-input" id={`radio1`} name="optradio" value={1}/>{props.language.shared_survey_nps_list_option_platform}
                            <label className="form-check-label" htmlFor={`radio1`}></label>
                        </div>
                        <div className="form-check" onChange={({ target }) => setService(parseInt(target.value))}>
                            <input type="radio" className="form-check-input" id={`radio2`} name="optradio" value={2}/>{props.language.shared_survey_nps_list_option_customer}
                            <label className="form-check-label" htmlFor={`radio2`}></label>
                        </div>
                        <div className="form-check" onChange={({ target }) => setService(parseInt(target.value))}>
                            <input type="radio" className="form-check-input" id={`radio3`} name="optradio" value={3}/>{props.language.shared_survey_nps_list_option_pay}
                            <label className="form-check-label" htmlFor={`radio3`}></label>
                        </div>
                        <div className="form-check" onChange={({ target }) => setService(parseInt(target.value))}>
                            <input type="radio" className="form-check-input" id={`radio4`} name="optradio" value={4}/>{props.language.shared_survey_nps_list_option_other}
                            <label className="form-check-label" htmlFor={`radio4`}></label>
                        </div>
                    </div> :
                    <div className='col-12 column-start bg-gray-500 rounded-8 p-3'> 
                        <p className='w-100 text-center txt-phrase txt-8 txt-regular txt-white'>{surveyValue.qualify < 7 ? props.language.shared_survey_nps_text_specific_question_bad : surveyValue.qualify >= 7 && props.language.shared_survey_nps_text_specific_question_good}</p>
                        <div className='w-100 position-relative'>
                            <div htmlFor='counter2' className='position-absolute w-100 px-3 zindex-2' style={{right: 0, bottom: 15}}>
                                <div className='w-100 align-items-end row-end border-top-1 border-color-white py-1'>
                                    <p className='txt-phrase txt-medium txt-white txt-6'>{charCounter}/200</p>
                                </div>
                            </div>
                            <textarea                                
                                className={`text-area-nps w-100 rounded-8 p-3 txt-white txt-phrase txt-regular txt-8 my-2`}
                                style={{resize:'none', height:140}}
                                maxLength={"200"}
                                id={'counter2'}
                                onChange={inputHandlerSpecific}                                                              
                            /> 
                        </div>
                    </div>}                        
                </div>
            )}
            <div className='row-center w-100 align-items-center mt-3'>                    
                <button className="row-center rounded-8 bg-green-500 px-4 py-2"
                    onClick={handleSend}>
                    <p className='txt-phrase txt-bold txt-neutral-800 txt-8'>{!firstSend ? props.language.shared_survey_nps_button_send_score : props.language.shared_survey_nps_button_send}</p>
                </button>
            </div>                
        </div>
    )
}

const mapStateToProps = (state) => ({
    language: state.shared.language
})

const mapDispatchToProps = dispatch => ({
    setNpsSurveyAsync: payload => dispatch(sharedActions.setNpsSurveyAsync(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PopUpScore)