import React from 'react';
import { connect } from 'react-redux';

import * as paysActions from '../../../store/actions/Pays';

const PaymentMethod = (props) => {

    let paymentMethods = props.paymentMethods;

    return (
        <div className='column-start align-items-start mt-4 h-100 w-100'>
            <div className='txt-phrase txt-semibold txt-8 txt-gray-95 my-2'>{props.language.invoices_card_payment_methods}</div>
            {
                paymentMethods.map(method => 
                    <div key={method.value} className='col-12 row-between align-items-center my-2 p-2 bg-gray-800 rounded-10 pe-pointer' onClick={() => { props.setTypeComponent(method.value); props.setPaymentProvider(method.provider); }}>
                        <div className='row-start align-items-center'>
                            <div className={`row-center align-items-center rounded-pill bg-${method.background} p-1`} style={{width: 40, height: 40}}>
                                {
                                    method.image.type === 'icon' ?
                                    <i className={`${method.image.value} txt-12 txt-${method.image.color} pe-pointer`}></i> : 
                                    <img src={method.image.value} alt="logo-pse" className='w-100 h-100' />
                                }
                            </div>
                            <div className='column-between h-100 ms-2 pe-pointer'>
                                <div className='txt-phrase txt-medium txt-8 txt-neutral-100 pb-1 pe-pointer'>{method.title}</div>
                                <div className='txt-phrase txt-regular txt-6 txt-gray-200 pe-pointer'>{method.description}</div>
                            </div>
                        </div>
                        <i className='fa-light fa-chevron-right txt-8 txt-neutral-200 pe-pointer'></i>
                    </div>
                )
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.users.user,
    language: state.shared.language,
    paymentMethods: state.pays.paymentMethods
})

const mapDispatchToProps = dispatch => ({
    setTypeComponent: (payload) => dispatch(paysActions.setTypeComponent(payload)),
    setPaymentProvider: (payload) => dispatch(paysActions.setPaymentProvider(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod)