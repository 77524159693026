import React from "react"
import step1 from "../../../imgs/onboarding/step1-graph-period.png"
import step1En from "../../../imgs/onboarding/english/step1-graph-period-en.png"
import step2 from "../../../imgs/onboarding/step2-graph-period.png"
import step2En from "../../../imgs/onboarding/english/step2-graph-period-en.png"
import step3 from "../../../imgs/onboarding/step3-graph-period.png"
import step3En from "../../../imgs/onboarding/english/step3-graph-period-en.png"
import step4 from "../../../imgs/onboarding/step4-graph-period.png"
import step4En from "../../../imgs/onboarding/english/step4-graph-period-en.png"
import step5 from "../../../imgs/onboarding/step5-graph-period.png"
import step5En from "../../../imgs/onboarding/english/step5-graph-period-en.png"

export const stepsGraphPeriod = (language) => {
  return ([
    {
      content: (
        <div className="row-center align-items-center w-100 mt-3" style={{ height: 152, width: 300 }}>
          <img src={language.language_locale === 'es'? step1 : step1En} className="w-100 h-100" alt="step 1 graph picker" />
        </div>
      ),
      title: language.onboarding_graph_period_title_step1,
      description: language.onboarding_graph_period_description_step1,
      placement: "left-start",      
      target: "#date_range_picker",
      disableBeacon: true,
      steps: 5,
      disableOverlayClose: true,
      spotlightClicks: true,
      hideFooter: true,
      hideFooterTitle: "Haz click en el botón!",
      pulseEffect: true
    },
    {
      content: (
        <div className="row-center align-items-center w-100 mt-3" style={{ height: 152, width: 300 }}>
          <img src={language.language_locale === 'es'? step2 : step2En} className="w-100 h-100" alt="step 2 graph picker" />
        </div>
      ),
      title: language.onboarding_graph_period_title_step2,
      description: language.onboarding_graph_period_description_step2,
      placement: "left",
      target: "#date_interval",
      disableBeacon: true,
      steps: 5,
      disableOverlayClose: true,
      hideBackButton: true,
    },
    {
      content: (
        <div className="row-center align-items-center w-100 container-tour rounded-8 mt-3" style={{ height: 152, width: 300 }}>
          <img src={language.language_locale === 'es'? step3 : step3En} className="w-100 h-100" alt="step 3 graph picker" />
        </div>
      ),
      title: language.onboarding_graph_period_title_step3,
      description: language.onboarding_graph_period_description_step3,
      placement: "left",
      target: "#date_interval",
      disableBeacon: true,
      steps: 5,
      disableOverlayClose: true,
    },
    {
      content: (
        <div className="row-center align-items-center w-100 container-tour rounded-8 mt-3" style={{ height: 142, width: 300 }}>
          <img src={language.language_locale === 'es'? step4 : step4En} className="w-100 h-100" alt="step 4 graph picker" />
        </div>
      ),
      title: language.onboarding_graph_period_title_step4,
      description: language.onboarding_graph_period_description_step4,
      placement: "left",      
      target: "#date_interval",
      disableBeacon: true,
      steps: 5,
      disableOverlayClose: true,
    },
    {
      content: (
        <div className="row-center align-items-center w-100 container-tour rounded-8 mt-3" style={{ height: 152, width: 300 }}>
          <img src={language.language_locale === 'es'? step5 : step5En} className="w-100 h-100" alt="step 5 graph picker" />
        </div>
      ),
      title: language.onboarding_graph_period_title_step5,
      description: language.onboarding_graph_period_description_step5,
      placement: "left",
      target: "#date_interval",
      disableBeacon: true,
      steps: 5,
      disableOverlayClose: true,
    },
  ])
}
