import * as login from '../creators/Login';

import * as usersActions from './Users';
import * as accountsActions from './Accounts';
import * as sharedActions from "./Shared";

import { GETData, SETData } from '../../services/WebServices';

import { init, identify, Identify, setUserId } from "@amplitude/analytics-browser"


import { getMessaging, getToken, isSupported } from "firebase/messaging";

init(process.env.REACT_APP_AMPLITUDE_KEY, {
    defaultTracking: false,
});

const getFirebaseToken = id_user => {
    return () => {

        isSupported().then(support => {
            if (support && Notification.permission === "granted") {

                let messaging = getMessaging();
                getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_TOKEN })
                    .then(token => {

                        const data = {
                            "id_user": id_user,
                            "device_type": "web",
                            "token": token
                        }

                        SETData(`notifications/devices/token`, "POST", data);

                    });

            }

        })
    }
}

export const setStep = payload => {
    return {
        type: login.SET_STEP,
        payload
    }
}

export const setLoading = payload => {
    return {
        type: login.SET_LOADING,
        payload
    }
}

export const setResending = payload => {
    return {
        type: login.SET_RESENDING,
        payload
    }
}

export const setCounter = payload => {
    return {
        type: login.SET_COUNTER,
        payload
    }
}

export const setCodeTries = payload => {
    return {
        type: login.SET_CODE_TRIES,
        payload
    }
}

export const loadInitInfoAsync = () => {
    return (dispatch) => {

        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));

        dispatch(setLoading(true));

        sharedActions
            .loadLanguage()
            .then(response => {

                dispatch(sharedActions.setLanguage(response));

                if (token !== null && user !== null) {

                    GETData(`users/users/${user.idUser}`, "GET")
                        .then((result) => {
                            if (result !== null) {

                                // if (result.redirectInfo)
                                //     window.location.replace(result.redirectInfo.redirectUrl);

                                if (localStorage.getItem('modal_activation') === null) {
                                    localStorage.setItem('modal_activation', true);
                                }

                                if (localStorage.getItem('modal_home') === null) {
                                    localStorage.setItem('modal_home', true);
                                }

                                localStorage.setItem('user', JSON.stringify(result));

                                dispatch(usersActions.setUser(result));

                                dispatch(usersActions.getUserExtraDataAsync());
                                dispatch(accountsActions.loadAccountsAsync());
                                dispatch(setStep(3));

                                setUserId(`NEUUSER_${user.idUser}`);

                                const identifyObj = new Identify()
                                identifyObj
                                    .set("name", `${result.name} ${result.lastname}`)
                                    .set("email", result.email)
                                    .set("job", result?.jobs?.length ? result.jobs.map(function (job) { return job?.name }) : 'N/A')
                                    .set("project_type", result?.capabilities?.projects_type)
                                identify(identifyObj);

                                dispatch(setLoading(false));

                                dispatch(updateConnectionAsync());

                                dispatch(getFirebaseToken(user.idUser));

                            } else {
                                dispatch(setStep(1));
                            }
                        })
                        .catch((error) => {
                            dispatch(setStep(1));
                        })
                        .finally(() => dispatch(setLoading(false)));

                } else {
                    dispatch(setStep(1));
                    dispatch(setLoading(false));
                }

            });
    }
}

export const loadInfoUserAsync = (payload, showError) => {
    return (dispatch, getState) => {

        let codeTries = getState().login.codeTries;

        const user = getState().users.user;

        const language = getState().shared.language;

        dispatch(setLoading(true));

        GETData(`users/login/${user.idUser}/${payload}`, "GET")
            .then((result) => {

                if (result !== null) {

                    localStorage.setItem('token', result.token);

                    GETData(`users/users/${result.user.idUser}`, "GET")
                        .then((resultUser) => {
                            if (resultUser !== null) {

                                // if (result.redirectInfo)
                                //     window.location.replace(result.redirectInfo.redirectUrl);


                                if (localStorage.getItem('modal_activation') === null) {
                                    localStorage.setItem('modal_activation', true);
                                }

                                if (localStorage.getItem('modal_home') === null) {
                                    localStorage.setItem('modal_home', true);
                                }

                                localStorage.setItem('user', JSON.stringify(resultUser));

                                dispatch(usersActions.setUser(resultUser));

                                dispatch(usersActions.getUserExtraDataAsync());
                                dispatch(accountsActions.loadAccountsAsync());
                                dispatch(setStep(3));

                                setUserId(`NEUUSER_${resultUser.idUser}`);

                                const identifyObj = new Identify()
                                identifyObj
                                    .set("name", `${resultUser.name} ${resultUser.lastname}`)
                                    .set("email", resultUser.email)
                                    .set("job", resultUser?.jobs?.length ? resultUser.jobs.map(function (job) { return job?.name }) : 'N/A')
                                    .set("project_type", resultUser?.capabilities?.projects_type)
                                identify(identifyObj);

                                dispatch(setLoading(false));

                                dispatch(updateConnectionAsync());

                                dispatch(getFirebaseToken(resultUser.idUser));

                            } else {
                                dispatch(setStep(1));
                            }
                        })
                        .catch((error) => {
                            dispatch(setStep(1));
                        })
                        .finally(() => dispatch(setLoading(false)));
                } else {

                    codeTries += 1;

                    if (codeTries === 4) {

                        codeTries += 1;
                        dispatch(setStep(1));

                    } else {
                        showError(language.alert_login_invalid_code);
                    }

                    dispatch(setLoading(false))
                    dispatch(setCodeTries(codeTries))

                }

            })
            .catch(() => {
                showError(language.alert_shared_error);
                dispatch(setLoading(false))
            })

    }
}

export const generateCodeAsync = (payload, showError) => {
    return (dispatch, getState) => {

        const step = getState().login.step;
        const user = getState().login.user;
        const language = getState().shared.language;

        let counter = getState().login.counter;
        let messageError;

        if (step === 2) {
            messageError = language.alert_shared_error;
            dispatch(setResending(true));
        } else {
            messageError = language.alert_login_user_not_found;
            dispatch(setLoading(true));
        };

        GETData(`users/login/${payload}?lan=${language.language_locale}`, "GET")
            .then((result) => {
                if (result !== null) {

                    dispatch(usersActions.setUser({ ...user, idUser: result.idUser }));

                    if (step === 2) {

                        let interval = setInterval(
                            () => {
                                if (counter > 0) {
                                    counter -= 1;
                                } else {
                                    clearInterval(interval);
                                    counter = 60;
                                    dispatch(setResending(false));
                                }
                                dispatch(setCounter(counter));
                            },
                            1000
                        );
                    } else {
                        dispatch(setLoading(false));
                        dispatch(setStep(2));
                    }
                } else {
                    showError(messageError);
                    dispatch(setLoading(false));
                    dispatch(setResending(false));
                }
            })
            .catch(() => {
                showError(messageError);
                dispatch(setLoading(false));
                dispatch(setResending(false));
            });

    }
}

export const updateConnectionAsync = () => {
    return (_dispatch, getState) => {

        const userId = getState().users.user.idUser;

        SETData(`users/users/connection/${userId}`, 'POST', { type: 'web' })
            .catch(response => console.error(response));

    }
}