import { GETData } from "../../services/WebServices";

import * as services from "../creators/Services";

import _ from "lodash";

export const setTotalServices = payload => {
    return {
        type: services.SET_TOTAL_SERVICES,
        payload
    }
}

export const setTotalServicesGrouped = payload => {
    return {
        type: services.SET_TOTAL_SERVICES_GROUPED,
        payload
    }
}

export const setActiveServicesEnergy = payload => {
    return {
        type: services.SET_ACTIVE_SERVICES_ENERGY,
        payload
    }
}

export const setActiveServicesPlus = payload => {
    return {
        type: services.SET_ACTIVE_SERVICES_PLUS,
        payload
    }
}

export const setActiveServicesInvoices = payload => {
    return {
        type: services.SET_ACTIVE_SERVICES_INVOICES,
        payload
    }
}

export const setActiveServicePreonboarding = payload => {
    return {
        type: services.SET_ACTIVE_SERVICE_PREONBOARDING,
        payload
    }
}

export const setDefaultActiveService = payload => {
    return {
        type: services.SET_DEFAULT_ACTIVE_SERVICE,
        payload
    }
}

export const loadServicesAsync = (services = []) => {
    return (dispatch, getState) => {
        const defaultActiveService = getState().services.defaultActiveService;
        const userId = getState().users.user.idUser;
        
        GETData(`users/services/?user=${userId}`, "GET")
            .then(response => {
                if (response !== null) {
                    
                    response = response.map(item => ({...item, idAccount: item.account.idAccount}))

                    dispatch(setTotalServices(response));  

                    let groupedServices = response.reduce((acc, service) => {
                        const { account, ...serviceDetails } = service;
                        const accountId = account.idAccount;
                    
                        if (!acc[accountId]) {
                            acc[accountId] = { account: { ...account }, services: [] };
                        }
                    
                        acc[accountId].services.push(serviceDetails);
                    
                        return acc;
                    }, {});
                    
                    groupedServices = Object.values(groupedServices);

                    dispatch(setTotalServicesGrouped(groupedServices));
                    
                    let defaultServiceIndex = 0;

                    if (defaultActiveService){
                        defaultServiceIndex = response.findIndex(s => s.idService === defaultActiveService)
                        defaultServiceIndex = defaultServiceIndex !== -1 ? defaultServiceIndex : 0;
                        dispatch(setDefaultActiveService(null));
                    }

                    dispatch(setActiveServicesEnergy([response[defaultServiceIndex]]));
                    
                    dispatch(setActiveServicesInvoices(response));

                    const servicesPlus = response.filter(x => x.type.typeService === 2)
                    if (servicesPlus.length){                        
                        dispatch(setActiveServicesPlus([servicesPlus[0]]));
                    }
                    else{
                        dispatch(setActiveServicesPlus([]));
                    }

                    if (services.length > 0) {
                        const filteredServices = response
                        .filter(service => services.includes(service.idService));
                        
                        setTimeout(() => dispatch(setActiveServicesEnergy(filteredServices)), 200);

                        setTimeout(() => dispatch(setActiveServicesPlus([_.last(filteredServices)])), 200);
                    }
                }
            })
            .catch(response => console.error(response));
    }
}
