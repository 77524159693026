import React, { useState, useEffect, Fragment, useRef } from "react";
import { connect } from "react-redux";

import * as servicesActions from "../../store/actions/Services";
import * as invoicesActions from "../../store/actions/Invoices";
import * as sharedActions from "../../store/actions/Shared";

import { addMonths, format } from "date-fns";

import { Transition } from "react-transition-group";

import { Loader, Whisper, Tooltip } from "rsuite";

import CurrencyFormat from "react-currency-format";

import { stringFormatDate } from "../../services/CalendarHandler";

import empty_card_bill from "../../imgs/empty-card-bill.svg";

import { track } from "@amplitude/analytics-browser";

import InvoiceDetails from "./InvoiceDetails";
import InvoiceModal from "./InvoiceModal";
import InvoiceBubble from "./InvoiceBubble";
import FilterInvoices from "./FilterInvoices";

import Header from "../shared/Header";
import Calendar from "../shared/utilities/Calendar";
import Pay from "./pay/Pay";

import useTour from "../onboarding/hook/useTour";
import { stepsPayment } from "../onboarding/steps/PaymentSteps";
import { stepsInvoice } from "../onboarding/steps/InvoiceSteps";

const Invoices = (props) => {
  const searchInputRef = useRef(null);

  let _invoices = props.invoices;
  let selectedInvoices = props.selectedInvoices;
  let activeServicesInvoices = props.activeServicesInvoices;
  let activeServicesEnergy = props.activeServicesEnergy;
  let showCalendar = props.showCalendar;
  let account = props.account;
  let invoiceShow = props.invoiceShow;
  let invoiceShowIndex = props.invoiceShowIndex;
  let billsStatus = props.billsStatus;
  let filterStatus = props.filterStatus;
  let invoicesPeriods = props.invoicesPeriods;

  const setShowBlackScreen = props.setShowBlackScreen;
  const setSelectedInvoices = props.setSelectedInvoices;
  const setInvoicesToPay = props.setInvoicesToPay;
  const loadInvoicesAsync = props.loadInvoicesAsync;
  const setShowCalendar = props.setShowCalendar;
  const loadServicesAsync = props.loadServicesAsync;
  const setInvoiceShow = props.setInvoiceShow;
  const setInvoiceShowIndex = props.setInvoiceShowIndex;
  const setInvoicesContent = props.setInvoicesContent;
  const setInvoicesContentLoading = props.setInvoicesContentLoading;
  const loadInvoicesPeriodsAsync = props.loadInvoicesPeriodsAsync;
  const setFilterStatus = props.setFilterStatus;
  const _setInvoices = props.setInvoices;

  const [search, setSearch] = useState("");
  const [originalArray, setOriginalArray] = useState([]);
  const [zoomIn, setZoomIn] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [invoiceUrl, setInvoiceUrl] = useState(null);

  const [totalPayment, setTotalPayment] = useState(0);
  const [selectedInvoicesToPay, setSelectedInvoicesToPay] = useState([]);

  const handleCalendar = (value) => {
    props.setInvoicesDate(value.graphDateFrom);
    loadInvoicesAsync();
    setShowCalendar(false);
    setInvoicesContentLoading(true);
    setInvoicesContent([]);
  };

  const handlerClickInvoice = (invoice) => {
    const sortInvoicesList = sortInvoices();

    let index = sortInvoicesList.findIndex(
      (item) => item.id_bill === invoice.id_bill
    );

    const bill = invoices.find((i) => i.id_bill === invoice.id_bill);

    setInvoiceShow(bill);
    setInvoiceShowIndex(index);
  };

  const scrollInvoice = (id) => {
    var elem = document.getElementById(`${id}`);
    elem != null &&
      elem.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const sortInvoices = () => {
    return [
      ...invoices
        .filter((i) => selectedInvoices.includes(i.id_bill))
        .sort((a, b) => a.id_service - b.id_service),
      ...invoices
        .filter((i) => !selectedInvoices.includes(i.id_bill))
        .sort((a, b) => a.id_service - b.id_service),
    ];
  };

  useEffect(() => {
    setShowBlackScreen(showCalendar);
  }, [setShowBlackScreen, showCalendar]);

  useEffect(() => {
    if (account) {
      loadServicesAsync();
    }
  }, [account, loadServicesAsync]);

  useEffect(() => {    
    if (activeServicesInvoices.length) {      
      loadInvoicesPeriodsAsync();
    }
  }, [activeServicesInvoices, loadInvoicesPeriodsAsync]);

  useEffect(() => {       
    if(!invoicesPeriods.length){_setInvoices([])}

    if (activeServicesInvoices.length && invoicesPeriods?.length) {      
      loadInvoicesAsync();
      setInvoiceShowIndex(0);
    }
  }, [
    activeServicesInvoices,
    loadInvoicesAsync,
    setInvoiceShowIndex,
    invoicesPeriods,
    _setInvoices
  ]);

  useEffect(() => {
    if (invoices.length && originalArray.length) {
      let bill;

      const index = Math.min(invoiceShowIndex, invoices.length - 1);

      bill = sortInvoices()[index];

      const invoice = invoices.find((i) => i.id_bill === bill.id_bill);

      setInvoiceShowIndex(index);
      setInvoiceShow(invoice);
    } else {
      setInvoiceShow(null);
    }
    // eslint-disable-next-line
  }, [
    invoices,
    invoiceShowIndex,
    selectedInvoices,
    originalArray,
    setInvoiceShow,
    setInvoiceShowIndex,
    filterStatus,
  ]);

  useEffect(() => {
    if (!selectedInvoices.length) {
      let selectedInvoices;

      const activeServicesEnergyIds = activeServicesEnergy.map(
        (i) => i.idService
      );

      if (activeServicesEnergyIds) {
        selectedInvoices = invoices
          .filter((i) => activeServicesEnergyIds.includes(i.id_service))
          .map((i) => i.id_bill);
      } else {
        selectedInvoices = invoices.map((i) => i.id_bill);
      }

      setSelectedInvoices(selectedInvoices);
    }
    setOriginalArray(invoices.map((i) => i.id_bill));
    // eslint-disable-next-line
  }, [invoices, setSelectedInvoices, activeServicesEnergy]);

  useEffect(() => {
    let sum = 0;
    let invoicesPay = [];
    let array = [];

    if (invoicesPeriods.length) {
      array = invoicesPeriods.filter(
        (i) => i.invoices && i.invoices.length > 0
      );

      array.forEach((period) => {
        period.invoices.forEach((invoice) => {
          if (
            selectedInvoices.includes(invoice.id_bill) &&
            invoice.state === 0
          ) {
            sum += parseFloat(invoice.total_payment);
            invoicesPay.push(invoice);
          }
        });
      });

      if (invoicesPay.length) setSelectedInvoicesToPay(invoicesPay);

      if (array.length) {
        setTotalPayment(sum);
      } else setTotalPayment(0);
    }
  }, [invoices, invoicesPeriods, selectedInvoices]);

  useEffect(() => {
    setInvoices([
      ..._invoices.filter(
        (i) =>
          i?.service_name.toLowerCase().includes(search.toLowerCase()) ||
          i?.reference.toLowerCase().includes(search.toLowerCase())
      ),
    ]);
  }, [search, _invoices]);

  useEffect(() => {
    if (!invoices.length) {
      setInvoices(_invoices);
    }
  }, [_invoices, invoices]);

  useEffect(() => {
    if (invoiceShow) {
      if (invoiceShow?.type_content === "pdf") {
        setInvoiceUrl(`https://${process.env.REACT_APP_S3_BUCKET}.s3.us-east-2.amazonaws.com/${invoiceShow?.pdf_path}`);
        setInvoicesContentLoading(false);
      }
    } else {
      setInvoiceUrl("");
    }
  }, [invoiceShow, invoices, account, setInvoicesContentLoading]);

  useEffect(() => {
    if (invoiceShow) scrollInvoice(invoiceShow?.id_bill);
  }, [invoiceShow]);

  const handleClosePay = () => {
    props.setStartOnboarding({ key: "payment", value: false });
  };

  const tourPayment = useTour({
    steps: stepsPayment(props.language),
    localStorageKey: "payment-tutorial",
    isOpenDrawer: props.invoicesToPay.length !== 0,
    runTour: props.payment,
    setRunTour: props.setStartOnboarding,
    key: "payment",
  });
  const tourInvoice = useTour({
    steps: stepsInvoice(props.language),
    localStorageKey: "invoice-tutorial",
    isOpenDrawer:
      !props.invoicesContentLoading && props.invoicesToPay.length === 0,
    runTour: props.invoiceOnboarding,
    setRunTour: props.setStartOnboarding,
    onTourFinish: handleClosePay,
    key: "invoice",
  });

  return (
    <section className="column-start align-items-center vh-100 w-100">
      {tourPayment}
      {tourInvoice}
      <Header
        moduleName={`${props.language.invoices_main_title_dashboard}`}
        backToButton={true}
      />
      <div className="position-relative row-start flex-grow-1 w-100">
        <div className="position-absolute col-4 column-start h-100 left-0 ps-3 pb-3">
          <div className="position-relative column-between align-items-center bg-gray-500 border-1 border-color-gray-800 rounded-16 w-100 h-100">
            <Transition in={props.invoicesLoading} timeout={100}>
              {(state) => (
                <div
                  className={`trans-fade trans-fade-${state} position-absolute bg-gray-500 rounded-16 w-100 h-100 top-0 left-0 zindex-1`}
                >
                  <div className="column-center align-items-center w-100 h-100">
                    <Loader
                      inverse
                      center
                      size="md"
                      content={props.language.loader_text_content}
                    />
                  </div>
                </div>
              )}
            </Transition>
            <Fragment>
              <div className="column-start border-bottom-1 px-3 py-3 border-color-gray-600 w-100">
                {filterStatus === null && (
                  <div id="test" className="w-100">
                    <div className="row-center align-items-center">
                      <i
                        className="fa-regular fa-chevron-left txt-10 txt-white pe-pointer me-3"
                        onClick={() => {
                          handleCalendar({
                            graphDateFrom: addMonths(props.invoicesDate, -1),
                          });
                          track("Bill viewed", {
                            Month_ID: `${format(
                              addMonths(props.invoicesDate, -1),
                              "MMMM"
                            )}_Bill`,
                          });
                        }}
                      ></i>
                      <div
                        className="row-center bg-gray-800 rounded-pill align-items-center pe-pointer py-2 px-3"
                        onClick={() => setShowCalendar(true)}
                      >
                        <div
                          className={`position-relative row-center align-items-center pe-pointer me-2 ${
                            showCalendar && "zindex-6"
                          }`}
                        >
                          <i className="fa-regular fa-calendar txt-8 txt-white pe-pointer"></i>
                          <Calendar
                            show={showCalendar}
                            start={props.invoicesDate}
                            end={props.invoicesDate}
                            interval={2}
                            setDates={handleCalendar}
                          />
                        </div>
                        <div className="txt-white txt-phrase txt-6 txt-regular text-left w-100 pe-pointer">
                          {stringFormatDate(props.invoicesDate, 2)}
                        </div>
                      </div>
                      <i
                        className="fa-regular fa-chevron-right txt-10 txt-white ms-3 pe-pointer"
                        onClick={() => {
                          handleCalendar({
                            graphDateFrom: addMonths(props.invoicesDate, +1),
                          });
                          track("Bill viewed", {
                            Month_ID: `${format(
                              addMonths(props.invoicesDate, +1),
                              "MMMM"
                            )}_Bill`,
                          });
                        }}
                      ></i>
                    </div>
                    <div className="row-start align-items-center w-100 py-2 px-3 mt-3 bg-gray-800 rounded-8">
                      <i className="fa-regular fa-search txt-8 txt-gray-400"></i>
                      <input
                        ref={searchInputRef}
                        type="text"
                        autoFocus={true}
                        className="search-input w-100 bg-transparent txt-phrase txt-8 txt-gray-400 txt-regular ms-2"
                        placeholder={"Ej. Erco Energía"}
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className="column-center align-items-start">
                  <div className="row-start align-items-center gap-2 my-4">
                    <p className="txt-phrase txt-regular txt-6 txt-white">
                      Filtrar
                    </p>
                    {billsStatus.map((status) => {
                      return (
                        <div
                          key={status.id}
                          className={`row-start align-items-center rounded-pill px-3 py-1 pe-pointer border-1 border-color-${
                            status.color
                          }-500 bg-${status.color}-500-40 ${
                            filterStatus !== status.id &&
                            filterStatus !== null &&
                            "opacity-40"
                          }`}
                          onClick={() =>
                            setFilterStatus(
                              filterStatus === status.id ? null : status.id
                            )
                          }
                        >
                          <div
                            className={`txt-phrase txt-medium txt-6 pe-pointer txt-${status.text_color}`}
                          >
                            {props.language[`invoices_state_${status.id}`]}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {filterStatus && (
                    <div
                      className="w-100 text-end txt-phrase txt-medium txt-6 txt-white text-decoration-underline pe-pointer"
                      onClick={() => setFilterStatus(null)}
                    >
                      {props.language["invoices_state_clear_filters"]}
                    </div>
                  )}
                </div>
                {filterStatus === null && invoices.length && !search.length ? (
                  <div
                    style={{ height: 40 }}
                    className={`row-center align-items-center pe-pointer bg-${
                      invoices.length !== selectedInvoices.length
                        ? "green-500"
                        : "gray-550"
                    } rounded-pill w-100`}
                    onClick={() => {
                      if (invoices.length === selectedInvoices.length) {
                        setSelectedInvoices([invoices[0].id_bill]);
                      } else {
                        setSelectedInvoices(invoices.map((i) => i.id_bill));
                      }
                    }}
                  >
                    <i
                      className={`fa-solid fa-check-circle txt-${
                        invoices.length !== selectedInvoices.length
                          ? "neutral-800"
                          : "white"
                      } txt-8 pe-pointer`}
                    ></i>
                    <div
                      className={`txt-phrase txt-medium txt-6 text-left ms-2 pe-pointer txt-${
                        invoices.length !== selectedInvoices.length
                          ? "neutral-800"
                          : "white"
                      }`}
                    >
                      {invoices.length === selectedInvoices.length
                        ? `${props.language.invoices_text_unselected_all} (${invoices.length})`
                        : `${props.language.invoices_text_selec_all} (${selectedInvoices.length})`}
                    </div>
                  </div>
                ) : null}
              </div>
              {invoices.length ? (
                <Fragment>
                  <div className="column-start w-100 px-3">
                    {filterStatus === "overdue" ? (
                      <div className="row-between align-items-center pe-pointer w-100 px-3 bg-gray-450 py-2 mt-4 rounded-16 ">
                        <i
                          className="fa-regular fa-triangle-exclamation txt-red-500 txt-10 pe-pointer me-3"
                          aria-hidden="true"
                        ></i>
                        <div className="flex-grow-1 position-relative pe-pointer">
                          <div className="row-between align-items-end mb-2 pe-pointer">
                            <div className="column-start">
                              <div className="txt-phrase txt-6 pe-pointer txt-white">
                                {props.language.invoices_alert_overdue}
                              </div>
                              <div className="txt-phrase txt-6 pe-pointer txt-white">
                                {props.language.invoices_alert_note}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {filterStatus !== null ? (
                    <FilterInvoices></FilterInvoices>
                  ) : (
                    <div className="column-start flex-grow-1 w-100 py-2 px-3 overflow-auto border-none-scroll">
                      {invoices
                        .filter((i) => selectedInvoices.includes(i.id_bill))
                        .sort((a, b) => a.id_service - b.id_service)
                        .map((i) => (
                          <InvoiceBubble
                            key={i.id_bill}
                            invoice={i}
                            handlerClick={handlerClickInvoice}
                          />
                        ))}
                      {selectedInvoices.length < invoices.length &&
                        selectedInvoices.length &&
                        !search.length &&
                        !props.invoicesLoading && (
                          <div className="position-relative">
                            <div className="position-absolute w-100 invoices-separator-line bg-gray-600" />
                            <div className="row-center w-100">
                              <div className="bg-gray-500 text-center txt-phrase txt-regular txt-8 txt-gray-600 px-2 zindex-2">
                                {props.language.invoces_more_invoces}
                              </div>
                            </div>
                          </div>
                        )}
                      {invoices
                        .filter((i) => !selectedInvoices.includes(i.id_bill))
                        .sort((a, b) => a.id_service - b.id_service)
                        .map((i) => (
                          <InvoiceBubble
                            key={i.id_bill}
                            invoice={i}
                            handlerClick={handlerClickInvoice}
                          />
                        ))}
                    </div>
                  )}
                  {totalPayment > 0 && (
                    <div
                      className="row-between align-items-center p-3 border-top-1 border-color-gray-600 w-100"
                      style={{ height: 65, minHeight: 65 }}
                    >
                      <div className="row-column align-items-center pe-pointer">
                        <CurrencyFormat
                          value={totalPayment}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={1}
                          prefix={"$"}
                          renderText={(value) => (
                            <div className="txt-green-500 txt-title txt-bold txt-12">
                              {value}
                            </div>
                          )}
                        />
                        <div className="txt-gray-400 txt-phrase txt-bold txt-6 txt-regular">
                          {props.language.invoices_text_total_pay}
                        </div>
                      </div>
                      <div
                        className="row-start align-items-center bg-green-500 rounded-8 py-2 px-3 pe-pointer"
                        onClick={() => {
                          if (selectedInvoicesToPay.length) {
                            setInvoicesToPay(selectedInvoicesToPay);
                          }
                          track("Bill selected", {
                            Bill_ID: selectedInvoicesToPay.map(function (
                              service
                            ) {
                              return service?.service_name;
                            }),
                          });
                        }}
                      >
                        <i className="fa-solid fa-wallet txt-10 txt-neutral-800"></i>
                        <p className="txt-phrase  txt-bold txt-8 txt-neutral-800 ps-3 pe-pointer">
                          {props.language.invoices_button_pay}
                        </p>
                      </div>
                    </div>
                  )}
                </Fragment>
              ) : (
                <div className="w-100 h-100 px-3 column-center align-items-center">
                  <img
                    src={empty_card_bill}
                    alt="empty_card_bill"
                    style={{ width: 172, height: 104 }}
                  />
                  <div className="txt-gray-600 txt-phrase txt-semibold txt-8 text-wrap mt-4 text-center">
                    {props.language.invoices_text_empty_bills_period}
                  </div>
                  <div className="txt-gray-600 txt-phrase txt-regular txt-6 text-wrap mt-2 text-center">
                    {
                      props.language
                        .invoices_text_empty_description_bills_period
                    }
                  </div>
                </div>
              )}
            </Fragment>
          </div>
        </div>
        <div
          className={`position-absolute col-8 column-start h-100 right-0 px-3`}
        >
          <Transition
            in={props.invoicesContentLoading && invoices.length > 0}
            timeout={100}
          >
            {(state) => (
              <div
                className={`trans-fade trans-fade-${state} position-absolute bg-black rounded-16 w-100 h-100 top-0 left-0 zindex-1`}
              >
                <div className="column-center align-items-center w-100 h-100">
                  <Loader
                    inverse
                    center
                    size="md"
                    content={props.language.loader_text_content}
                  />
                </div>
              </div>
            )}
          </Transition>
          {invoiceShow ? (
            <div className="h-100 rounded-16">
              <div
                className="row-between align-items-center bg-gray-500 border-1 border-color-gray-800 rounded-top-16 w-100 py-2 px-3"
                style={{ height: 44 }}
              >
                <Whisper
                  delay={100}
                  placement="bottom"
                  trigger="hover"
                  speaker={
                    <Tooltip>
                      {props.language.invoices_tooltip_previous_bill}
                    </Tooltip>
                  }
                >
                  <button
                    className={`row-center align-items-center button-collapsed py-2 px-3 rounded-30`}
                    disabled={!invoiceShowIndex || filterStatus}
                    onClick={() => {
                      invoiceShowIndex &&
                        setInvoiceShowIndex(invoiceShowIndex - 1);
                    }}
                  >
                    <i className="fa-regular fa-chevron-left txt-6"></i>
                  </button>
                </Whisper>
                <div className="row-center w-100 align-items-center flex-grow-1 mx-3 px-3 py-2 bg-gray-800 rounded-pill">
                  <i className={`fas fa-city txt-8 txt-white me-2`}></i>
                  <Whisper
                    delay={200}
                    placement="bottom"
                    trigger="hover"
                    speaker={<Tooltip>{invoiceShow.service_name}</Tooltip>}
                  >
                    <div
                      className={`txt-phrase txt-bold txt-6 txt-white text-nowrap max-width-200`}
                    >
                      {invoiceShow.service_name}
                    </div>
                  </Whisper>
                  <i
                    className={`fas fa-slash-forward txt-8 txt-white mx-2`}
                  ></i>
                  <div className="txt-gray-400 txt-phrase txt-6 txt-bold">
                    {props.language.invoices_title_section_bills}{" "}
                    {invoiceShow.reference}
                  </div>
                </div>
                <div className="row-start align-items-center">
                  <Whisper
                    delay={100}
                    placement="bottom"
                    trigger="hover"
                    speaker={
                      <Tooltip>
                        {props.language.invoices_tooltip_next_bill}
                      </Tooltip>
                    }
                  >
                    <button
                      className={`row-center align-items-center button-collapsed py-2 px-3 rounded-30`}
                      disabled={
                        !(originalArray.length > invoiceShowIndex + 1) ||
                        filterStatus
                      }
                      onClick={() => {
                        originalArray.length > invoiceShowIndex + 1 &&
                          setInvoiceShowIndex(invoiceShowIndex + 1);
                      }}
                    >
                      <i className="fa-regular fa-chevron-right txt-6"></i>
                    </button>
                  </Whisper>
                  {invoiceShow?.type_content !== "pdf" ? (
                    <button
                      className={`row-center align-items-center button-collapsed rounded-30 ms-2`}
                      style={{ height: 26, width: 26 }}
                      onClick={async () => {
                        await props.downloadInvoiceAsync(invoiceShow?.pdf_path);
                        track("Bill downloaded", {
                          Month_ID: `${format(
                            props.invoicesDate,
                            "MMMM"
                          )}_Bill`,
                        });
                      }}
                    >
                      <Whisper
                        delay={200}
                        placement="bottom"
                        trigger="hover"
                        speaker={
                          <Tooltip>
                            {props.language.invoices_tooltip_download_bill}
                          </Tooltip>
                        }
                      >
                        <i className="fa-regular fa-arrow-down-to-bracket txt-6"></i>
                      </Whisper>
                    </button>
                  ) : null}
                  <button
                    id="test_1"
                    className={`row-center align-items-center button-collapsed rounded-30 ms-2`}
                    style={{ height: 26, width: 26 }}
                    onClick={() => {
                      setZoomIn(true);
                      track("Bill opened");
                    }}
                  >
                    <Whisper
                      delay={200}
                      placement="bottom"
                      trigger="hover"
                      speaker={
                        <Tooltip>
                          {props.language.invoices_tooltip_zoomin_bill}
                        </Tooltip>
                      }
                    >
                      <i className="fa-regular fa-up-right-and-down-left-from-center txt-6"></i>
                    </Whisper>
                  </button>
                </div>
              </div>
              {invoiceShow?.type_content !== "pdf" ? (
                <div
                  className="border-left-1 border-right-1 border-color-gray-800"
                  style={{ height: "calc(100% - 44px)", overflowY: "auto" }}
                >
                  <InvoiceDetails />
                </div>
              ) : (
                <div
                  className="overflow-hidden"
                  style={{ height: "calc(100% - 45px)" }}
                >
                  <object
                    data={invoiceUrl}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                  >
                    <p className="row-center align-items-center w-100 vh-100 txt-white txt-title txt-12 mt-5">
                      {props.language.invoices_text_error_show_bills}
                    </p>
                  </object>
                </div>
              )}
            </div>
          ) : (
            (!props.invoicesContentLoading ||
              (!invoices.length && !props.invoicesLoading)) && (
              <div className="w-100 h-100 px-3 row-center align-items-center txt-white txt-title txt-bold txt-12 text-wrap">
                {props.language.invoices_text_no_select_bills}
              </div>
            )
          )}
        </div>
      </div>
      <Pay />
      {invoiceShow || invoiceUrl ? (
        <InvoiceModal
          showInvoice={zoomIn}
          setShowInvoice={setZoomIn}
          invoiceUrl={invoiceUrl}
        />
      ) : null}
    </section>
  );
};

const mapStateToProps = (state) => ({
  totalServices: state.services.totalServices,
  invoices: state.invoices.invoices,
  selectedInvoices: state.invoices.selectedInvoices,
  invoicesLoading: state.invoices.invoicesLoading,
  intervalGraph: state.energy.intervalGraph,
  invoicesDate: state.invoices.invoicesDate,
  activeServicesInvoices: state.services.activeServicesInvoices,
  activeServicesEnergy: state.services.activeServicesEnergy,
  showCalendar: state.shared.showCalendar,
  language: state.shared.language,
  account: state.accounts.account,
  invoicesContentLoading: state.invoices.invoicesContentLoading,
  invoiceShow: state.invoices.invoiceDetails,
  invoiceShowIndex: state.invoices.invoiceDetailsIndex,
  invoicesToPay: state.invoices.invoicesToPay,
  payment: state.shared.startOnboarding.payment,
  invoiceOnboarding: state.shared.startOnboarding.invoice,
  billsStatus: state.invoices.billsStatus,
  filterStatus: state.invoices.filterStatus,
  invoicesPeriods: state.invoices.invoicesPeriods
});

const mapDispatchToProps = (dispatch) => ({
  setShowBlackScreen: (payload) =>
    dispatch(sharedActions.setShowBlackScreen(payload)),
  setSelectedInvoices: (payload) =>
    dispatch(invoicesActions.setSelectedInvoices(payload)),
  setInvoicesDate: (payload) =>
    dispatch(invoicesActions.setInvoicesDate(payload)),
  setInvoicesLoading: (payload) =>
    dispatch(invoicesActions.setInvoicesLoading(payload)),
  loadInvoicesAsync: () => dispatch(invoicesActions.loadInvoicesAsync()),
  setActiveServicesInvoices: (payload) =>
    dispatch(servicesActions.setActiveServicesInvoices(payload)),
  setInvoicesToPay: (payload) =>
    dispatch(invoicesActions.setInvoicesToPay(payload)),
  setShowCalendar: (payload) =>
    dispatch(sharedActions.setShowCalendar(payload)),
  loadServicesAsync: (payload) =>
    dispatch(servicesActions.loadServicesAsync(payload)),
  downloadInvoiceAsync: (payload) =>
    dispatch(invoicesActions.downloadInvoiceAsync(payload)),
  setInvoiceShow: (payload) =>
    dispatch(invoicesActions.setShowInvoiceDetails(payload)),
  setInvoiceShowIndex: (payload) =>
    dispatch(invoicesActions.setShowInvoiceDetailsIndex(payload)),
  setInvoicesContent: (payload) =>
    dispatch(invoicesActions.setInvoicesContent(payload)),
  setInvoicesContentLoading: (payload) =>
    dispatch(invoicesActions.setInvoicesContentLoading(payload)),
  setStartOnboarding: (payload) =>
    dispatch(sharedActions.setStartOnboarding(payload)),
  loadInvoicesPeriodsAsync: () =>
    dispatch(invoicesActions.loadInvoicesPeriodsAsync()),
  setFilterStatus: (payload) =>
    dispatch(invoicesActions.setFilterStatus(payload)),
  setInvoices: (payload) =>
    dispatch(invoicesActions.setInvoices(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
