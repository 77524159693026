import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import * as invoicesActions from '../../../store/actions/Invoices';
import * as paysActions from '../../../store/actions/Pays';
import * as sharedActions from '../../../store/actions/Shared';

import { Drawer } from 'rsuite';

import PaymentMethod from './PaymentMethod';
import CardPayment from './CardPayment';
import PsePayment from './PsePayment';
import BackButton from '../../shared/utilities/BackButton';

const Component = ({ type }) => {

    switch ((type || 'METHOD').toUpperCase()) {
        case 'CARD':
            return <CardPayment />
        case 'PSE':
            return <PsePayment />
        default:
            return <PaymentMethod />
    }

}

const Pay = (props) => {

    let invoicesToPay = props.invoicesToPay;
    let typeComponent = props.typeComponent;
    let paymentMethods = props.paymentMethods;

    const setTypeComponent = props.setTypeComponent;
    const loadPaymentMethodsAsync = props.loadPaymentMethodsAsync;

    const [modalInfo, setModalInfo] = useState({});

    useEffect(() => {
        if (invoicesToPay.length) {
            setModalInfo({
                title: invoicesToPay[0].reference,
                total_payment: invoicesToPay.reduce((a, { total_payment }) => a + parseFloat(total_payment), 0),
            });
        }
    }, [setModalInfo, invoicesToPay]);

    useEffect(() => {
        if (!paymentMethods.length) {
            loadPaymentMethodsAsync();
        }
    }, [paymentMethods, loadPaymentMethodsAsync]);


    const handleClose = () => {
        if (typeComponent === 'METHOD') {
            props.setInvoicesToPay([]);
            props.setStartOnboarding({ key: 'payment', value: false })
        } else {
            setTypeComponent('METHOD')
        }
    }

    return (
        <Drawer
            show={invoicesToPay.length !== 0 || props.payment}
            onHide={() => handleClose()}
            size={'xs'}
            placement={'right'}
            backdropClassName={'bg-black opacity-80 pe-pointer'}>
            <div id='payment' className="column-between align-items-center pt-3 vh-100 w-100 overflow-auto px-4 bg-gray-500">
                <div className="w-100">
                    <div className='row-between align-items-center gap-2 w-100 max-height-100'>
                        <div className='row-between align-items-center w-100'>
                            <BackButton onClick={() => handleClose()}></BackButton>
                            <div className="txt-12 txt-title txt-bold txt-neutral-50 text-wrap text-left">{props.language.invoices_pay_text_modal} {modalInfo.title}</div>
                        </div>
                        {invoicesToPay.length > 1 ?
                            <div className="row-center align-items-center border-2 border-color-white rounded-pill px-3 py-1">
                                <i className="fa-solid fa-plus txt-6 txt-white"></i>
                                <div className="ms-2 txt-phrase txt-6 txt-white">{invoicesToPay.length - 1}</div>
                            </div> : null}
                    </div>
                </div>
                <Component type={typeComponent} />
            </div>
        </Drawer>
    )
}

const mapStateToProps = (state) => ({
    paymentMethods: state.pays.paymentMethods,
    typeComponent: state.pays.typeComponent,
    invoicesToPay: state.invoices.invoicesToPay,
    language: state.shared.language,
    payment: state.shared.startOnboarding.payment
})

const mapDispatchToProps = dispatch => ({
    loadPaymentMethodsAsync: () => dispatch(paysActions.loadPaymentMethodsAsync()),
    setInvoicesToPay: (payload) => dispatch(invoicesActions.setInvoicesToPay(payload)),
    setTypeComponent: (payload) => dispatch(paysActions.setTypeComponent(payload)),
    setStartOnboarding: (payload) => dispatch(sharedActions.setStartOnboarding(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Pay)